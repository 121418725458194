import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QpmcCall from "../callSchedule/Index";
const TestTranscripts = lazy(() => import("./test-transcripts/Index"));
const UploadDocument = lazy(() => import("./UploadDocument"));

function Index() {
  const { sectionId } = useParams();
  const [sectionList, setSectionList] = useState([]);
  const { stepsData } = useSelector((state) => state.StrategySessionReducer);

  useEffect(() => {
    if (stepsData) {
      if (stepsData.success) {
        setSectionList(stepsData.data || []);
      } else {
        setSectionList([]);
      }
    }
  }, [stepsData]);

  const renderTitle = () => {
    let arr = sectionList.filter(({ id }) => id === sectionId);
    if (arr.length !== 0) {
      return arr[0]["sectionName"];
    } else return "Upload Document";
  };

  const params = useParams();
  console.log(params.sectionName, "********sectionName");
  switch (params.sectionName) {
    case "test_transcripts":
      return <TestTranscripts title={renderTitle()} />;
    default:
      return <UploadDocument title={renderTitle()} />;
  }
}
export default Index;
