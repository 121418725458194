import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BackDropLoader from "../../../components/Backdrop";
import CallDetails from "../../../components/CallDetails";
import SnackBar from "../../../components/CustomizedSnackBar";
import { getCallDetails } from "../../../redux/strategy-session/callSchedule/Action";
import { Typo } from "../../../styled/Index";
import { COLORS, ICONS } from "../../../utils/Shared";
import { NAVIGATE_TO, PAGE_NAME } from "../../../routes/RoutePaths";
import { callSchedule, ScheduleDetails, Template } from "../../../utils/Data";
import { useStyles } from "./Styles";
import CustomButton from "../../../components/CustomButton";
import ScheduleCall from "./ScheduleCall";
import ScheduleCard from "./ScheduleCall";

export default function QpmcCall() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "*******************stepname**************");
  /**this variable is used to store the response from the api and it taken from the reducer state */
  const { callDetails, isLoading } = useSelector(
    (state) => state.callScheduleReducer
  );
  // const { productStages } = useSelector((state) => state.StudentReducer);

  const [openCallSchedulePopup, setOpenCallSchedulePopup] = useState(false);
  const [isReschedule, setIsReschedule] = useState(false);
  const handleRescheduleClick = () => {
    setOpenCallSchedulePopup(true);
    setIsReschedule(true);
  };
  const handleScheduleClick = () => {
    setOpenCallSchedulePopup(true);
    setIsReschedule(false);
  };

  const handleSchedulePopUpClose = () => {
    setOpenCallSchedulePopup(false);
  };

  const [state, setState] = useState({
    snackMsg: "",
    snackOpen: false,
    snackVariant: "error",
  });

  const [status, setStatus] = useState(false);

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackMsg: "", snackVariant: "" });
  };

  /**function to convert remaining time from seconds to hours and minutes */
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins ") : "";

    return hDisplay + mDisplay;
  };

  const renderDate = (date) => {
    let scheduledDate = moment(new Date(date)).format("dddd, MMM D");
    return scheduledDate;
  };
  // const stageName = "QPMC 1 call schedule"
  useEffect(() => {
    dispatch(getCallDetails(productId, "Pre Strategy call"));
  }, []);

  useEffect(() => {
    if (callDetails) {
      if (callDetails.success) {
        setState({
          ...state,
          snackOpen: false,
        });
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: callDetails && callDetails.message,
          snackVariant: "error",
        });
      }
    }
  }, [callDetails]);
  console.log(renderDate(callDetails?.data?.date), "&&&&&*****");

  const renderData = (callDetails) => {
    if (callDetails && callDetails.success) {
      if (callDetails && callDetails.data?.callStatus) {
        return (
          <>
            <CallDetails
              remainingTime={secondsToHms(callDetails.data.remainingTime)}
              date={callDetails?.data?.date}
              time={callDetails?.data?.time}
              callStatus={callDetails.data?.callStatus}
              handleClick={handleRescheduleClick}
              mentorName={callDetails.data.mentorName}
              momPoints={callDetails.data.momPoints}
              purposeOfCall={"Purpose of Pre Strategy Call"}
              callType={"Pre Strategy Mentor Call with"}
              callName={"Pre Strategy"}
              // isObCallCompleted={callDetails.data.isCallCompleted}
              description={Template}
            />
          </>
        );
      } else {
        return (
          <Box pt={22}>
            <Grid
              container
              gap={"20px"}
              height={"100%"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typo color={COLORS.lightBlack}>Recommender Selector Tool is Available Now Under Tools Menu</Typo>
              {ICONS.noCallScheduled}
              <Typo color={COLORS.lightBlack}>No call have been scheduled</Typo>
              <CustomButton
                color={"primary"}
                variant="contained"
                className={classes.scheduleButton}
                onClick={handleScheduleClick}
              >
                <Typo>Schedule the call</Typo>
              </CustomButton>
            </Grid>
          </Box>
        );
      }
    }
  };

  return (
    <div>
      {renderData(callDetails)}
      <ScheduleCard
        isOpen={openCallSchedulePopup}
        handleClose={handleSchedulePopUpClose}
        isReschedule={isReschedule}
        callName={"Pre Strategy call"}
        stepName={"Pre Strategy call"}
        appointmentId={callDetails && callDetails.data && callDetails.data.id}
      />
      <BackDropLoader open={isLoading} />
      <SnackBar
        open={state.snackOpen}
        severity={state.snackVariant}
        message={state.snackMsg}
        onClose={handleSnackClose}
      />
    </div>
  );
}
