import { STUDENT } from "./ActionType";

const initialState = {
  productStages: [],
  loading: false,
  sectionList: [],
  obCompleteProductList: null,
  obCompleteStatus: null,
  internShipStatus: null,
  getSubStageStatus: [],
  loginDate: ""
};

const StudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT.loginLoader:
      return { ...state, isLoading: true };
    case STUDENT.loginStudent:
      return { ...state, loginStudentData: action.payload, isLoading: false };
    case STUDENT.getBasicStudentData: {
      return {
        ...state,
        basicStudentDetails: action.payload,
        isLoading: false,
      };
    }
    case STUDENT.isGoogleLogin: {
      return {
        ...state,
        isGoogleLogin: action.payload,
      };
    }
    case STUDENT.isLoggedFirstTime: {
      return {
        ...state,
        isFirstTimeLogged: action.payload,
      };
    }
    case STUDENT.forgetPassword: {
      return {
        ...state,
        forgetPassResponse: action.payload,
        isLoading: action.loading,
      };
    }
    case STUDENT.clearForgetResponse: {
      return {
        ...state,
        forgetPassResponse: action.payload,
        isLoading: false,
      };
    }
    case STUDENT.clearLoginDetails: {
      return {
        ...state,
        loginStudentData: null,
        basicStudentDetails: null,
        isGoogleLogin: null,
        isFirstTimeLogged: null,
        isLoading: false,
      };
    }
    case STUDENT.resetPassword: {
      return {
        ...state,
        resetPassResponse: action.payload,
        isLoading: action.loading,
      };
    }
    case STUDENT.clearResetResponse: {
      return {
        ...state,
        resetPassResponse: action.payload,
        isLoading: false,
      };
    }
    case STUDENT.getStudentProductStages: {
      return {
        ...state,
        productStages: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.getStudentSectionByStageName: {
      return {
        ...state,
        sectionList: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.getOnboardingCompleteProduct: {
      return {
        ...state,
        obCompleteProductList: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.updateOnboardingCompleteProduct: {
      return {
        ...state,
        obCompleteStatus: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.getInternShipAccess: {
      return {
        ...state,
        internShipStatus: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.clearData: {
      return {};
    }
    case STUDENT.clearDataInSubStage: {
      return {
        ...state,
        getSubStageStatus: null,
      };
    }
    case STUDENT.getSubStageStatus: {
      return {
        ...state,
        getSubStageStatus: action.payload,
        loading: action.loading,
      };
    }
    case STUDENT.loginDate: {
      return {
        ...state,
        loginDate: action.payload,
      }
    }
    default:
      return state;
  }
};

export default StudentReducer;
