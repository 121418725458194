import { AdmitCard } from "./ActionType";

const INITIAL_STATE = {
  loading: false,
  getLeftContainer: null,
  postSchoolDetails: null,
  getAddedSchoolWithStatus: null,
  getAdmitCardNames: null,
  uploadFileResponse: null,
  downloadTemplateResponse: null,
  downloadUploadedFileResponse: null,
  downloadAdmitCardResponse: null,
};

const AdmitCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdmitCard.getLeftContainer:
      return {
        ...state,
        getLeftContainer: action.payload,
        loading: action.loading,
      };
    case AdmitCard.getAddedSchoolWithStatus:
      return {
        ...state,
        getAddedSchoolWithStatus: action.payload,
        loading: action.loading,
      };
    case AdmitCard.getAdmitCardName:
      return {
        ...state,
        getAdmitCardNames: action.payload,
        loading: action.loading,
      };
    case AdmitCard.postSchoolDetails:
      return {
        ...state,
        postSchoolDetails: action.payload,
        loading: action.loading,
      };
    case AdmitCard.uploadFile:
      return {
        ...state,
        uploadFileResponse: action.payload,
        loading: action.loading,
      };
    case AdmitCard.downloadTemplate:
      return {
        ...state,
        downloadTemplateResponse: action.payload,
        loading: action.loading,
      };
    case AdmitCard.downloadFile:
      return {
        ...state,
        downloadFileResponse: action.payload,
        loading: action.loading,
      };
    case AdmitCard.downloadUploadedFile:
      return {
        ...state,
        downloadUploadedFileResponse: action.payload,
        loading: action.loading,
      };
    case AdmitCard.downloadAdmitCard:
      return {
        ...state,
        downloadAdmitCardResponse: action.payload,
        loading: action.loading,
      };
    case AdmitCard.clearData:
      return {
        ...state,
        downloadAdmitCardResponse: null,
        downloadUploadedFileResponse: null,
        uploadFileResponse: null,
        downloadFileResponse: null,
        // downloadTemplateResponse: null,
      };

    case AdmitCard.loader:
      return { ...state, loading: true };

    default:
      return state;
  }
};

export default AdmitCardReducer;
