import { TextField } from "@mui/material";
import moment from "moment";
import React from "react";
// import "../assets/css/InputFields.css";

function TextBox({
  value,
  disabled,
  onChange,
  label,
  type,
  name,
  error,
  helperText,
  InputProps,
  ComponentInputProps,
  placeholder,
  onKeyPress,
  onKeyDown,
  ...rest
}) {
  return (
    <TextField
      color={"primary"}
      variant={"standard"}
      value={value}
      onChange={onChange}
      disabled={disabled}
      type={type}
      label={label}
      name={name}
      error={error}
      helperText={helperText}
      inputProps={InputProps}
      InputProps={ComponentInputProps}
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      {...rest}
    />
  );
}

export default TextBox;
