import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export function DropDown({
  value,
  onChange,
  onBlur,
  options,
  getOptionLabel,
  label,
  name,
  error,
  helperText,
  popupIcon,
  multiple,
  groupBy,
  required,
  renderOption,
}) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      popupIcon={popupIcon}
      value={value}
      groupBy={groupBy}
      onBlur={onBlur}
      onChange={onChange}
      name={name}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"standard"}
          label={label}
          name={name}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
    />
  );
}

export default DropDown;
