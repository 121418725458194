import { Aspiration } from "./Actiontype";

const INITIAL_STATE = {
  getAspiration: null,
  getDegree: null,
  getIntake: null,
  getFieldofStudy: null,
  getPreferredPackage: null,
  getPreferredLocation: null,
  getWork: null,
  getRegion: null,
  getSchool: null,
  getAreaofSpecialsation: null,
  postAspiration: null,
  postFieldOfStudy: []
};

const AspirationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Aspiration.getAspiration:
      return {
        ...state,
        getAspiration: action.payload,
      };
    case Aspiration.getDegree:
      return {
        ...state,
        getDegree: action.payload,
      };
    case Aspiration.getIntake:
      return {
        ...state,
        getIntake: action.payload,
      };
    case Aspiration.getFieldofStudy:
      return {
        ...state,
        getFieldofStudy: action.payload,
      };
    case Aspiration.getAreaofSpecialsation:
      return {
        ...state,
        getAreaofSpecialsation: action.payload,
      };
    case Aspiration.getRegion:
      return {
        ...state,
        getRegion: action.payload,
      };
    case Aspiration.getSchool:
      return {
        ...state,
        getSchool: action.payload,
      };
    case Aspiration.getPreferredLocation:
      return {
        ...state,
        getPreferredLocation: action.payload,
      };
    case Aspiration.getPreferredPackage:
      return {
        ...state,
        getPreferredPackage: action.payload,
      };
    case Aspiration.getWork:
      return {
        ...state,
        getWork: action.payload,
      };
    case Aspiration.postAspiration:
      return {
        ...state,
        postAspiration: action.payload,
      };
    case Aspiration.postFieldOfStudy:
      return {
        ...state,
        postFieldOfStudy: action.payload,
      };
    case Aspiration.clearAspiration:
      return {
        ...state,
        postAspiration: null,
      };

    default:
      return state;
  }
};

export default AspirationReducer;
