import { GAT } from "./ActionType";

const INITIAL_STATE = {
  takenList: null,
  takenInfo: null,
  upcomingList: null,
  postResponse: null,
  putResponse: null,
  isLoading: false,
  deleteFileResponse: null,
  uploadFileResponse: null,
  deleteGatInfoResponse: null,
};

const GraduateAdmissionTestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAT.gatLoader:
      return {
        ...state,
        isLoading: true,
      };
    /**GRE */
    case GAT.getGreData:
      return {
        ...state,
        takenList: action.payload,
        isLoading: action.loading,
      };
    case GAT.getGreInfo:
      return {
        ...state,
        takenInfo: action.payload,
        isLoading: action.loading,
      };
    case GAT.getUpcomingGreData:
      return {
        ...state,
        upcomingList: action.payload,
        isLoading: action.loading,
      };
    case GAT.saveGreData:
      return {
        ...state,
        postResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.updateGreData:
      return {
        ...state,
        putResponse: action.payload,
        isLoading: action.loading,
      };

    /**GMAT */
    case GAT.getGmatData:
      return {
        ...state,
        takenList: action.payload,
        isLoading: action.loading,
      };
    case GAT.getGmatInfo:
      return {
        ...state,
        takenInfo: action.payload,
        isLoading: action.loading,
      };
    case GAT.getUpcomingGmatData:
      return {
        ...state,
        upcomingList: action.payload,
        isLoading: action.loading,
      };
    case GAT.saveGmatData:
      return {
        ...state,
        postResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.updateGmatData:
      return {
        ...state,
        putResponse: action.payload,
        isLoading: action.loading,
      };

    /**TOEFL */
    case GAT.getToeflData:
      return {
        ...state,
        takenList: action.payload,
        isLoading: action.loading,
      };
    case GAT.getToeflInfo:
      return {
        ...state,
        takenInfo: action.payload,
        isLoading: action.loading,
      };
    case GAT.getUpcomingToeflData:
      return {
        ...state,
        upcomingList: action.payload,
        isLoading: action.loading,
      };
    case GAT.saveToeflData:
      return {
        ...state,
        postResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.updateToeflData:
      return {
        ...state,
        putResponse: action.payload,
        isLoading: action.loading,
      };

    /**IELTS */
    case GAT.getIeltsData:
      return {
        ...state,
        takenList: action.payload,
        isLoading: action.loading,
      };
    case GAT.getIeltsInfo:
      return {
        ...state,
        takenInfo: action.payload,
        isLoading: action.loading,
      };
    case GAT.getUpcomingIeltsData:
      return {
        ...state,
        upcomingList: action.payload,
        isLoading: action.loading,
      };
    case GAT.saveIeltsData:
      return {
        ...state,
        postResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.updateIeltsData:
      return {
        ...state,
        putResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.deleteFile:
      return {
        ...state,
        deleteFileResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.uploadFile:
      return {
        ...state,
        uploadFileResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.deleteGatInfo:
      return {
        ...state,
        deleteGatInfoResponse: action.payload,
        isLoading: action.loading,
      };
    case GAT.gatClearData:
      return {};
    default:
      return state;
  }
};

export default GraduateAdmissionTestReducer;
