import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import showCalendar from "../assets/images/ShowCalendar.png";
import TimerIcon from "../assets/images/Timer.png";
import CustomButton from "../components/CustomButton";
import { CallDetailCard, Typo } from "../styled/Index";
import { useStyles } from "./Styles";

const CallDetailsCard = ({
  remainingTime,
  date,
  time,
  callStatus,
  handleClick,
  callType,
  mentorName,
  ppgaNotes,
  momPoints,
  isPgaCallCompleted,
  isObCallCompleted,
  isSkipObCall,
  isSkipPPGA,
  disabled
}) => {
  const classes = useStyles();
  console.log(disabled, "hdjdjdjdjk");

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <CallDetailCard>
          <Grid item container spacing={1}>
            <Grid item md={7} sm={12} xs={7} display="flex">
              <Typo color={"textPrimary"} fontWeight={"600"}>
                {callType}
              </Typo>
              <Typo
                className={classes.leftSpacing}
                color={"#18AAE7"}
                fontWeight={"600"}
              >
                {mentorName}
              </Typo>
            </Grid>
            <Grid
              item
              md={5}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {ppgaNotes || momPoints || isPgaCallCompleted || isObCallCompleted ? (
                <></>
              ) : (
                <>
                  <AccessTimeIcon className={classes.remainingTimerIcon} />
                  <Typo className={classes.remainingTimeText}>
                    {remainingTime}
                  </Typo>
                </>
              )}
            </Grid>



            <Grid item container>
              <div>
                {!isSkipObCall && !isSkipPPGA? (

                  <Grid
                    item
                    md={5}
                    sm={12}
                    xs={12}
                    display="flex"
                    alignItems="center"
                  >
                    <img
                      src={showCalendar}
                      alt=""
                      color="#000000"
                      className={classes.calendarIcon}
                    />

                    <Box pl={1}>
                      <Typo
                      // color={"HighlightText"}
                      >
                        {date}
                      </Typo>
                    </Box>

                  </Grid>
                ) : (<></>)}

              </div>
              <div className={classes.leftSpacing}>
                {!isSkipObCall && !isSkipPPGA? (

                  <Grid
                    item
                    md={4}
                    sm={12}
                    xs={12}
                    display="flex"
                    alignItems="center"
                  >

                    <img src={TimerIcon} alt="" className={classes.timerIcon} />
                    <Box pl={1}>
                      <Typo
                      //  color={"HighlightText"}
                      >
                        {time}
                      </Typo>
                    </Box>

                  </Grid>
                ) : (<></>)}
              </div>

            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              {/* <Box pt={1}>
                <CustomButton
                  color={"primary"}
                  variant="contained"
                  className={classes.rescheduleButton}
                  onClick={handleClick}
                >
                  Reschedule
                </CustomButton>
              </Box> */}



              {ppgaNotes || momPoints || isPgaCallCompleted || isObCallCompleted ? (
                <Box pt={1}>
                  <CustomButton
                    color={"secondary"}
                    variant="contained"
                    className={classes.rescheduleButton}
                  >
                    <Typo color={"white"}>Completed</Typo>
                  </CustomButton>

                </Box>
              ) : isSkipObCall ? (
                <Box pt={1}>

                  <CustomButton
                    color={"secondary"}
                    variant="contained"
                    className={classes.rescheduleButton}
                  >
                    <Typo color={"white"}>Skipped</Typo>
                  </CustomButton>
                </Box>
              ) : isSkipPPGA ? (
                <Box pt={1}>

                  <CustomButton
                    color={"secondary"}
                    variant="contained"
                    className={classes.rescheduleButton}
                  >
                    <Typo color={"white"}>Skipped</Typo>
                  </CustomButton>
                </Box>
              ) :
              
              (
                <Box pt={1}>
                  <CustomButton
                    color={"primary"}
                    variant="contained"
                    className={classes.rescheduleButton}
                    // className={classes.pointerEvents}
                    style={{
                      pointerEvents: disabled ? "none" : "unset",
                    }}
                    // disabled={disabled}
                    onClick={handleClick}
                  >
                    <Typo>Reschedule</Typo>
                  </CustomButton>
                </Box>

              )}

            </Grid>
          </Grid>
        </CallDetailCard>
      </Grid>
    </Grid>
  );
};
export default CallDetailsCard;
