export const Academic = {
  getStatus: "getStatus",
  updateDetails: "updateDetails",
  deleteSemDetails: "deleteSemDetails",
  deleteFiles: "deleteFiles",
  getdegree: "getdegree",
  getdepartment: "getdepartment",
  getuniversity: "getuniversity",
  getcollege: "getcollege",
  getExamBoard: "getExamBoard",
  getAcademic: "getAcademic",
  documentUpload: "documentUpload",
  updateSemdetails: "updateSemdetails",
  uploadSemFile: "uploadSemFile",
  clearAcademic: "clearAcademic",
};
