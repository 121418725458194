import { ProfileMentoring } from "./ActionType";

const INITIAL_STATE = {
  getDocumentList: null,
  loading: false,
  uploadDocument: null,
  downloadDocument: null,
  getLeftContainer: null,
  completeStage: null,
  QPMC2ReportReducer: null,
  downloadDocumentModal: null,
  downloadDocumentModals: null,
  getWorkSheetSteps: null,
};

const ProfileMentoringReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfileMentoring.getDocumentList:
      return {
        ...state,
        getDocumentList: action.payload,
        loading: action.loading,
      };

    case ProfileMentoring.QPMC2ReportReducer:
      console.log(action.payload, "************************result for school");

      return {
        ...state,
        QPMC2ReportReducer: action.payload,
        loading: action.loading,
      };
    case ProfileMentoring.workSheetSteps:
      return {
        ...state,
        getWorkSheetSteps: action.payload,
        loading: action.loading,
      };
    case ProfileMentoring.downloadDocument:
      return {
        ...state,
        downloadDocument: action.payload,
        loading: action.loading,
      };

    case ProfileMentoring.uploadDocument:
      return {
        ...state,
        uploadDocument: action.payload,
        loading: action.loading,
      };

    case ProfileMentoring.getLeftContainer:
      return {
        ...state,
        getLeftContainer: action.payload,
        loading: action.loading,
      };

    case ProfileMentoring.completeStage:
      return {
        ...state,
        completeStage: action.payload,
        loading: action.loading,
      };
    case ProfileMentoring.downloadDocumentModal:
      return {
        ...state,
        downloadDocumentModal: action.payload,
        loading: action.loading,
      };
    case ProfileMentoring.downloadDocumentModals:
      return {
        ...state,
        downloadDocumentModals: action.payload,
        loading: action.loading,
      };
    case ProfileMentoring.clearData:
      return {};
    case ProfileMentoring.clearCustomData:
      return {
        QPMC2ReportReducer: [],
      };
    case ProfileMentoring.loader:
      console.log(state, "**********hai********");
      return { ...state, loading: true };

    default:
      console.log(state, "**********hai********");

      return state;
  }
};

export default ProfileMentoringReducer;
