export const STRATEGY_SESSION = {
  loader: "strategySessionLoader",
  clearData: "strategySessionClearData",
  clearCustomData: "strategySessionClearCustomData",
  getDocumentModelBySubStageId: "getDocumentModelBySubStageId",
  postFileUploadBySubStageId: "postFileUploadBySubStageId",
  putDocumentBySubStageId: "putDocumentBySubStageId",
  getDownloadByDocumentId: "getDownloadByDocumentId",
  getDownloadByDocumentIdNew: "getDownloadByDocumentIdNew",
  deleteDocumentByDocumentId: "deleteDocumentByDocumentId",
  strategySessionComplete: "strategySessionComplete",
  getStepsBySubStageId: "getStrategySessionStepsBySubStageId",

  /**GRE */
  getGreData: "getTestTranscriptsGreData",
  saveGreData: "saveTestTranscriptsGreData",
  updateGreData: "updateTestTranscriptsGreData",
  getGreInfo: "getTestTranscriptsGreInfo",

  /**GMAT */
  getGmatData: "getTestTranscriptsGmatData",
  saveGmatData: "saveTestTranscriptsGmatData",
  updateGmatData: "updateTestTranscriptsGmatData",
  getGmatInfo: "getTestTranscriptsGmatInfo",

  /**TOEFL */
  getToeflData: "getTestTranscriptsToeflData",
  saveToeflData: "saveTestTranscriptsToeflData",
  updateToeflData: "updateTestTranscriptsToeflData",
  getToeflInfo: "getTestTranscriptsToeflInfo",

  /**IELTS */
  getIeltsData: "getTestTranscriptsIeltsData",
  saveIeltsData: "saveTestTranscriptsIeltsData",
  updateIeltsData: "updateTestTranscriptsIeltsData",
  getIeltsInfo: "getTestTranscriptsIeltsInfo",

  deleteFile: "gatTestTranscriptsDeleteFile",
  uploadFile: "gatTestTranscriptsUploadFile",
  deleteGatInfo: "deleteTestTranscriptsGatInfo",
  getSubStages: "getSubStages",
};
