import { Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS } from "../utils/Shared";

export const ContentPanel = styled.div`
  height: 80vh;
  border: 1px solid ${COLORS.lightAsh};
  border-radius: 4px;
  padding: ${(props) => props.p && "10px"};
  overflow-y: ${(props) => props.overflow};
`;

export const Typo = styled(Typography)`
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  z-index: ${(props) => props.zIndex};
  text-align: ${(props) => props.textAlign};
  font-weight: ${(props) => props.fontWeight};
`;

export const CustomCard = styled.div`
  height: ${(props) => props.height};
  display: block;
  position: relative;
  &:hover {
    box-shadow: 0 0 8px #ccc;
  }
`;

export const IconDiv = styled.div`
  position: absolute;
  display: ${({ show }) => (show ? "block" : "none")};
  top: 5px;
  right: 2px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 30px;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 40%;
    color: #81c14f;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${COLORS.secondary};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  padding: ${(props) => props.padding};
  ::-webkit-scrollbar {
    /* display: none; */
  }
`;

export const InfoPopup = styled.div`
  height: 500px;
  width: 500px;
`;

export const CallDetailCard = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  opacity: 1;
  padding: 4%;
  &:hover {
    box-shadow: 0 0 8px #ccc;
  }
`;

export const CommentBoxPaper = styled.div`
  width: 440px;
  position: relative;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px 15px;
  top: 15px;

  &:before {
    content: "";
    position: absolute;
    top: -21px;
    right: 5px;
    z-index: 1;
    border: solid 10px transparent;
    border-bottom-color: #e7e7e7;
  }
  &:after {
    content: "";
    position: absolute;
    top: -19px;
    right: 5px;
    z-index: 1;
    border: solid 10px transparent;
    border-bottom-color: #ffffff;
  }
`;

export const B1 = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-top: 135px;
`;

export const B2 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 150%;
  text-align: center;
  color: #052a4e;
`;

export const CardText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 22px;
  color: #000000;
`;
