import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import CustomDropzone from "../../components/CustomDropzone";
import DropDown from "../../components/DropDown";
import TextBox from "../../components/TextBox";
import { cgpaList } from "../../utils/Data";
import { isNumber } from "../../utils/HelperFunction";

function AcademicPopup({
  onDrop,
  finalFile,
  fileErr,
  handleDeleteClick,
  formik,
  academicList,
  handleCgpaChange,
  contentForContent
}) {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        <DropDown
          required
          options={academicList}
          getOptionLabel={(option) => option.title}
          value={formik.values.semacademic}
          onBlur={formik.handleBlur}
          onChange={(e, value) => formik.setFieldValue("semacademic", value)}
          label="Select Semester"
          name="semacademic"
          variant="semacademic"
          error={formik.touched.semacademic && formik.errors.semacademic}
          helperText={formik.touched.semacademic && formik.errors.semacademic}
        />
      </Grid>
      <Grid item md={8}></Grid>
      <Grid item md={3} sm={12} xs={12}>
        <TextBox
          required
          fullWidth
          name="activeBacklog"
          label="Backlogs (Active)"
          value={formik.values.activeBacklog}
          onChange={formik.handleChange}
          onKeyPress={(evt) => {
            if (isNumber(evt) || evt.target.value?.length >= 5)
              evt.preventDefault();
          }}
          error={formik.touched.activeBacklog && formik.errors.activeBacklog}
          helperText={
            formik.touched.activeBacklog && formik.errors.activeBacklog
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <TextBox
          required
          fullWidth
          name="clearedBacklog"
          label="Backlogs (Cleared)"
          value={formik.values.clearedBacklog}
          onChange={formik.handleChange}
          onKeyPress={(evt) => {
            if (isNumber(evt) || evt.target.value?.length >= 2)
              evt.preventDefault();
          }}
          error={formik.touched.clearedBacklog && formik.errors.clearedBacklog}
          helperText={
            formik.touched.clearedBacklog && formik.errors.clearedBacklog
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <DropDown
          required
          options={cgpaList}
          getOptionLabel={(option) => option.title}
          value={formik.values.semcgpaScale}
          onBlur={formik.handleBlur}
          onChange={(e, value) => formik.setFieldValue("semcgpaScale", value)}
          label="SGPA Scale"
          name="semcgpaScale"
          variant="standard"
          error={formik.touched.semcgpaScale && formik.errors.semcgpaScale}
          helperText={formik.touched.semcgpaScale && formik.errors.semcgpaScale}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <TextBox
          required
          fullWidth
          name="semcgpa"
          label="SGPA"
          value={formik.values.semcgpa}
          disabled={
            formik.values.semcgpaScale === null ||
            formik.values.semcgpaScale === ""
          }
          onChange={(e) => handleCgpaChange(e)}
          error={formik.touched.semcgpa && formik.errors.semcgpa}
          onKeyPress={(evt) => {
            if (isNumber(evt) || evt.target.value?.length >= 3)
              evt.preventDefault();
          }}
          helperText={formik.touched.semcgpa && formik.errors.semcgpa}
          InputProps={{ maxLength: 5 }}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <CustomDropzone
          fileErr={fileErr}
          finalFile={finalFile ? finalFile : null}
          onDrop={onDrop}
          fileType={".pdf"}
          content={contentForContent}
          handleDeleteClick={handleDeleteClick}
        />
      </Grid>
    </Grid>
  );
}

export default AcademicPopup;
