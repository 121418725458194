import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useSelector } from "react-redux";
import { Typo } from "../../../styled/Index";
import { useStyles } from "../Styles";
import { DAYS, MONTHS } from "../Variables";
import * as moment from "moment";
import { Link } from "react-router-dom";

const DisplayTimeSlots = ({
  selectedDate,
  timeSlots,
  padAdd,
  duration,
  timeSlotClick,
  selectedSlot,
}) => {
  /**using moment we can convert date to required format */
  let date = moment(new Date(selectedDate)).format("dddd, MMM D");

  const renderTimeSlots = () => {
    if (timeSlots && timeSlots.length > 0) {
      return (
        <>
          {timeSlots &&
            timeSlots.map((item) => {
              let endHours = new Date(item.time).getHours();
              console.log(endHours, "*************************end hours");
              let endTime = new Date(item.time).getMinutes() + Number(duration);
              console.log(
                endTime,
                "*************************end hours 1",
                new Date(item.time).getMinutes()
              );
              let finalEndHours = endTime >= 60 ? endHours + 1 : endHours;
              let finalEndMinutes = endTime >= 60 ? endTime % 60 : endTime;
              let data =
                new Date(item.time).getHours() <= 12
                  ? padAdd(new Date(item.time).getHours()) +
                    ":" +
                    padAdd(new Date(item.time).getMinutes())
                  : padAdd(new Date(item.time).getHours() % 12) +
                    ":" +
                    padAdd(new Date(item.time).getMinutes());

              let format = new Date(item.time).getHours() < 12 ? " AM" : " PM";
              let finalHours =
                finalEndHours <= 12
                  ? padAdd(finalEndHours)
                  : padAdd(finalEndHours % 12);
              let endMinutes = padAdd(finalEndMinutes);
              let endHour =
                padAdd(new Date(item.time).getHours()) < 12 ? " AM" : " PM";

              let finalTimeSlot =
                data + format + " - " + finalHours + ":" + endMinutes + endHour;
              return (
                <Grid item md={4} sm={4} lg={4} xs={4}>
                  <div
                    onClick={() => timeSlotClick(finalTimeSlot, item)}
                    className={
                      selectedSlot === finalTimeSlot
                        ? classes.timeSlotActive
                        : classes.timeSlotInActive
                    }
                  >
                    <Typo
                      color={selectedSlot === finalTimeSlot ? "#fff" : "#000"}
                      variant={"subtitle2"}
                      // color={"inherit"}
                    >
                      {data +
                        format +
                        " - " +
                        finalHours +
                        ":" +
                        endMinutes +
                        endHour}
                      {console.log(
                        data,
                        format,
                        finalHours,
                        endMinutes,
                        endHour,
                        "*************************end hours"
                      )}
                    </Typo>
                  </div>
                </Grid>
              );
            })}
        </>
      );
    } else {
      return <Typo>No Time Slots Available</Typo>;
    }
  };

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        {label}
      </Link>
    );
  };

  const classes = useStyles();
  const { getVariantSteps } = useSelector((state) => state.OnBoardingReducer);
  return (
    <Box pt={1}>
      <Grid container spacing={1} display="flex">
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <Typo color={"textPrimary"} fontWeight={"600"}>
            {/* Select available time */}
            At present call scheduling feature is unavailable
          </Typo>
        </Grid>
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <Typo>
            {getVariantSteps.referProductCodeName === "PBM" ||
            getVariantSteps.referProductCodeName === "PBP" ? (
              <>
                Please drop an email to{" "}
                <ButtonMailto
                  label="profileprep@thecareerlabs.com"
                  mailto="mailto:profileprep@thecareerlabs.com"
                />{" "}
                with your convenient slot between 12pm - 7pm (Tues- Sat), the
                team will assist you with a google meet call.{" "}
              </>
            ) : getVariantSteps.referProductCodeName === "GMAT_001" ? (
              <>
                Please drop an email to{" "}
                <ButtonMailto
                  label="testprep@thecareerlabs.com"
                  mailto="mailto:testprep@thecareerlabs.com"
                />{" "}
                with your convenient day (Mon, Wed, Fri) between 6.30pm -7.30pm,
                the team will assist you with a google meet call.{" "}
              </>
            ) : getVariantSteps.referProductCodeName === "GRE_001" ? (
              <>
                Please drop an email to{" "}
                <ButtonMailto
                  label="testprep@thecareerlabs.com"
                  mailto="mailto:testprep@thecareerlabs.com"
                />{" "}
                with your convenient day (Tues & Thurs) between 6.30pm -7.30pm,
                the team will assist you with a google meet call.{" "}
              </>
            ) : getVariantSteps?.referProductCodeName === "ACS_MS" ? (
              <>
                Please drop an email to{" "}
                <ButtonMailto
                  label="msconsulting@thecareerlabs.com"
                  mailto="mailto:msconsulting@thecareerlabs.com"
                />{" "}
                with your convenient slot between 12pm - 7pm (Tues- Sat), the
                team will assist you with a google meet call.
              </>
            ) : (
              <>
                Please drop an email to{" "}
                <ButtonMailto
                  label="mbaconsulting@thecareerlabs.com"
                  mailto="mailto:mbaconsulting@thecareerlabs.com"
                />{" "}
                with your convenient slot between 12pm - 7pm (Tues- Fri), the
                team will assist you with a google meet call.{" "}
              </>
            )}
          </Typo>
        </Grid>

        {/* <Grid item md={12} sm={12} lg={12} xs={12}>
          <Typo>In your local time zone (GMT +05:30)</Typo>
        </Grid> */}

        {/* it displays the date selected from the calendar otherwise displays the current date(Tuesday, Oct 17)*/}
        {/* <Grid item md={12} sm={12} lg={12} xs={12}>
          <div className={classes.selectedDateStyle}>
            <Typo color={"textPrimary"} fontWeight={"600"}>
              {date}
            </Typo>
          </div>
        </Grid> */}

        {/* time slots grid container and converts the timeSlots from the response to (10:00 AM - 10:45 AM) format */}
        {/* <Grid
          item
          container
          rowSpacing={1}
          className={classes.timeSlotContainer}
        >
          {renderTimeSlots()}
        </Grid> */}
      </Grid>
    </Box>
  );
};
export default DisplayTimeSlots;
