import { combineReducers } from "redux";
import HelperReducer from "./helper/Reducer";
import OnBoardingReducer from "./onBoarding/Reducer";
import StudentReducer from "./student/Reducer";
import GraduateAdmissionTestReducer from "./graduate-admission-test/Reducer";
import PersonalInformationReducer from "./personal-info/Reducer";
import DeadlineTrackerReducer from "./deadline-tracker/Reducer";
import CourseReducer from "./course/Reducer";
import ProfileGapAnalysisReducer from "./profile-gap-analysis/Reducer";
import InternshipReducer from "./internship/Reducer";
import AcademicReducer from "./academic/Reducer";
import BSchoolFilterToolReducer from "./bschool-filter/Reducer";
import AspirationReducer from "./aspiration/Reducer";
import CVReducer from "./uploadCV/Reducer";
import TestAndSurveyReducer from "./test-survey/Reducer";
import StrategySessionReducer from "./strategy-session/Reducer";
import ProfileMentoringReducer from "./profile-mentoring/Reducer";
import ApplicationReducer from "./application-stage/Reducer";
import callScheduleReducer from "./profile-mentoring/callSchedule/Reducer";
import additionalPointsReducer from "./profile-mentoring/additionalPoints/Reducer";
import InterviewReducer from "./pas/InterviewCall/Reducer";
import ResourceReducer from "./pas/Resources/Reducer";
import AdmitCardReducer from "./postAdmitServices/admitCard/Reducer";
import SupportPage from "./supportPage/Reducer";

export default combineReducers({
  HelperReducer,
  OnBoardingReducer,
  StudentReducer,
  GraduateAdmissionTestReducer,
  PersonalInformationReducer,
  AcademicReducer,
  DeadlineTrackerReducer,
  CourseReducer,
  ProfileGapAnalysisReducer,
  InternshipReducer,
  BSchoolFilterToolReducer,
  AspirationReducer,
  CVReducer,
  TestAndSurveyReducer,
  StrategySessionReducer,
  ProfileMentoringReducer,
  ApplicationReducer,
  callScheduleReducer,
  additionalPointsReducer,
  InterviewReducer,
  ResourceReducer,
  AdmitCardReducer,
  SupportPage,
});
