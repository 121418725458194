export const TEST_SURVEY = {
  testAndSurveyLoader: "testAndSurveyLoader",
  getTestDetails: "getTestDetails",
  startTest: "startTest",
  submitAnswer: "submitAnswer",
  addTechnicalSection: "addTechnicalSection",
  getPreview: "getPreview",
  saveSupplementaryAnswer: "saveSupplementaryAnswer",
  clearAddTechnicalTest: "clearAddTechnicalTest",
};
