import { GETTER } from "../utils/Constants";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_FOR_INTERN = process.env.REACT_APP_API_URL_FOR_INTERN;
export const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const CV_BUILDER_URL = process.env.REACT_APP_CV_BUILDER_URL;
export const LOG_OUT_URL = process.env.REACT_APP_API_URL;
export const B_SCHOOL_FILTER_TOOL_URL =
  process.env.REACT_APP_B_SCHOOL_FILTER_URL;
export const SCHOOL_RESEARCH_TOOL_URL =
  process.env.REACT_APP_SCHOOL_RESEARCH_TOOL_URL;
export const ADMIT_SELECTOR_TOOl_URL =
  process.env.REACT_APP_ADMIT_SELECTOR_TOOL_URL;
export const LMS_WEB_URL = process.env.REACT_APP_LMS_WEB_URL;
export const RECOMMENDER_SELECTOR_TOOl_URL =
  process.env.REACT_APP_RECOMMENDER_SELECTOR_TOOl_URL;
console.log(
  process.env,
  BASE_URL_FOR_INTERN,
  "*********************DDDDDDDDDDDDDDDDD"
);
export const STUDENT_URL = {
  getStudentProduct: () =>
    `/api/v1/get/allocate/student/product/${GETTER.sessionGetter("studentId")}`,
  getStudentProductStages: (productId, stageName) =>
    `/api/v1/students/${GETTER.sessionGetter(
      "studentId"
    )}/products/${productId}/stages`,
  getStudentSectionByStageName: (productId, stageName) =>
    `/api/v1/students/${GETTER.sessionGetter(
      "studentId"
    )}/products/${productId}/step?stepName=${stageName}`,
  agreeProductTnc: (productId, status) =>
    `/api/v1/student/${GETTER.sessionGetter(
      "studentId"
    )}/product/${productId}/checkTnc?tnc=${status}`,
  loggedFirstTime: (studentId) =>
    `/api/v1/students/${studentId}/firsttimelogin`,
  termsAndConditionStatus: (studentId) => `/api/v1/students/${studentId}/tnc`,
  loginDate: (dateId) => `/api/v1/students/${dateId}/login?platform=UP`,
  completeOnboarding: (productId) =>
    `/api/v1/students/${GETTER.sessionGetter(
      "studentId"
    )}/product/${productId}/onBoardingCompleteCall?field=student`,
  putOnboardingCompleteProduct: (studentId) =>
    `/api/v1/students/${studentId}/notCompleteOnboarding`,
  getOnboardingCompleteProduct: (studentId) =>
    `/api/v1/students/${studentId}/notCompleteOnboarding`,
  getInternShipAccess: (studentId) => `/api/v1/students/${studentId}/oldUser`,
  getSubStageStatus: (studentId, productId, stageId) =>
    `/api/v1/students/${studentId}/products/${productId}/${stageId}/substagestatus`,
};

export const WORK_EXP_URL = {
  getAllWorkExp: () =>
    `/api/v1/students/${GETTER.sessionGetter("studentId")}/employments`,
  deleteRoleById: (roleId) =>
    `/api/v1/students/${GETTER.sessionGetter(
      "studentId"
    )}/employments/${roleId}`,
  deleteOrganizationByName: (organizationName) =>
    `/api/v1/students/${GETTER.sessionGetter(
      "studentId"
    )}/employments/role/${organizationName}`,
  saveOrganization: () =>
    `/api/v1/students/${GETTER.sessionGetter("studentId")}/employments`,
};

export const AUTHENTICATION_URL = {
  login: `${BASE_URL}/api/v1/auth/signin`,
  studentBasicDetails: `${AUTH_URL}/auth/students/`,
  forgetPassword: `${AUTH_URL}/auth/forgot-password`,
  resetPassword: `${AUTH_URL}/auth/reset-password/`,
};

export const PERSONAL_INFORMATION_URL = {
  getPersonalDetails: (studentId) => `/api/v1/students/${studentId}`,
  getPersonalImage: (studentId) =>
    `/api/v1/files/students/${studentId}/profileimage`,
  updatePersonalDetails: (studentId) =>
    `/api/v1/students/${studentId}/personaldetails`,
  uploadProfileImage: (studentId) =>
    `/api/v1/files/students/${studentId}/profileimage`,
};

export const ONBOARDING_URL = {
  getVariantSteps: (studentId, productId) =>
    `/api/v1/get/product/varient/steps/${productId}?studentId=${studentId}`,
  timeSlots: (studentId, productId, appointmentId, calendarId, date) =>
    `/api/v1/schedule/listavailabletimes?appointmentTypeID=${appointmentId}&calendarID=${calendarId}&date=${date}&timezone&type=pga`,
  callDetails: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/obcall`,
  callConfirm: (studentId, productId) =>
    `/api/v1/schedule/appointment/${studentId}/${productId}`,
  rescheduleCall: (appointmentId, studentId) =>
    `/api/v1/reschedule/appointment/${appointmentId}/${studentId}`,
  getManualGuide: (productId) =>
    `/api/v1/products/${productId}/userguidelink?userType=Student`,
};
export const QPMC_URL = {
  getVariantSteps: (studentId, productId) =>
    `/api/v1/get/product/varient/steps/${productId}?studentId=${studentId}`,
  timeSlots: (studentId, productId, appointmentId, calendarId, date) =>
    `/api/v1/schedule/listavailabletimes?appointmentTypeID=${appointmentId}&calendarID=${calendarId}&date=${date}&timezone&type=pga`,
  callDetails: (studentId, productId, stageName) =>
    // `/api/v1/students/${studentId}/products/${productId}/obcall`,
    `api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=${stageName}`,
  callConfirm: (studentId, productId) =>
    `/api/v1/schedule/appointment/${studentId}/${productId}`,
  rescheduleCall: (appointmentId, studentId) =>
    `/api/v1/reschedule/appointment/${appointmentId}/${studentId}`,
};

export const DEADLINE_TRACKER_URL = {
  postBShool: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/page/advance/search`,
  postCounty: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/country/advance/search`,
  postRegion: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/regions/advance/search`,
  postSubRegion: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/subRegions/advance/search`,
  postSearch: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/round/search`,
  getAllBShool: (studentId) => `/api/v1/students/${studentId}/bSchool`,
  getSchoolLogoList: (studentId) => `/api/v1/students/${studentId}/bSchool`,
  postIntakeYear: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/intakeYear/advance/search`,
  postIntakeTerm: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/seasons/advance/search`,
  postRoundNumber: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/round/advance/search`,
  getDeadlineVisitedPage: (studentId) =>
    `api/v1/students/${studentId}/visitedPage`,
  putVisitedPage: (studentId) => `/api/v1/lms/students/${studentId}`,
};

export const PROFILE_GAP_ANALYSIS_URL = {
  getS3LinkTobase64: (studentId) =>
    `/api/v1/files/basesixtyfour/${studentId}/image`,
  getReportData: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=pga-report`,
  getPgaReport: (studentId, productId, stageId) =>
    `api/v1/files/students/${studentId}/products/${productId}/stage/${stageId}/pgaReport`,
  downloadPgaReport: (studentId, productId, fileId) =>
    `api/v1/files/download/students/${studentId}/${fileId}/products/${productId}/pgaReport`,
  getPlanOfAction: (studentId, productId, versionId) =>
    `/api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=plan-of-action&old=true&versionNo=${versionId}`,
  updateStatus: (studentId, productId, versionId) =>
    `/api/v1/students/${studentId}/product/${productId}/pgaReport/poaFocus?versionNo=${versionId}`,
  reportSatisfaction: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/pgaSatisfication`,
  saveComments: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/stuedntPgaReportComment`,
  getPrePgaCall: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=pre-pga-call`,
  getPgaCall: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=pga-call`,
  getPlanActionStatus: (studentId, productId, versionId) =>
    `/api/v1/students/${studentId}/products/${productId}/planOfActionStatus?versionNo=${versionId}`,
  cvReviewGetList: (studentId, productId) =>
    `/api/v1/cv/students/${studentId}/product/${productId}/studentcv`,
  putSendForReview: (studentId, productId, Id) =>
    `/api/v1/cv/students/${studentId}/product/${productId}/studentcv?cvId=${Id}&status=REVIEW`,
  cvDownload: (studentId, productId, studentCvId) =>
    `/api/v1/cv/students/${studentId}/product/${productId}/downloadcv/${studentCvId}`,
  cvDelete: (studentId, studentCvId) =>
    `/api/v1/cv/delete/students/${studentId}/studentcv/${studentCvId}`,
  cvUpload: (studentId, productId) => `/api/v1/cv/${studentId}/${productId}`,
  getCv: (studentId, productId, path) =>
    `/api/v1/cv/students/${studentId}/product/${productId}/downloadcv/${path}`,
};

export const ACADEMIC_URL = {
  getStatus: (studentId) => `/api/v1/students/${studentId}/status`,
  updateDetails: (studentId) => `/api/v1/student/${studentId}/academicDetails`,
  deleteSemDetails: (studentId) =>
    `/api/v1/files/students/${studentId}/semesterCard`,
  deleteAcademicFiles: (studentId) =>
    `/api/v1/files/students/${studentId}/file`,
  getExamBoard: (studentId) => `/api/v1/students/examBoard`,
  getcollege: (studentId) => `/api/v1/colleges/default`,
  getuniversity: (studentId) => `/api/v1/university/default`,
  getdegree: (studentId, type) => `/api/v1/degrees/default/${type}`,
  getdepartment: (studentId) => `/api/v1/departments/default`,
  getacademicdetails: (studentId) =>
    `/api/v1/get/student/educationDetails/${studentId}/type`,
  uploadDocument: (studentId, id, type) =>
    `/api/v1/files/fileUpload/academic/${studentId}/${type}/${id}/${type}`,
  updatesemDetails: (studentId) =>
    `/api/v1/student/${studentId}/semesterDetails`,
  uploadSemFile: (studentId, type, id, sem) =>
    `/api/v1/files/fileUpload/academic/${studentId}/${type}/${id}/${sem}`,
};
export const GRADUATE_ADMISSION_TEST_URL = {
  postGre: (studentId) => `/api/v1/students/${studentId}/gre`,
  postGmat: (studentId) => `api/v1/students/${studentId}/gmat`,
  postToefl: (studentId) => `/api/v1/students/${studentId}/tofel`,
  postIelts: (studentId) => `/api/v1/students/${studentId}/graduate/ielts`,
  getGre: (studentId) => `/api/v1/students/${studentId}/gre`,
  getGmat: (studentId) => `api/v1/students/${studentId}/gmat`,
  getToefl: (studentId) => `/api/v1/students/${studentId}/tofel`,
  getIelts: (studentId) => `/api/v1/students/${studentId}/gat`,
  putGre: (gatInfoId) => `/api/v1/gre/${gatInfoId}`,
  putGmat: (gatInfoId) => `/api/v1/gmat/update/${gatInfoId}`,
  putToefl: (gatInfoId) => `/api/v1/tofel/${gatInfoId}`,
  putIelts: (gatInfoId) => `/api/v1/students/${gatInfoId}/graduate/ielts`,
  getTestUpcoming: (studentId) => `/api/v1/students/${studentId}/score`,
  getGreInfo: (gatInfoId) => `/api/v1/greInfo/${gatInfoId}`,
  getGmatInfo: (gatInfoId) => `/api/v1/gmatInfo/${gatInfoId}`,
  getToeflInfo: (gatInfoId) => `/api/v1/tofelInfo/${gatInfoId}`,
  getIeltsInfo: (gatInfoId) => `/api/v1/ieltsInfo/${gatInfoId}`,
  postFile: (studentId, gatType, certificateId) =>
    `/api/v1/files/fileupload/students/${studentId}/${gatType}/${certificateId}`,
  deleteFile: (studentId) => `/api/v1/files/students/${studentId}/file`,
  deleteGatInfo: (studentId, gatInfoId) =>
    `/api/v1/files/students/${studentId}/gatinfo/${gatInfoId}`,
};

// B_SCHOOL_FILTER_URL
export const B_SCHOOL_FILTER_URL = {
  postBSchoolSearch: (studentId) =>
    `/api/v1/students/${studentId}/bschool/program`,
  postBSchoolCountry: (studentId) =>
    `/api/v1/students/${studentId}/bSchool/country`,
  deleteBShoolCompareByCompareId: (studentId, compareId) =>
    `/api/v1/students/${studentId}/bschool/compare/${compareId}`,
  deleteBShoolAllComparedList: (studentId) =>
    `/api/v1/students/${studentId}/bschool/compare`,
  deleteBShoolPersonalizedByBSchoolRoundId: (studentId, bSchoolRoundId) =>
    `/api/v1/students/${studentId}/bschool/personalized/${bSchoolRoundId}`,
  deleteBShoolAllPersonalizedList: (studentId) =>
    `/api/v1/students/${studentId}/bschool/personalized`,
  putBSchoolCompare: (studentId) =>
    `/api/v1/students/${studentId}/bschool/compare`,
  getBSchoolCompare: (studentId) =>
    `/api/v1/students/${studentId}/bschool/compare`,
  putBSchoolPersonalized: (studentId) =>
    `/api/v1/students/${studentId}/bschool/personalized`,
  getBSchoolPersonalized: (studentId) =>
    `/api/v1/students/${studentId}/bschool/personalized`,
  postBSchoolCollege: (studentId) =>
    `/api/v1/students/${studentId}/bschool/program`,
  getBSchoolRank: (studentId) => `/api/v1/students/${studentId}/bschool/rank`,
  postBSchoolType: (studentId) => `/api/v1/students/${studentId}/bschool/type`,
  getBSchoolRankType: (studentId) =>
    `/api/v1/students/${studentId}/bschool/rank/type`,
  getDownloadBSchoolPersonalizedList: (studentId) =>
    `/api/v1/students/${studentId}/bschool/personalized`,
  putBSchoolCompareList: (studentId) =>
    `/api/v1/students/${studentId}/bschool/compares`,
  postBSchoolTuitionFees: (studentId) =>
    `/api/v1/students/${studentId}/bschool/program/tuitionFees`,
  getBSchoolFilterPageContent: (studentId) =>
    `/api/v1/students/${studentId}/bschool/filter/page`,
};
export const ASPIRATION_URL = {
  getAspirationurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/productAspiration`,
  postAspirationurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/productAspiration`,
  postFieldOfStudy: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/degree/fieldOfStudy`,
  getDegreeurl: (studentId, productId) =>
    `/api/v1/aspiration/students/${studentId}/degree/search`,
  getFieldofStudyurl: (studentId, productId) =>
    `/api/v1/aspiration/branch/search`,
  getIntakeurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/additinalIntakes`,
  getRegionurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/school/regions`,
  getSchoolurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/bSchoolGradSchool/region`,
  getAreaofSpecialisationurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/fieldOfStudy/specialization`,
  getPreferredLocationurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/jobLocation`,
  getPreferredPackageurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/preferredPackage`,
  getPreferredWorkurl: (studentId, productId) =>
    `/api/v1/students/${studentId}/product/${productId}/workProfile`,
};

export const TEST_SURVEY_URL = {
  getTestDetailsAndInstructions: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/tests`,
  start_test: (studentId, testQuestionSetId) =>
    `/api/v1/tests/students/${studentId}/testQuestionSet/${testQuestionSetId}/testExecutions`,
  postAnswer: (studentId) => `/api/v1/tests/students/${studentId}/answers`,
  addTechnicalTestSections: (studentId, testQuestionSetId) =>
    `/api/v1/tests/students/${studentId}/testQuestionSet/${testQuestionSetId}/testSections`,
  viewPreview: (studentId, testExecutionId, groupId) =>
    `/api/v1/tests/students/${studentId}/testexecution/${testExecutionId}/previewgroup/${groupId}/testsectiongroup`,
  postSupplementary: (studentId, testExecutionId, groupId) =>
    `/api/v1/tests/students/${studentId}/answerinfo`,
};

export const STRATEGY_SESSION_URL = {
  getDocumentModelBySubStageId: (studentId, productId, subStageId) =>
    `/api/v1/students/${studentId}/products/${productId}/subStages/${subStageId}`,
  postFileUploadBySubStageId: (studentId, productId, subStageId) =>
    `/api/v1/students/${studentId}/products/${productId}/subStages/${subStageId}/fileUpload`,
  putDocumentBySubStageId: (studentId, productId, subStageId) =>
    `/api/v1/students/${studentId}/products/${productId}/subStages/${subStageId}/fileUploadDetails`,
  getDownloadByDocumentId: (studentId, subStageId, fileName) =>
    `/api/v1/students/${studentId}/subStage/${subStageId}/${fileName}`,
  deleteDocumentByDocumentId: (studentId, documentId) =>
    `/api/v1/students/${studentId}/documents/${documentId}`,
  putStrategySessionComplete: (studentId) =>
    `/api/v1/students/${studentId}/markCompleteStrategySession`,
  getStepsBySubStageId: (studentId, productId, subStageId, stageShortName) =>
    `/api/v1/students/${studentId}/products/${productId}/subStage/${subStageId}/${stageShortName}`,
  getSubStages: (studentId, productId, subStageId) =>
    `/api/v1/students/${studentId}/products/${productId}/${subStageId}/qpmcsubstagestatus
    `,
};

export const STRATEGY_SESSION_TEST_TRANSCRIPTS_URL = {
  putCreateGre: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/gre`,
  putCreateGmat: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/gmat`,
  putCreateToefl: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/toefl`,
  putCreateIelts: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/ielts`,

  getGre: (studentId) => `/api/v1/students/${studentId}/testTranscripts/gre`,
  getGmat: (studentId) => `/api/v1/students/${studentId}/testTranscripts/gmat`,
  getToefl: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/toefl`,
  getIelts: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/ielts`,

  putGre: (studentId) => `/api/v1/students/${studentId}/testTranscripts/gre`,
  putGmat: (studentId) => `/api/v1/students/${studentId}/testTranscripts/gmat`,
  putToefl: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/toefl`,
  putIelts: (studentId) =>
    `/api/v1/students/${studentId}/testTranscripts/ielts`,

  getGreInfo: (gatInfoId) => `/api/v1/greInfo/${gatInfoId}`,
  getGmatInfo: (gatInfoId) => `/api/v1/gmatInfo/${gatInfoId}`,
  getToeflInfo: (gatInfoId) => `/api/v1/tofelInfo/${gatInfoId}`,
  getIeltsInfo: (gatInfoId) => `/api/v1/ieltsInfo/${gatInfoId}`,

  postFile: (studentId, gatType, certificateId) =>
    `/api/v1/files/fileupload/students/${studentId}/${gatType}/${certificateId}`,
  deleteFile: (studentId) => `/api/v1/files/students/${studentId}/file`,
  deleteGatInfo: (studentId, gatInfoId) =>
    `/api/v1/files/students/${studentId}/gatinfo/${gatInfoId}`,
};
// export const QPMC_URL_ = {
//   getVariantSteps: (studentId, productId) =>
//     `/api/v1/get/product/varient/steps/${productId}?studentId=${studentId}`,
//   timeSlots: (studentId, productId, appointmentId, calendarId, date) =>
//     `/api/v1/schedule/listavailabletimes?appointmentTypeID=${appointmentId}&calendarID=${calendarId}&date=${date}&timezone&type=pga`,
//   callDetails: (studentId, productId, stageName) =>
//     // `/api/v1/students/${studentId}/products/${productId}/obcall`,
//     `api/v1/students/${studentId}/products/${productId}/pgaCall?stageName=${stageName}`,
//   callConfirm: (studentId, productId) =>
//     `/api/v1/schedule/appointment/${studentId}/${productId}`,
//   rescheduleCall: (appointmentId, studentId) =>
//     `/api/v1/reschedule/appointment/${appointmentId}/${studentId}`,
export const ADDITIONAL_POINTS_URL = {
  getPoints: (studentId, productId, versionId) =>
    // `/api/v1/students/${studentId}/product/${productId}/pgaReport/additionalPoint?versionNo=2`,
    `/api/v1/students/${studentId}/product/${productId}/pgaReport/additionalPoint?versionNo=${versionId}&isClient=true`,
};
export const POST_ADMIT_SERVICES_URL = {
  getLeftContainerStages: (studentId, productId, stageId) =>
    `/api/v1/students/${studentId}/products/${productId}/${stageId}/substagestatus`,
  getDocuments: (studentId) =>
    `/api/v1/students/${studentId}/postadmitservices/documenttypes?isClient=true`,
  getDocumentsById: (studentId, productId, documentTypeId) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/documentType/${documentTypeId}`,
  downloadDocumentByfileName: (studentId, productId, fileName) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents/${fileName}    `,
  webinarList: () => `/api/v1/webinars`,
};

export const POST_ADMIT_CARD = {
  postSchoolDetailsUrl: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/schools`,
  getAddedSchoolDetailsUrl: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/schools`,
  getAdmitCardNameUrl: () => `/api/v1/postadmitservices/admitcard`,
  uploadFileUrl: (
    studentId,
    productId,
    admitCardId,
    postAdmitSchoolId
  ) => `/api/v1/students/${studentId}/products/${productId}/postadmitservices/${admitCardId}/${postAdmitSchoolId}
  `,
  getTemplateUrl: (studentId, productId) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/costexpense/documents`,
  downloadTemplateUrl: (studentId, productId, fileName) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/documents/${fileName}`,
  downloadUploadedFileUrl: (
    studentId,
    productId,
    admitCardId,
    postAdmitSchoolId
  ) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/${postAdmitSchoolId}/admitcardfiles/${admitCardId}`,

  downloadAdmitCard: (
    studentId,
    productId,
    admitCardId,
    postAdmitSchoolId,
    id
  ) =>
    `/api/v1/students/${studentId}/products/${productId}/postadmitservices/${admitCardId}/${postAdmitSchoolId}/${id}`,
};
