import {
  BASE_URL,
  AUTH_URL,
  AUTHENTICATION_URL,
  STUDENT_URL,
} from "../../axios/Url";
import axios from "axios";
import { STUDENT } from "./ActionType";
import Axios from "../../axios/Instance";
import { errorHandler } from "../../utils/HelperFunction";
import { GETTER } from "../../utils/Constants";
const URL = `${BASE_URL}/api/v1/student`;

export const studentLogin = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STUDENT.loginLoader });
      await Axios.post(AUTHENTICATION_URL.login, data, {
        crossDomain: true,
      }).then((result) => {
        dispatch({
          type: STUDENT.loginStudent,
          payload: { ...result.data, success: true },
          loading: false,
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.loginStudent, error, false));
    }
  };
};

export const getBasicStudentData = (data) => {
  let accessToken = window.sessionStorage.getItem("accessToken");
  return async (dispatch) => {
    try {
      await Axios.post(AUTHENTICATION_URL.studentBasicDetails, data, {
        crossDomain: true,
        headers: {
          "x-access-token": accessToken,
        },
        params: {
          attributes: "BASIC",
        },
      }).then((result) => {
        dispatch({
          type: STUDENT.getBasicStudentData,
          payload: result.data,
          loading: false,
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.getBasicStudentData, error, false));
    }
  };
};

export const checkGoogleLogin = (data) => {
  return (dispatch) => {
    dispatch({ type: STUDENT.isGoogleLogin, payload: data });
  };
};

export const isLoggedFirstTime = (studentId, state) => {
  if (state === null) {
    return async (dispatch) => {
      try {
        await Axios.get(STUDENT_URL.loggedFirstTime(studentId)).then(
          (result) => {
            dispatch({
              type: STUDENT.isLoggedFirstTime,
              payload: result.data,
              loading: false,
            });
          }
        );
      } catch (error) {
        dispatch(errorHandler(STUDENT.isLoggedFirstTime, error, false));
      }
    };
  } else {
    return async (dispatch) => {
      try {
        await Axios.get(STUDENT_URL.loggedFirstTime(studentId), {
          params: {
            state: state,
          },
        }).then((result) => {
          dispatch({
            type: STUDENT.isLoggedFirstTime,
            payload: result.data,
            loading: false,
          });
        });
      } catch (error) {
        dispatch(errorHandler(STUDENT.isLoggedFirstTime, error, false));
      }
    };
  }
};

export const getLoginStatus = (data) => {
  return (dispatch) => {
    dispatch({ type: STUDENT.getLoginStatus, payload: data });
  };
};

export const getTncStatus = async () => {
  let studentId = window.sessionStorage.getItem("studentId");
  try {
    const response = await Axios.get(
      STUDENT_URL.termsAndConditionStatus(studentId)
    );
    return response;
  } catch (error) {}
};

export const forgetPassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STUDENT.loginLoader });
      await Axios.post(AUTHENTICATION_URL.forgetPassword, data, {
        crossDomain: true,
      }).then((result) => {
        dispatch({
          type: STUDENT.forgetPassword,
          payload: { success: true, loading: false, ...result.data },
          loading: false,
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.forgetPassword, error, false));
    }
  };
};

export const clearForgetResponse = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT.clearForgetResponse, payload: null });
  };
};

export const clearLoginDetails = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT.clearLoginDetails });
  };
};

export const resetPassword = (data, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STUDENT.loginLoader });
      await Axios.post(AUTHENTICATION_URL.resetPassword, data, {
        params: {
          r_p_t: token,
        },
      }).then((result) => {
        dispatch({
          type: STUDENT.resetPassword,
          payload: { success: true, ...result.data, loading: false },
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.resetPassword, error, false));
    }
  };
};

export const clearResetResponse = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT.clearResetResponse, payload: null });
  };
};

export const getStudentProductStages = (productId) => {
  return (dispatch) => {
    dispatch({
      type: STUDENT.getStudentProductStages,
      payload: [],
      loading: true,
    });
    Axios.get(STUDENT_URL.getStudentProductStages(productId))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: STUDENT.getStudentProductStages,
            payload: response.data.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        dispatch(errorHandler(STUDENT.getStudentProductStages, err, false));
      });
  };
};

export const getStudentSectionByStageName = (productId, stageName) => {
  return (dispatch) => {
    dispatch({
      type: STUDENT.getStudentSectionByStageName,
      payload: [],
      loading: true,
    });
    Axios.get(STUDENT_URL.getStudentSectionByStageName(productId, stageName))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: STUDENT.getStudentSectionByStageName,
            payload: response.data.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        dispatch(
          errorHandler(STUDENT.getStudentSectionByStageName, err, false)
        );
      });
  };
};

export const updateOnboardingCompleteProduct = (data) => {
  let studentId = GETTER.sessionGetter("studentId");

  return async (dispatch) => {
    try {
      await Axios.put(
        STUDENT_URL.putOnboardingCompleteProduct(studentId),
        data
      ).then((result) => {
        dispatch({
          type: STUDENT.updateOnboardingCompleteProduct,
          payload: result.data,
          loading: false,
        });
      });
    } catch (error) {
      dispatch(
        errorHandler(STUDENT.updateOnboardingCompleteProduct, error, false)
      );
    }
  };
};

export const getOnboardingCompleteProduct = () => {
  let studentId = GETTER.sessionGetter("studentId");

  return async (dispatch) => {
    try {
      await Axios.get(STUDENT_URL.getOnboardingCompleteProduct(studentId)).then(
        (result) => {
          dispatch({
            type: STUDENT.getOnboardingCompleteProduct,
            payload: result.data,
            loading: false,
          });
        }
      );
    } catch (error) {
      dispatch(
        errorHandler(STUDENT.getOnboardingCompleteProduct, error, false)
      );
    }
  };
};

export const getInternshipAccess = () => {
  let studentId = GETTER.sessionGetter("studentId");

  return async (dispatch) => {
    try {
      await Axios.get(STUDENT_URL.getInternShipAccess(studentId)).then(
        (result) => {
          dispatch({
            type: STUDENT.getInternShipAccess,
            payload: { success: true, data: result.data },
            loading: false,
          });
        }
      );
    } catch (error) {
      dispatch(errorHandler(STUDENT.getInternShipAccess, error, false));
    }
  };
};

export const clearData = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT.clearData });
  };
};
export const clearDataInSubStage = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT.clearDataInSubStage });
  };
};
// export const getSubStageStatus = (productId) => {
//   let studentId = GETTER.sessionGetter("studentId");
//   // let productId = GETTER.sessionGetter("productId");

//   return async (dispatch) => {
//     try {
//       await Axios.get(STUDENT_URL.getSubStageStatus(studentId, productId)).then(
//         (result) => {
//           dispatch({
//             type: STUDENT.getSubStageStatus,
//             payload: { success: true, data: result.data },
//             loading: false,
//           });
//         }
//       );
//     } catch (error) {
//       dispatch(errorHandler(STUDENT.getSubStageStatus, error, false));
//     }
//   };
// };
export const getStudent = (productId, stageId) => {
  let studentId = window.sessionStorage.getItem("studentId");
  let accessToken = window.sessionStorage.getItem("accessToken");

  return async (dispatch) => {
    try {
      await Axios.get(
        STUDENT_URL.getSubStageStatus(studentId, productId, stageId),
        {
          crossDomain: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ).then((result) => {
        dispatch({
          type: STUDENT.getSubStageStatus,
          payload: result.data,
          loading: false,
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.getSubStageStatus, error, false));
    }
  };
};
export const saveLoginDate = (studId) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      // dispatch({ type: STUDENT.loginLoader });
      await Axios.post(STUDENT_URL.loginDate(studId), {
        // params: {
        //   r_p_t: token,
        // },
      }).then((result) => {
        dispatch({
          type: STUDENT.loginDate,
          payload: { success: true, ...result.data, loading: false },
        });
      });
    } catch (error) {
      dispatch(errorHandler(STUDENT.loginDate, error, false));
    }
  };
};
