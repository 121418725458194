export const STUDENT = {
  loginLoader: "loginLoader",
  loginStudent: "loginStudent",
  getBasicStudentData: "getBasicStudentData",
  isGoogleLogin: "isGoogleLogin",
  getLoginStatus: "getLoginStatus",
  isLoggedFirstTime: "isLoggedFirstTime",
  forgetPassword: "forgetPassword",
  clearForgetResponse: "clearForgetResponse",
  clearLoginDetails: "clearLoginDetails",
  resetPassword: "resetPassword",
  clearResetResponse: "clearResetResponse",
  getStudentProductStages: "getStudentProductStages",
  getStudentSectionByStageName: "getStudentSectionByStageName",
  updateOnboardingCompleteProduct: "updateOnboardingCompleteProduct",
  getOnboardingCompleteProduct: "getOnboardingCompleteProduct",
  getInternShipAccess: "getInternShipAccess",
  clearData: "clearData",
  getSubStageStatus: "getSubStageStatus",
  clearDataInSubStage: "clearDataInSubStage",
  loginDate: "loginDate"
};
