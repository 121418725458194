export const PERSONAL_INFORMATION = {
  personalInfoLoader: "personalInfoLoader",
  personalInfoClearData: "personalInfoClearData",
  getPersonalDetails: "getPersonalDetails",
  getPersonalImage: "getPersonalImage",
  updatePersonalDetails: "updatePersonalDetails",
  uploadProfileImage: "uploadProfileImage",
  clearPersonalDetails: "clearPersonalDetails",
  clearCustomData: "personalInoClearCustomData",
};
