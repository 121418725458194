import { STRATEGY_SESSION } from "./ActionType";

const initialState = {
  loading: false,
  documentModel: null,
  fileUploadStatus: null,
  documentUpdateStatus: null,
  downloadFileResponse: null,
  downloadFileResponseNew: null,
  fileDeleteStatus: null,
  takenList: null,
  takenInfo: null,
  postResponse: null,
  putResponse: null,
  deleteFileResponse: null,
  uploadFileResponse: null,
  deleteGatInfoResponse: null,
  completeStatus: null,
  stepsData: null,
  subStages: null,
};

const StrategySessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY_SESSION.loader:
      return { ...state, loading: true };
    case STRATEGY_SESSION.clearData:
      return {};
    case STRATEGY_SESSION.clearCustomData:
      return {
        ...state,
        [action.fieldName]: null,
      };
    case STRATEGY_SESSION.getDocumentModelBySubStageId:
      return {
        ...state,
        documentModel: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.postFileUploadBySubStageId:
      return {
        ...state,
        fileUploadStatus: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.putDocumentBySubStageId:
      return {
        ...state,
        documentUpdateStatus: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getDownloadByDocumentId:
      return {
        ...state,
        downloadFileResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getDownloadByDocumentIdNew:
      return {
        ...state,
        downloadFileResponseNew: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.deleteDocumentByDocumentId:
      return {
        ...state,
        fileDeleteStatus: action.payload,
        loading: action.loading,
      };

    case STRATEGY_SESSION.getDownloadByDocumentIdNew:
      return {
        ...state,
        downloadFileResponseNew: action.payload,
        loading: action.loading,
      };

    /** TestTranscripts */
    /**GRE */
    case STRATEGY_SESSION.getGreData:
      return {
        ...state,
        takenList: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getGreInfo:
      return {
        ...state,
        takenInfo: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.saveGreData:
      return {
        ...state,
        postResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.updateGreData:
      return {
        ...state,
        putResponse: action.payload,
        loading: action.loading,
      };

    /**GMAT */
    case STRATEGY_SESSION.getGmatData:
      return {
        ...state,
        takenList: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getGmatInfo:
      return {
        ...state,
        takenInfo: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.saveGmatData:
      return {
        ...state,
        postResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.updateGmatData:
      return {
        ...state,
        putResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getStepsBySubStageId:
      return {
        ...state,
        stepsData: action.payload,
        loading: action.loading,
      };

    /**TOEFL */
    case STRATEGY_SESSION.getToeflData:
      return {
        ...state,
        takenList: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getToeflInfo:
      return {
        ...state,
        takenInfo: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.saveToeflData:
      return {
        ...state,
        postResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.updateToeflData:
      return {
        ...state,
        putResponse: action.payload,
        loading: action.loading,
      };

    /**IELTS */
    case STRATEGY_SESSION.getIeltsData:
      return {
        ...state,
        takenList: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getIeltsInfo:
      return {
        ...state,
        takenInfo: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.saveIeltsData:
      return {
        ...state,
        postResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.updateIeltsData:
      return {
        ...state,
        putResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.deleteFile:
      return {
        ...state,
        deleteFileResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.uploadFile:
      return {
        ...state,
        uploadFileResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.deleteGatInfo:
      return {
        ...state,
        deleteGatInfoResponse: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.strategySessionComplete:
      return {
        ...state,
        completeStatus: action.payload,
        loading: action.loading,
      };
    case STRATEGY_SESSION.getSubStages:
      return {
        ...state,
        subStages: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default StrategySessionReducer;
