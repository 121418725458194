import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import Calendar from "../../../assets/images/Calendar.png";
import CustomButton from "../../../components/CustomButton";
import { Typo } from "../../../styled/Index";
import { useStyles } from "../Styles";
import { MONTHS } from "../Variables";
import * as moment from "moment";

const GoogleCalendar = ({
  confirmationText,
  scheduledDate,
  scheduledTime,
  addToGoogleCalendarButton,
  noThanksButton,
  handleClick,
  handleCancel,
}) => {
  let date = moment(new Date()).format("MMM D,YYYY");

  const classes = useStyles();

  return (
    <Box pt={3} pb={1}>
      <Grid container display="flex" align="center" rowSpacing={0}>
        {/* displays google calendar popup icons(calendar-popup) */}
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <img src={Calendar} alt="" className={classes.calendarStyle} />
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          lg={12}
          xs={12}
          className={classes.confirmationText}
        >
          <Typo>{confirmationText}</Typo>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          lg={12}
          xs={12}
          className={classes.scheduledDateTime}
        >
          <Box>
            <Typo variant={"subtitle1"} fontWeight={"800"}>
              {scheduledDate && scheduledDate ? scheduledDate : date} |{" "}
              {scheduledTime}
            </Typo>
          </Box>
        </Grid>

        {/* Add to google calendar Button and its onClick function */}
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <Box pt={4} mb={1}>
            <CustomButton
              variant={"contained"}
              color={"primary"}
              autoFocus
              onClick={handleClick}
            >
              {addToGoogleCalendarButton}
            </CustomButton>
          </Box>
        </Grid>

        {/* No, Thanks Button and its onClick function */}
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <CustomButton
            variant={"inherit"}
            color={"#343B89"}
            autoFocus
            onClick={handleCancel}
          >
            {noThanksButton}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default GoogleCalendar;
