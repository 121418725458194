export const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];
export const cgpaList = [
  { title: "10", value: 10 },
  { title: "7", value: 7 },
  { title: "4", value: 4 },
  { title: "100", value: 100 },
];

export const TabList = [
  { name: "tenth", label: "10th Grade" },
  { name: "twelfth", label: "12th Grade" },
  { name: "diploma", label: "Diploma" },
  { name: "ug", label: "Undergraduate" },
  { name: "pg", label: "Postgraduate" },
];
export const HtmlData = `<div>
    <h1>Hellewkjdewiufniewfniewrflo</h1>
    <h1>Helllo</h1>
    <h1>Helllo</h1>
    <h1>Helllo</h1>
    <h1>Helllo</h1>
    <h1>Helllo</h1>
    <h1>Helllo</h1>
  </div>`;

export const academicList = [
  { title: "1" },
  { title: "2" },
  { title: "3" },
  { title: "4" },
  { title: "5" },
  { title: "6" },
  { title: "7" },
  { title: "8" },
  { title: "9" },
  { title: "10" },
];

export const diplomaTypeList = [
  { title: "Diploma" },
  { title: "Post Graduate Diploma" },
];

export const WorkTypeArr = [
  {
    name: "FULL_TIME",
    label: "Full_Time",
    checked: false,
  },
  {
    name: "PART_TIME",
    label: "Part_Time",
    checked: false,
  },
  {
    name: "OTHERS",
    label: "Others",
    checked: false,
  },
];

export const CourseDummyResponse = [
  {
    studentId: "c5c74ba9-90de-41a8-98b1-fd3a404d6c74",
    coursename: "HR Analytics",
    endDate: null,
    imageUrl:
      "https://mernlmsassets.s3.ap-south-1.amazonaws.com/Thumbnails/Construction%20Technology%20-Thumbnail.png",
    progress: 0,
    courseId: "359388dd-4b31-4744-9d11-380bb248f5da",
    startDate: "2021-03-24T21:08:00.891+0000",
  },
  {
    studentId: "c5c74ba9-90de-41a8-98b1-fd3a404d6c74",
    coursename: "Data Science",
    endDate: null,
    imageUrl:
      "https://mernlmsassets.s3.ap-south-1.amazonaws.com/Thumbnails/mechine%20learning%20-Thumbnail.png",
    progress: 100,
    courseId: "57c9f673-c8f2-4758-9e03-cbe7cb95390c",
    startDate: "2021-03-23T19:07:21.327+0000",
  },
];
export const aspdata = {
  success: true,
  message: "Student Aspiration",
  data: {
    id: null,
    noOfSchools: null,
    workProfile: null,
    preferredPackage: null,
    jobLocation: null,
    additionalIntakes: null,
    degree: null,
    fieldOfStudy: null,
    areaOfSpecialization: null,
    preferredRegion: null,
    school: null,
    questionList: [
      {
        id: "e01e0919-91d6-4acd-83de-93814f5997f2",
        name: "pursueHigherEducation",
        question: "Are you planning to pursue Higher Education",
        description: "",
        type: "SINGLE_SELECT",
        difficultyLevel: null,
        timeRemaining: null,
        orderNo: null,
        status: null,
        questionStartingTime: null,
        sectionName: null,
        choices: [
          {
            id: "657d3ae3-aca0-427e-96fa-023a707c92ce",
            type: null,
            text: "Yes",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: true,
          },
          {
            id: "bbad674d-67d8-4a0a-860c-cd51502e416c",
            type: null,
            text: "No",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
        ],
        bullets: null,
        bulletsList: null,
        expectedTime: null,
        createdAt: null,
        updatedAt: null,
        isHaveImage: true,
        imgURL: "",
        correctChoice: [
          {
            id: "657d3ae3-aca0-427e-96fa-023a707c92ce",
            type: null,
            text: "Yes",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: true,
          },
        ],
      },
      {
        id: "fe2b7d1d-0150-4011-b595-df4d5e408e5a",
        name: "pursueHigherEducationField",
        question:
          "Which of the below fields would you choose to pursue your higher education in?",
        description: "",
        type: "SINGLE_SELECT",
        difficultyLevel: null,
        timeRemaining: null,
        orderNo: null,
        status: null,
        questionStartingTime: null,
        sectionName: null,
        choices: [
          {
            id: "5acb8070-c99a-46a6-8766-f6aacdbe1885",
            type: null,
            text: "Technical",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
          {
            id: "c61df563-2982-4738-951d-d3f0a0222451",
            type: null,
            text: "Management",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: true,
          },
          {
            id: "ecbc8882-2065-471b-a437-a69b6a8a57da",
            type: null,
            text: "Not Yet Decided",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
        ],
        bullets: null,
        bulletsList: null,
        expectedTime: null,
        createdAt: null,
        updatedAt: null,
        isHaveImage: true,
        imgURL: "",
        correctChoice: [
          {
            id: "5acb8070-c99a-46a6-8766-f6aacdbe1885",
            type: null,
            text: "Technical",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
        ],
      },
      {
        id: "18222377-bf4b-44b7-8420-56039e167f7d",
        name: "testIntendToTake",
        question: "Which of the following tests do you intend to take?",
        description: "",
        type: "MULTI_CHOICE",
        difficultyLevel: null,
        timeRemaining: null,
        orderNo: null,
        status: null,
        questionStartingTime: null,
        sectionName: null,
        choices: [
          {
            id: "610efbff-363f-4c92-9759-43a3aaad82b7",
            type: null,
            text: "GRE",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
          {
            id: "c23ca4ec-20f7-4623-aba9-e94c4a3df31a",
            type: null,
            text: "GMAT",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: true,
          },
          {
            id: "e411d00e-7f79-4e49-9c54-a94ce95f1c9d",
            type: null,
            text: "TOFEL",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: true,
          },
          {
            id: "dd3e6c45-86a1-4abd-bb19-b1ec70c61493",
            type: null,
            text: "IELTS",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
        ],
        bullets: null,
        bulletsList: null,
        expectedTime: null,
        createdAt: null,
        updatedAt: null,
        isHaveImage: true,
        imgURL: "",
        correctChoice: [
          {
            id: "610efbff-363f-4c92-9759-43a3aaad82b7",
            type: null,
            text: "GRE",
            choiceImage: null,
            mappedCourses: [],
            bundleNo: null,
            orderNo: null,
            createdAt: null,
            updatedAt: null,
            imageUrl: null,
            imageType: null,
            correctChoice: false,
          },
        ],
      },
    ],
    testExecutionId: "f6ea0754-bc97-45d7-a6dd-627ea8f252c6",
    answerInfos: null,
  },
};
export const NoofSchoolList = [{ title: "1" }, { title: "3" }, { title: "5" }];
export const AspirationProductWiseChange = [
  { title: "ACS-MS", value: "ACS_MS" },
  { title: "ACS-MIM", value: "ACS_MIM" },
  { title: "ACS-MBA", value: "ACS_MBA" },
  { title: "PBM", value: "PBM" },
  { title: "PBP", value: "PBP" },
];
export const UseCVBuilder = [
  "Click on “Use CV Builder”.",
  "Fill out Basic Details and other mandatory fields.",
  "Generate your own CV in 7 easy steps.",
];
export const downloadCVContent = [
  "Click on “Download Template”",
  "Open the Template with Microsoft Word",
  "Use the Comments as a reference point to draft your CV.",
  "Upload the using “Browse” tab.",
];
export const downloadRnAContent = [
  "Download the RnA Template",
  "Open the template with Microsoft Word",
  "Use the Comments as reference to draft your RnA template",
  "Download the CV template",
  "Follow the same steps",
  "Upload both documents in the section below",
];
export const callSchedule = [
  {
    title: "It’s official!",
    description:
      "We’re thrilled to welcome you to CareerLabs. You’re joining a community of other like-minded students, and we’re excited to help you with the best career needs.",
  },
  {
    description:
      "What’s next? Well, in the next 5-7 working days you’ll be hearing from the team, who will help you brief about the process in an Onboarding session. After that, you will start working on the documents to get the mentoring you need.",
  },
  {
    description:
      "We are here to help make sure you get the results you expect from us, so prepare a list of questions that you have doubts on and we’d love to hear from you during the Onboarding Session.",
  },
  {
    description:
      "To get you settled, we wanted to share with you some of our best resources so you can make the most out of your experience with us. Stay tuned to our weekly newsletter, The LabReports for some great tips and knowledge to be had.",
  },
];
export const prePga = [
  {
    description:
      "Your application procedure must begin with your mentor reviewing your profile. How will the Mentor review it? Only if you provide us your information during the call is it possible for this to occur.    ",
  },
  {
    description:
      "During the session, your mentor will help you assess your profile by gathering your personal information and background data. These will mainly include your desired program to apply, target intake, career goals, domain. Also, about professional background to assist you identify a relevant career path. ",
  },
  {
    description:
      "The mentor will give a brief the LMS Portal, to complete the application process, to improve your profile. Additionally, you'll receive an update on the next classes.    ",
  },
  {
    description:
      "You might be thinking how will you remember everything that’s discussed in the call? Besides discussing it over call, you will receive a summary of the session, so that you can go back and look back at it",
  },
];
export const pga = [
  {
    description:
      "You must have come across several profile building activities all over the internet. But you don't know which is the best for your profile. ",
  },
  {
    description:
      "So a Profile Gap Analysis Call will take place. Here, your mentor along with Krithika Srinivasan helps identify the gaps in your career and provide a workable plan of action.",
  },
  {
    description:
      "But, by this time, you should have started with your application documents with the Editor. It is because the mentor will suggest based on your choice of interest and the documents shared.",
  },
  {
    description:
      "A list of programs will be shared along with the other guidance and the next steps to take up. Besides discussing it over call, you will receive a summary of the session, so that you can go back and look back at it.     ",
  },
];
export const profileMentoring = [
  {
    description:
      "The Profile Gaps Analysis Call is complete. You have made quite a progress in your Application Process. But to check with the speed of your progress is the need of the hour.",
  },
  {
    description:
      "Next step would hence be a Profile Mentoring Call, also known as the Pre Strategy Session Call. Your mentor during the call will discuss the changes in the CV or Resume and its urgency to complete.",
  },
  {
    description:
      "When planning to join a Grad School, it is important to decide on your preferred programs and region. The school research worksheet helps to narrow down your preferences.",
  },
  {
    description:
      "Based on the discussion, a list of schools will be shared that best fits you. There's no hard and fast rule to stick to the given list. It is only compiled based on your current profile.",
  },
];
export const schoolResearch = [
  {
    description:
      "There are thousands of schools across the world. With that, the process of shortlisting might be daunting.  Few must have given up too. But this is an attempt for you to put some logic into what appears to be vague and give you more hand holding. ",
  },
  {
    description:
      "You might have been confused about how to categorize while selecting your Grad School.  During the application process, there are certain criterias to consider.  Categorizing is necessary as a variety of circumstances might impact your decision to study abroad.",
  },
  {
    description:
      "Considering several factors will help you narrow down a few selective schools and your desired program and specialization. You should consider the distinct benefits that each program provides. This covers things like campus location, cost, career goals,  experiential learning techniques etc.",
  },
  {
    description:
      "Filing the School Research Worksheet, will help you ascertain your preferences.",
  },
];
export const essayQuestionnaire = [
  {
    description:
      "Narrowing down your school must have been a difficult decision. So we have decided to make your life easier. You took one step and we will help you by taking two extra steps. Thus we have decided to help with the Essay and LoR drafting process.",
  },
  {
    description:
      "After shortlisting schools, you must have noticed that each school has asked for a Statement of Purpose. Therefore, we shared an Essay Questionnaire.",
  },
  {
    description:
      "The Statement of Purpose for all schools is more or less the same. We have thus picked a few of the common important questions for you to answer. To avoid a last minute rush and help you draft your Essay, before the deadline, answering the questions is a must.",
  },
  {
    description:
      "Your Editor based on these answers will share a generic suggestion for the gaps that are to be improved in the Essay and make it a strong one, during the Strategy Session.",
  },
];
export const lorQuestionnaire = [
  {
    description:
      "As promised, we are here to  help you with drafting the Letter of Recommendation. Now you know a LoR is supposed to be filled by the Reccomender. Your achievements for the responsibilities taken are only known by you and your Recoomender.",
  },
  {
    description:
      "So before starting with the drafting of LoR, filing the LoR questionnaire is crucial. Without filing, your Editor will not be able to assist you.     ",
  },
  {
    description:
      "The LoR Questionnaire is thus a worksheet where you have to provide relevant information about your skills developed through the responsibilities taken up under the guidance of your recommender. Prove your claims by leveraging relevant examples.",
  },
  {
    description:
      "Doing this gives the Editor an idea about your skills and in turn an opportunity to assist you further.",
  },
];
export const lorFrameWork = [
  {
    description:
      "You can find yourself gathering and preparing all the necessary documentation as the MS admissions are quickly approaching. The Letter of Recommendation, or LOR, is what you are normally expected to include with that group of documents.",
  },
  {
    description:
      "The Letter of Recommendation is equally crucial to your application as your Statement of Purpose (SOP), and it has the power to make or break your prospects of receiving a scholarship or admission to a certain institution.    ",
  },
  {
    description:
      "While letters of recommendation are crucial to getting accepted into a prestigious university's MS programme, there is virtually no culture of submitting them in India.    ",
  },
  {
    description:
      "Students are left perplexed by this, as are the instructors and employers who are responsible for producing letters of recommendation. While each student's LOR would be distinct and personalized, effective LORs have certain characteristics in common that set them out from the rest.     ",
  },
  {
    description:
      "Here is a step by step guide to draft your Letter of Recommendation that emphasizes and strengthens your academic qualifications and offers a glimpse of your character to help you stand out from the numerous applicants and facilitate entry into the school of your dreams.",
  },
];
export const essayFrameWork = [
  {
    description:
      "A statement of purpose for academic study is required of each applicant. One of your main chances to explain your academic goals to the admissions committee and show them why you are a good fit for the program you are applying to is through the SOP.   ",
  },
  {
    description:
      "This essay aims to convince the admissions committee that you have a strong background and relevant experience in your field of interest and that you have the capability it takes to succeed in graduate school.   ",
  },
  {
    description:
      "Because it provides the admission committee with information on your academic history and interests, the SOP is among the most crucial components of your graduate school application.",
  },
  {
    description:
      "It gives you the chance to describe in your own words what makes you stand out from the competition, making it easier for them to decide whether you are a suitable fit for the program you are applying to and demonstrate your communication skills and flair.",
  },
  {
    description:
      "A detailed Essay framework to draft your Statement of Purpose is here, designed based on the Essay questionnaire filled by you.     ",
  },
];
export const additionalEssay = [
  {
    description:
      "Let's gain some insight on the logic behind schools having this option before we discuss how to handle the situation. Most schools include a requirement for the Additional essay that gives a hint for its purpose. It is generally to explain any mitigating circumstances that may exist in your profile. This is your chance to fill in gaps from the past and rationalize mistake(s) from the past.    ",
  },
  {
    description:
      "Given that the Additional Essay is frequently used to explain in more detail a flaw in a candidate's profile, you must own responsibility for the circumstance you describe in your essay and exhibit self-awareness. Declare out loud the aspect of your candidacy that needs more justification.    ",
  },
  {
    description:
      "Even while you might be concerned that doing so will call attention to it, we can guarantee you that the admissions committee will still be aware of any difficulties of this nature. Therefore, actively managing the story and resolving the problem by utilizing the Additional Essay in a positive way will prevent the admissions committee from making assumptions about the circumstance.",
  },
];
export const preStrategy = [
  {
    description:
      "Grad school applications can be time-consuming and stressful. If you don't prepare in advance, you risk missing important deadlines, failing to notify recommenders in a timely manner, or submitting a subpar application. We'll provide you with the information you need, whether you are just starting to consider graduate school, preparing for the GRE, or getting ready to start working on your applications. We'll outline the steps involved in the application procedure for you and respond to some frequently asked queries.",
  },
  {
    description:
      "But for that to happen, we will check all the documents, the worksheets that you have shared with us, such as the grad school research worksheet, essay questionnaire, LOR questionnaire. Also you should have your GRE/IELTS/TOEFL scores ready before coming to the pre strategy session.",
  },
];
export const gat = [
  {
    description:
      "Numerous factors are involved to select schools and selecting a few out of them the thousands of grad schools that you have dreamt of is stressful and time-consuming too.",
  },
  {
    description:
      "Hence, we had shared a school research worksheet that incorporates all the factors that need to be taken into consideration while making the Grad School List. We will hence share a list of schools that best fits you, which includes your preferences as well.",
  },
  {
    description:
      "Now, considering them, scrutinizing all the above factors, you have to share the shortlisted school list, in order to start with your school specific application process.",
  },
];
export const additionalPoints = [
  {
    description:
      "Your CGPA is a measure of your academic potential.Your current CGPA is 9.65,maintain this until your application process.",
  },
  {
    description:
      "You are currently considering to pursue a Masters in Computer Science in the United States.",
  },
  {
    description:
      "Since you are planning to pursue a Master's degree,it is recommended for you to take GRE by June/July 2021.Aim for a minimum score of 330(Q-170,V-160).",
  },
  {
    description:
      "Aim on getting a lot more research experience-2 research projects in Machine Learning or Python under the guidance of an academic/industry mentor and atleast 3-4 reasonably complex projects that ate for solving real-world problems.You can also consider some miniprojects to hieghlight on your individual contribution and skill set acquired",
  },
  {
    description:
      "Consider working on a core project that can be converted into a research paper for publication or a case study.Try to get the paper published in good jounals such as IEEE,Springer,etc",
  },
  {
    description:
      "Aim on increasing the practical aspect,as this is an incredible point to boost your profile building and can be included into your CV suitably.This can be achieved by working on more complex projects in the domain of your interest.",
  },
  {
    description:
      "You are already pursuing an internship.Utilize this opportunity to the fullest and perform well.This can be your chance to get a potential recommendation letter from an industry as well ",
  },
  {
    description:
      "Consider taking part in technical competitions from forums such as Kaggie as this will open up various opporuntities.This will help you understand where you peers are at,what sort of  projects you can do and even give you ideas for some.Additionally,winning at these events will give you the confidence and experience to immerse yourself even more in you domain. ",
  },
  {
    description:
      "Take part in extra-curricular activitites to build your social skills and demonstrate leadership qualities.This will help you to demonstrate your leadership skills and in your profile building",
  },
  {
    description:
      "You can log in to your dashboard using your registeed Google ID and access our couurses and Focus of the Month webinars which will help you to get much more insights about programs,intakes and how to start your grad school research.Some of the important webinar links are added for you below Focus of the Month:Researching on Graduate Schools,Focus of the Month:Intakes,Timelines and Tests.",
  },
  {
    description:
      "Your CV reflects your skills and chievements>Do work on updating your Cv with relevant details pertaining to your projects,Internships,grades and participations.A good profile will also open opportunities for scholorship and research fellowship and research fellowship/assistantship.",
  },
];
export const ScheduleDetails = [
  {
    description:
      "Quarterly Profile Mentoring call is a guidance call session by a team of  mentors (once every quarter or based on your progress) to check on your progress and resolve any queries or concerns. After completion of QPMC call you have to work on the shared worksheets and complete the activities suggested for the next Quarter.",
  },
  {
    title: "Agenda of the call:",
    description:
      "1.Discussion on client's CV as per the changes in your current profile",
  },
  {
    description:
      "2.Discussion on profile building updates for current quarter.",
  },
  {
    description:
      "3.Next steps towards updating Region, Industry (Goal Setting) and School research worksheets. (as applicable)",
  },
];
export const strategy = [
  {
    title:
      "Applications to universities are daunting. But no matter what applications need to be completed well in advance with finalized application documents and without risk missing important deadlines. This could be done through a time set aside for planning, which includes a list of assigned activities with timeframes and resources.",
    description:
      "Hence, a Strategy Session Call is scheduled to help you analyze the best fit of schools, find the right recommender, share suggestions to strengthen your Essay to get started with the next steps of the Application Process which are tailor-made for you based on your input.",
  },
];
export const Template = [
  {
    title:
      "The university application process must be fun-filled but it should be strategic too. Students during the application process become tired due to the time-consuming process, which lessens the chances of getting into a good university. To achieve your goals, it is necessary to have a strategic and a structured plan to start thinking and acting in an assembled manner.",
    description:
      "A Pre Strategy Session Call lets you act upon the plan of the application process to take your goals to the next level and guides you towards the part of your profile that needs to be highlighted. ",
  },
];
export const Webinar = [
  {
    title: "Webinar",
    description:
      "Recorded webinars for Finance with Credila,Incred,and Prodigy Finance.",
  },
  {
    description: "Beyond Admits:Next Steps-Webinar link",
  },
  {
    description: "Beyond Admits:COVID-19 Pandemic-Webinar link",
  },
  {
    description: "Beyond Admits:Visa Interview Guidelines-Webinar link",
  },
  {
    description: " Self Visa Preparation-https://",
  },
];
