import React from "react";
import CustomButton from "./CustomButton";
import { useStyles } from "./Styles";

function CustomAdd({ children, onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.addDiv}>
      <CustomButton variant="outlined" onClick={onClick}>
        {children}
      </CustomButton>
    </div>
  );
}

export default CustomAdd;
