import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import React from "react";
import CallScheduleCalendar from "../../../components/Calendar";
import { useStyles } from "../Styles";
import DisplayTimeSlots from "./DisplayTimeSlots";

const CallSchedule = ({
  handleClick,
  clickedDate,
  listedTimeSlots,
  pad,
  duration,
  handleTimeSlotClick,
  selectedTimeSlot,
}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item md={5} display="flex" justify="space-evenly">
        {/* calendar component in the call schedule popup */}
        <CallScheduleCalendar
          dateSelected={clickedDate}
          selectedDateClick={handleClick}
        />

        {/* <Divider
          className={classes.callSchedulePopupDivider}
          orientation="vertical"
          flexItem
        /> */}
      </Grid>

      {/* it displays available time slots in the call schedule popup */}
      <Grid item md={7}>
        <DisplayTimeSlots
          selectedDate={clickedDate}
          padAdd={pad}
          timeSlots={listedTimeSlots}
          duration={duration}
          timeSlotClick={handleTimeSlotClick}
          selectedSlot={selectedTimeSlot}
        />
      </Grid>
    </Grid>
  );
};
export default CallSchedule;
