import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as moment from "moment";
import React from "react";
import { Typo } from "../styled/Index";
import CallDetailsCard from "./CallDetailsCard";
import { useStyles } from "./Styles";

const CallDetails = ({
  remainingTime,
  date,
  time,
  callStatus,
  handleClick,
  purposeOfCall,
  callType,
  mentorName,
  ppgaNotes,
  description,
  isPgaCallCompleted,
  isObCallCompleted,
  isSkipObCall,
  isSkipPPGA,
  callName,
  disabled,
  details,
  momPoints,
}) => {
  const callCompletedDate = moment(new Date(date)).format("MMM D,YYYY");
  const classes = useStyles();
  const splitMomPoints = momPoints?.split("\n");
  return (
    <Grid
      container
      columnSpacing={2}
      spacing={3}
      className={classes.callDetailsContainer}
    >
      <Grid item md={5} xs={4} sm={4}>
        <CallDetailsCard
          remainingTime={remainingTime}
          date={date}
          time={time}
          callStatus={callStatus}
          handleClick={handleClick}
          callType={callType}
          mentorName={mentorName}
          ppgaNotes={ppgaNotes}
          disabled={disabled}
          momPoints={momPoints}
          isPgaCallCompleted={isPgaCallCompleted}
          isObCallCompleted={isObCallCompleted}
          isSkipObCall={isSkipObCall}
          isSkipPPGA={isSkipPPGA}
        />
      </Grid>
      <Grid item md={7} xs={8} sm={8} display="flex" alignItems="center">
        <Grid item container spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <Typo fontWeight={"600"}>{purposeOfCall}</Typo>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Typo color={"GrayText"}>
              {description?.map((item) => {
                return (
                  <Grid container>
                    <Grid item lg={12} xs={12}>
                      <Typo textAlign="justify">{item.title}</Typo>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typo textAlign="justify">{item.description}</Typo>
                    </Grid>
                  </Grid>
                );
              })}
            </Typo>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Typo color={"GrayText"}>
              {details?.map((item) => {
                return (
                  <Grid container>
                    <Grid item>
                      <Typo textAlign="justify">{item.title}</Typo>
                    </Grid>
                    <Grid item>
                      <Typo textAlign="justify">{item.description}</Typo>
                    </Grid>
                  </Grid>
                );
              })}
            </Typo>
          </Grid>
        </Grid>
      </Grid>
      {ppgaNotes && (
        <Grid item md={12}>
          <Divider />
        </Grid>
      )}

      {/* PPGA Call Notes */}
      {ppgaNotes && (
        <Grid item container spacing={2}>
          <Grid item md={12}>
            <Typo fontWeight={"600"}>PPGA Notes</Typo>
          </Grid>
          <Grid item md={12}>
            <Typo color={"GrayText"}>
              {callCompletedDate} at {time}
            </Typo>
          </Grid>

          <Grid
            item
            md={12}
            spacing={2}
            style={{
              maxHeight: "27vh",
              overflowY: "scroll",

              // backgroundColor: "pink",
            }}
          >
            <Grid item container spacing={2}>
              {ppgaNotes.map((item) => {
                return (
                  <Grid item md={12}>
                    <Typo>{item.ppgaCallNotesTitle.text}</Typo>
                    <Typo>{item.mentorNotes}</Typo>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
      {momPoints && (
        <Grid item md={12}>
          <Divider />
        </Grid>
      )}

      {/* PPGA Call Notes */}
      {momPoints && (
        <Grid item container spacing={2}>
          <Grid item md={12}>
            <Typo fontWeight={"600"}>{callName} Notes</Typo>
          </Grid>
          <Grid item md={12}>
            <Typo color={"GrayText"}>
              {callCompletedDate} at {time}
            </Typo>
          </Grid>

          <Grid
            item
            md={12}
            spacing={2}
            style={{
              maxHeight: "27vh",
              overflowY: "scroll",

              // backgroundColor: "pink",
            }}
          >
            <Grid item container spacing={2}>
              <Grid item md={12}>
                {/* <Typo>{momPoints}</Typo> */}
                {/* <Typo>{item.mentorNotes}</Typo> */}
                {splitMomPoints.map((line) => (
                  <Typo>{line}</Typo>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CallDetails;
