import clevertap from 'clevertap-web-sdk';
clevertap.init(process.env.REACT_APP_CLEVER_TAB_ACCOUNT_ID);
clevertap.setLogLevel(3);
let checkInit = process.env.REACT_APP_CLEVER_TAB_ACCOUNT_ID === '' || process.env.REACT_APP_CLEVER_TAB_ACCOUNT_ID === undefined || process.env.REACT_APP_CLEVER_TAB_ACCOUNT_ID === null ? false : true;
clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({useIP: true}); //set the flag to true, if the user agrees to share their IP data
let actions = {

    IdentifyUser: (payload) => {
        // Reference: https://developer.clevertap.com/docs/web-quickstart-guide#identify-users
        if (checkInit) clevertap.onUserLogin.push(payload);
    },

    PushEvent: (eventName, eventData) => {
        // Reference: https://developer.clevertap.com/docs/web-quickstart-guide#track-user-events
        if (checkInit) clevertap.event.push(eventName, eventData);
    },

    UpdateProfile: (payload) => {
        // Reference: https://developer.clevertap.com/docs/concepts-user-profiles#manually-updating-single-value-user-profile-properties
        if (checkInit) clevertap.profile.push(payload);
    }

}
export default actions;

