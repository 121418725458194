import { ReactComponent as Calendar } from "../assets/icons/calendar.svg";
import { ReactComponent as Course } from "../assets/icons/course.svg";
import { ReactComponent as Home } from "../assets/icons/home.svg";
import { ReactComponent as Internship } from "../assets/icons/internship.svg";
import { ReactComponent as Logout } from "../assets/icons/logout.svg";
import { ReactComponent as Notification } from "../assets/icons/notification.svg";
import { ReactComponent as Support } from "../assets/icons/support1.svg";
import { ReactComponent as Product } from "../assets/icons/product.svg";
import { ReactComponent as Tools } from "../assets/icons/tools.svg";
import { ReactComponent as Tnc } from "../assets/icons/tnc.svg";
import { ReactComponent as Lock } from "../assets/icons/lock.svg";
import { ReactComponent as SmallLock } from "../assets/icons/stepLock.svg";
import { ReactComponent as EnableTick } from "../assets/icons/enableTick.svg";
import { ReactComponent as DisableTick } from "../assets/icons/disabledTick.svg";
import { ReactComponent as SelectedMenuArrow } from "../assets/icons/selectedMenuArrow.svg";
import { ReactComponent as Info } from "../assets/icons/info.svg";
import { ReactComponent as NavOpen } from "../assets/icons/navOpen.svg";
import { ReactComponent as NavClose } from "../assets/icons/navClose.svg";
import { ReactComponent as CallCompleted } from "../assets/icons/callCompleted.svg";
import { ReactComponent as OnboardingInprogress } from "../assets/icons/onboardingInprogress.svg";
import { ReactComponent as CallInprogress } from "../assets/icons/callInprogress.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/DeleteIcon.svg";
import { ReactComponent as NoCallScheduled } from "../assets/images/noCallScheduled.svg";
import { ReactComponent as TncCompleted } from "../assets/icons/tncCompleted.svg";
import { ReactComponent as ReportInProgress } from "../assets/icons/pgaReportInprogress.svg";
import { ReactComponent as ReportCompleted } from "../assets/icons/pgaReportCompleted.svg";
import { ReactComponent as PlanOfActionInProgress } from "../assets/icons/planOfActionInprogress.svg";
import { ReactComponent as PlanOfActionCompleted } from "../assets/icons/planOfActionCompleted.svg";
import { ReactComponent as CvReviewInProgress } from "../assets/icons/cvReviewInprogress.svg";
import { ReactComponent as CvReviewCompleted } from "../assets/icons/cvReviewCompleted.svg";
import { ReactComponent as ObComplete } from "../assets/icons/obComplete.svg";
import { ReactComponent as Document } from "../assets/icons/document.svg";
import { ReactComponent as Manual } from "../assets/icons/Manual.svg";
import { ReactComponent as CvReviewIcon } from "../assets/icons/CvReviewIcon.svg";

export const COLORS = {
  primary: "#343B89",
  secondary: "#81C14F",
  selected: "#FFD500",
  white: "#ffffff",
  purple: "#343B89",
  lightAsh: "#C8C8C8",
  darkNavy: "#172B4D",
  black: "#000000",
  darkAsh: "#999999",
  selectedSecondary: "#F5DE41",
  naveBlue: "#1093FF",
  skyBlue: "#00AEEF",
  darkCharcoal: "#333333",
  lightBlack: "#666666",
  green: "#81c14f",
  red: "#f2463b",
  yellow: "#efac2",
  lightBlack: "#666666",
  mildAsh: "#e7e7e7",
  lightBlue: "#18AAE7",
  darkBlue: "#3c56fd",
  red: "#FD413C",
  lightRed: "#FDECEB",
  primaryBlue: "#488DFF",
  darkGray: "#8B8B8B",
  lightGray: "#D2D2D2",
};

export const GRADIENT = {
  onboarding: {
    fullView:
      "transparent linear-gradient(109deg, #B21DD6 0%, #EFAB0C 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(133deg, #B21DD6 0%, #EFAB0C 100%) 0% 0% no-repeat padding-box",
  },
  pga: {
    fullView:
      "transparent linear-gradient(251deg, #6D01FB 0%, #AE40DF 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(227deg, #6D01FB 0%, #AE40DF 100%) 0% 0% no-repeat padding-box",
  },
  profileMentoring: {
    fullView:
      "transparent linear-gradient(251deg, #6662FB 0%, #3AA4EA 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(227deg, #6662FB 0%, #3AA4EA 100%) 0% 0% no-repeat padding-box",
  },
  strategySession: {
    fullView:
      "transparent linear-gradient(227deg, #FFAF93 0%, #FF9190 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(227deg, #FFAF93 0%, #FF9190 100%) 0% 0% no-repeat padding-box",
  },
  applicationStage: {
    fullView:
      "transparent linear-gradient(227deg, #655DAE 0%, #5FC5A4 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(227deg, #655DAE 0%, #5FC5A4 100%) 0% 0% no-repeat padding-box",
  },
  postAdmitService: {
    fullView:
      "transparent linear-gradient(227deg, #7CCE00 0%, #00B8E8 100%) 0% 0% no-repeat padding-box",
    halfView:
      "transparent linear-gradient(227deg, #7CCE00 0%, #00B8E8 100%) 0% 0% no-repeat padding-box",
  },
};

export const ICONS = {
  calendar: <Calendar />,
  manual: <Manual />,
  course: (color) => <Course fill={color} />,
  CvReviewIcon: (color) => <CvReviewIcon fill={color} />,
  home: <Home />,
  internship: (color) => <Internship fill={color} />,
  logout: <Logout />,
  notification: <Notification />,
  support: <Support />,
  product: (color) => <Product fill={color} />,
  tools: (color) => <Tools fill={color} />,
  tnc: <Tnc />,
  lock: <Lock />,
  smallLock: <SmallLock />,
  enableTick: <EnableTick />,
  disableTick: <DisableTick />,
  selectedMenuArrow: <SelectedMenuArrow />,
  info: <Info />,
  navOpen: <NavOpen />,
  navClose: <NavClose />,
  callCompleted: <CallCompleted />,
  onboardingInprogress: <OnboardingInprogress />,
  callInprogress: <CallInprogress />,
  noCallScheduled: <NoCallScheduled />,
  tncCompleted: <TncCompleted />,
  deleteIcon: <DeleteIcon />,
  pgacallInProgress: <CallInprogress />,
  pgacallCompleted: <CallCompleted />,
  prepgacallInProgress: <CallInprogress />,
  prepgacallCompleted: <CallCompleted />,
  pgaReportInProgress: <ReportInProgress />,
  pgaReportCompleted: <ReportCompleted />,
  planOfActionInProgress: <PlanOfActionInProgress />,
  planOfActionCompleted: <PlanOfActionCompleted />,
  cvReviewInProgress: <CvReviewInProgress />,
  cvReviewCompleted: <CvReviewCompleted />,
  onboardingCompleted: <ObComplete />,
  learningInprogress: <OnboardingInprogress />,
  learningCompleted: <ObComplete />,
  uploadDocumentInProgress: <Document />,
  uploadDocumentInprogress: <Document />,
  uploadDocumentComplete: <TncCompleted />,
  CompleteCvComplete: <TncCompleted />,
  completeCvInProgress: <Document />,
  qpmc1InProgress: <ReportInProgress />,
  qpmc1Completed: <ReportCompleted />,
  qpmc2InProgress: <ReportInProgress />,
  qpmc2Completed: <ReportCompleted />,
  WorksheetsInProgress: <ReportInProgress />,
  WorksheetsCompleted: <ReportCompleted />,
  preStrategycallInProgress: <CallInprogress />,
  preStrategycallCompleted: <CallCompleted />,
  preStrategyInProcessInProgress: <ReportInProgress />,
  preStrategyCompleted: <ReportCompleted />,
  strategySessioncallInProgress: <CallInprogress />,
  strategySessioncallCompleted: <CallCompleted />,
  strategySessionInProgress: <ReportInProgress />,
  strategySessionCompleted: <ReportCompleted />,
  uploadInProgress: <ReportInProgress />,
  uploadCompleted: <ReportCompleted />,
  feedbackSessionInProgress: <CallInprogress />,
  feedbackSessionCompleted: <CallCompleted />,
  resourcesInProgress: <ReportInProgress />,
  resourcesCompleted: <ReportCompleted />,
  visaInterviewCallInProgress: <CallInprogress />,
  visaInterviewCallCompleted: <CallCompleted />,
};

const response = [
  {
    name: "product 1",
    id: "4903940394",
    stage: [
      {
        name: "Onboarding",
        stage: [
          {
            name: "Terms And Con",
            routeName: "terms-and-con",
            stage: [
              {
                name: "product 1",
                id: "493849",
              },
              {
                name: "product 2",
                id: "94034903",
              },
            ],
          },
          {
            name: "Call Schedule",
            routeName: "terms-and-con",
          },
          {
            name: "Onboarding Details",
            routeName: "terms-and-con",
            stage: [
              {
                name: "Personal Info",
                routeName: "personal-info",
              },
            ],
          },
        ],
      },
      {
        name: "Pga",
      },
    ],
  },
  {
    name: "product 2",
    id: "940394",
  },
];
