import { TEST_SURVEY } from "./ActionType";

const initialState = {
  listOfTestDetails: null,
  isLoading: false,
  startTestList: null,
  submittedAnswer: null,
  addedTechnicalTestSection: null,
  previousData: null,
  supplementaryAnswer: null,
  clearAddTechnicalTest: null,
};

const TestAndSurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_SURVEY.testAndSurveyLoader:
      return {
        ...state,
        isLoading: true,
      };
    case TEST_SURVEY.getTestDetails:
      return {
        ...state,
        listOfTestDetails: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.startTest:
      return {
        ...state,
        startTestList: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.submitAnswer:
      return {
        ...state,
        submittedAnswer: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.addTechnicalSection:
      return {
        ...state,
        addedTechnicalTestSection: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.getPreview:
      return {
        ...state,
        previousData: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.saveSupplementaryAnswer:
      return {
        ...state,
        supplementaryAnswer: action.payload,
        isLoading: action.loading,
      };
    case TEST_SURVEY.clearAddTechnicalTest:
      return {};

    default:
      return state;
  }
};

export default TestAndSurveyReducer;
