export const PROFILE_GAP_ANALYSIS = {
  pgaLoader: "pgaLoader",
  getReport: "getReport",
  getPgaList: "getPgaList",
  pgaDownload: "pgaDownload",
  clearData: "clearPgaReportData",
  getPlanOfActionData: "getPlanOfActionData",
  updatePlanOfActionStatus: "updatePlanOfActionStatus",
  updateReportSatisfaction: "updateReportSatisfaction",
  saveReportComments: "saveReportComments",
  getPrePgaCallData: "getPrePgaCallData",
  getPgaCallData: "getPgaCallData",
  getPlanOfActionStatus: "getPlanOfActionStatus",
  getCvReviewList: "getCvReviewList",
  updateSendForReview: "updateSendForReview",
  downloadCv: "downloadCv",
  deleteCv: "deleteCv",
  uploadCv: "uploadCv",
  viewCv: "viewCv",
};
