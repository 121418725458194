import { Details } from "./ActionType"

const INITIAL_STATE = {
    getPoints: null,
}

const additionalPointsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Details.getPoints:
            return {
                ...state,
                getPoints: action.payload,

            }

        default:
            return state
    }
}
export default additionalPointsReducer