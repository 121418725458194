import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Enable from "../../assets/icons/enableTick.svg";
import HeadingContainer from "../../components/HeadingContainer";
import Tabs, { StyledTab } from "../../components/Tab";
import { getAcademicStatus } from "../../redux/academic/Action";
import { getStudentSectionByStageName } from "../../redux/student/Action";
import { NAVIGATE_TO } from "../../routes/RoutePaths";
import { ContentWrapper, Typo, Wrapper } from "../../styled/Index";
import { HtmlData, TabList } from "../../utils/Data";
import { isOnboardingCompleted } from "../../utils/StageStepStatus";
import { useStyles } from "./Styles";
import actions from "../../CleverTab/Events";
export default function Index() {
  const [tabData, setTabData] = useState([]);
  const classes = useStyles();
  const handleFormSubmit = () => {
    handleSubmit();
    if (finalFile === null) {
      setFileErr("Please Upload MarkSheet");
    } else {
      setFileErr("");
    }
  };
  const [finalFile, setFinalFile] = useState(null);
  const [fileErr, setFileErr] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    onSubmit: handleFormSubmit,
  });

  const { getStatus } = useSelector((state) => state.AcademicReducer);
  const { handleSubmit } = formik;

  const navigate = useNavigate();
  const params = useLocation();
  const url = useParams();

  const activeIndex = TabList.findIndex((el) => el.label === params?.state);
  const tabvalue = activeIndex > -1 ? activeIndex : 0;

  useEffect(() => {
    dispatch(getAcademicStatus());
    dispatch(getStudentSectionByStageName(url.productId, url.stageName));
    actions.PushEvent("UP: User Clicked Academic Information Card", {
      "Date": new Date()
    });
  }, []);

  useEffect(() => {
    if (getStatus) {
      if (getStatus.success) {
        setTabData(getStatus.data);
      }
    }
  }, [getStatus]);

  const renderPointerEventsClass = () => {
    return isOnboardingCompleted() ? classes.pointerEvents : "";
  };

  return (
    <Wrapper>
      <div className={renderPointerEventsClass()}>
        <HeadingContainer title={"Academic Information"} data={HtmlData} />
        <Grid style={{
          paddingLeft: "1rem",
          paddingTop: "1rem",
          fontSize: "0.9rem",
          color: "rgb(0,0,137)",
        }}>
          <div>Academic Information to be filled in the same order shown(left to right).Skip Diploma if not pursued</div>
        </Grid>

      </div>
      <ContentWrapper>

        {/* main div */}
        <Grid container className={classes.mainDiv}>
          {/* tab div */}
          <Grid
            item
            md={12}
            sx={{ display: "flex", flexBasis: "0 !important" }}
          >
            <Tabs value={tabvalue}>
              {tabData &&
                tabData.map((item, index) => {
                  return (
                    <div className={classes.tabMainDiv}>
                      <div>
                        <StyledTab
                          sx={{
                            color: index === tabvalue ? "#343B89" : "#999999",
                          }}
                          onClick={() =>
                            navigate(
                              NAVIGATE_TO.academicPath(
                                url.productId,
                                url.stageName,
                                item.path
                              ),
                              {
                                state: item.name,
                              }
                            )
                          }
                          label={item.name}
                        />
                      </div>
                      {item.completed && (
                        <div className={classes.verificationStyle}>
                          <img src={Enable} alt={"Verification_Icon"} />
                        </div>
                      )}
                    </div>
                  );
                })}
            </Tabs>
          </Grid>
          {/* form div  */}
          <Grid item md={12} className={classes.outletDiv}>
            <Outlet />
          </Grid>
        </Grid>
      </ContentWrapper>
    </Wrapper>
  );
}
