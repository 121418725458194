import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import DeleteIcon from "../assets/icons/DeleteIcon.svg";
import PDFImage from "../assets/images/Pdf.svg";
import { IconDiv, Typo } from "../styled/Index";
import { useStyles } from "./Styles";
function AcademicMarksCard({
  activeBacklog,
  clearedBacklog,
  sgpaScale,
  sgpa,
  document,
  semester,
  handleDeleteClick,
}) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const handleMouseOver = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      <Grid
        container
        className={classes.cardBorder}
        onMouseOver={() => handleMouseOver()}
        onMouseLeave={() => handleMouseLeave()}
      >
        <Grid
          item
          md={3}
          sm={2}
          xs={2}
          lg={3}
          xl={3}
          className={classes.semImageGridStyle}
        >
          <div className={classes.semBackGround}>
            <Typo color={"#EFAB0C"}>{semester}</Typo>
          </div>
        </Grid>
        <Grid item md={9} sm={10} xs={10} lg={9} xl={9}>
          <Grid container spacing={1} className={classes.semMainCard}>
            <Grid item md={7} sm={6} xs={6}>
              <Typo variant={"body2"} color={"#999999"}>
                Backlogs(Active)
              </Typo>
            </Grid>
            <Grid item md={5} sm={6} xs={6}>
              <Typo variant={"body2"}>{activeBacklog}</Typo>
            </Grid>
            <Grid item md={7} sm={6} xs={6}>
              <Typo variant={"body2"} color={"#999999"}>
                Backlogs(Cleared)
              </Typo>
            </Grid>
            <Grid item md={5} sm={6} xs={6}>
              <Typo variant={"body2"}>{clearedBacklog}</Typo>
            </Grid>
            <Grid item md={7} sm={6} xs={6}>
              <Typo variant={"body2"} color={"#999999"}>
                SGPA Scale{" "}
              </Typo>
            </Grid>
            <Grid item md={5} sm={6} xs={6}>
              <Typo variant={"body2"}>{sgpaScale}</Typo>
            </Grid>
            <Grid item md={7} sm={6} xs={6}>
              <Typo variant={"body2"} color={"#999999"}>
                SGPA
              </Typo>
            </Grid>
            <Grid item md={5} sm={6} xs={6}>
              <Typo variant={"body2"}>{sgpa}</Typo>
            </Grid>
          </Grid>
        </Grid>

        {/* )} */}
        {document && (
          <>
            <Grid item md={12} sm={12} xs={12} align="center">
              <hr style={{ width: "90%" }} />
            </Grid>
            {document.documentName && (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                className={classes.documentGrid}
              >
                <div className={classes.documentDiv}>
                  <img
                    src={PDFImage}
                    alt={"Pdf_Image"}
                    className={classes.imageStyle}
                  />
                  <div className={classes.documentNameDiv}>
                    <Typo variant={"subtitle2"}>{document.path}</Typo>
                  </div>
                </div>
              </Grid>
            )}
          </>
        )}
        <IconDiv show={hover}>
          <img
            src={DeleteIcon}
            className={classes.deleteIconStyle}
            alt={"Delete_Icon"}
            onClick={handleDeleteClick}
          />
        </IconDiv>
      </Grid>
    </>
  );
}

export default AcademicMarksCard;
