import { makeStyles } from "@mui/styles";
// import { COLORS } from "../../utils/Shared";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
    scheduleButton: {
        width: "200px",
        height: "40px",
    },

}));
