import { PROFILE_GAP_ANALYSIS } from "./ActionType";

const initialState = {
  reportData: null,
  pgaReport: null,
  pgaDownloadStatus: null,
  planOfActionData: null,
  updatedStatus: null,
  isLoading: false,
  updatedReportSatisfaction: null,
  savedComments: null,
  PrePgaCallDetails: null,
  pgaCallDetails: null,
  planOfActionCompletedStatus: null,
  listOfCvReview: null,
  cvReviewedData: null,
  downloadedCv: "",
  deletedCv: null,
  uploadedCv: "",
  viewedCv: "",
};

const ProfileGapAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_GAP_ANALYSIS.clearData:
      return {};
    case PROFILE_GAP_ANALYSIS.pgaLoader:
      return {
        ...state,
        isLoading: true,
      };
    case PROFILE_GAP_ANALYSIS.getReport:
      return {
        ...state,
        reportData: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getPgaList:
      return {
        ...state,
        pgaReport: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.pgaDownload:
      return {
        ...state,
        pgaDownloadStatus: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getPlanOfActionData:
      return {
        ...state,
        planOfActionData: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.updatePlanOfActionStatus:
      return {
        ...state,
        updatedStatus: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.updateReportSatisfaction:
      return {
        ...state,
        updatedReportSatisfaction: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.saveReportComments:
      return {
        ...state,
        savedComments: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getPrePgaCallData:
      return {
        ...state,
        PrePgaCallDetails: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getPgaCallData:
      return {
        ...state,
        pgaCallDetails: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getPlanOfActionStatus:
      return {
        ...state,
        planOfActionCompletedStatus: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.getCvReviewList:
      return {
        ...state,
        listOfCvReview: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.downloadCv:
      return {
        ...state,
        downloadedCv: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.deleteCv:
      return {
        ...state,
        deletedCv: action.payload,
        isLoading: action.loading,
      };

    case PROFILE_GAP_ANALYSIS.uploadCv:
      return {
        ...state,
        uploadedCv: action.payload,
        isLoading: action.loading,
      };

    case PROFILE_GAP_ANALYSIS.updateSendForReview:
      return {
        ...state,
        cvReviewedData: action.payload,
        isLoading: action.loading,
      };
    case PROFILE_GAP_ANALYSIS.viewCv:
      return {
        ...state,
        viewedCv: action.payload,
        isLoading: action.loading,
      };

    default:
      return state;
  }
};

export default ProfileGapAnalysisReducer;
