export const AdmitCard = {
  loader: "admitCardLoader",
  clearData: "admitCardClearData",
  getLeftContainer: "getLeftContainer",
  postSchoolDetails: "postSchoolDetails",
  getAddedSchoolWithStatus: "getAddedSchoolWithStatus",
  getAdmitCardName: "getAdmitCardName",
  uploadFile: "uploadFile",
  downloadTemplate: "downloadTemplate",
  downloadFile: "downloadFile",
  downloadUploadedFile: "downloadUploadedFile",
  downloadAdmitCard: "downloadAdmitCard",
};
