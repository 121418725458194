import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackdropLoader from "../../../components/Backdrop";
import SnackBar from "../../../components/CustomizedSnackBar";
import {
  confirmCallSchedule,
  getCallDetails,
  getTimeSlots,
  getVariantStepsById,
  rescheduleCall,
} from "../../../redux/strategy-session/callSchedule/Action";
import { NAVIGATE_TO } from "../../../routes/RoutePaths";
import CallConfirmationPopup from "../../onBoarding/popup/CallConfirm";
import CallSchedule from "../../onBoarding/popup/CallSchedule";
import AddToGoogleCalendarPopup from "../../onBoarding/popup/GoogleCalendar";
import Popup from "../../onBoarding/Popup";
export default function ScheduleCard(props) {
  console.log(props, "&&&&&&&*****((((");
  const { stageName, productId } = useParams();

  const navigate = useNavigate();
  /**state value to open confirmation popup */
  const [openCallConfirmPopup, setOpenCallConfirmPopup] = React.useState(false);

  /**state value to open add to google calendar popup */
  const [openGoogleCalendarPopup, setOpenGoogleCalendarPopup] =
    React.useState(false);

  /**state value to check whether add to google calendar popup is active or not */
  const [isAddToGoogleCalendarActive, setIsAddToGoogleCalendarActive] =
    React.useState(false);

  /** state value to store the timeSlots from the response*/
  const [timeSlots, setTimeSlots] = React.useState([]);

  /** state value to store the timeSlots  duration(eg- 9AM to 10AM => 1 hr duration) from the response*/
  const [duration, setDuration] = React.useState([]);

  /**stores the selected date from the calendar and sends to display time slots file (eg.Tuesday, Oct 17) */
  const [clickedDate, setClickedDate] = React.useState(new Date());

  /**stores the selectedTimeSlot and sends to the call confirm popup (eg.10:00 AM - 10:45 AM) */
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState("");

  /**state value to store the selected time slot and sends this time slot to google calendar popup (eg.03:20 PM) */
  const [confirmedTimeSlot, setConfirmedTimeSlot] = React.useState("");

  /**state value to store the selected date in the calendar and send the value to call confirm popup(eg.Tuesday, Oct 17) */
  const [selectedDate, setSelectedDate] = React.useState("");

  /**state value to store the confirm date from the confirmation popup and send the value to add to google calendar popup  (eg.Oct 13,2021)*/
  const [confirmedDate, setConfirmedDate] = React.useState("");

  const [currentDate, setCurrentDate] = React.useState(
    moment(new Date()).format("yyyy-M-D")
  );
  const stage = "QPMC 1 call schedule";
  const [selectedDateTime, setSelectedDateTime] = React.useState("");

  const [getStatus, setGetStatus] = useState(false);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    snackMsg: "",
    snackOpen: false,
    snackVariant: "error",
  });

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackMsg: "", snackVariant: "" });
  };

  /**
   * on selecting date if it is < 10 we will concatenate 0 before the date (eg.3 => 03)
   */
  const pad = (d) => {
    return d < 10 ? "0" + d.toString() : d.toString();
  };

  /**this variable is used to store the response from the api and it taken from the reducer state */
  const {
    isLoading,
    timeSlotsList,
    getVariantSteps,
    callDetails,
    rescheduledCall,
    confirmedCallStatus,
  } = useSelector((state) => state.callScheduleReducer);

  /** on clicking schedule button from the call schedule popup this function opens call confirm popup and closes call schedule popup*/
  const handleCallScheduleClick = () => {
    //
    props.handleClose();
    setOpenCallConfirmPopup(true);
  };

  const handleCancelClick = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (getVariantSteps) {
      if (getVariantSteps.success) {
        setState({
          ...state,
          snackOpen: false,
        });
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: getVariantSteps && getVariantSteps.message,
          snackVariant: "error",
        });
      }
    }
  }, [getVariantSteps]);
  // console.log(callName);
  console.log(props.appointmentId);
  /**on clicking confirm button in the call confirm popup it schedules the onboarding call(post call takes place) and opens google calendar popup */
  const handleCallConfirmClick = () => {
    setOpenCallConfirmPopup(false);
    setOpenGoogleCalendarPopup(true);
    setIsAddToGoogleCalendarActive(true);

    if (getVariantSteps && getVariantSteps.success) {
      const findObj =
        getVariantSteps &&
        getVariantSteps?.mentorCalendarList.find(
          (data) => data.name === props.callName
        );
      if (props.isReschedule) {
        let requestBody = {
          datetime: selectedDateTime,
          calendarID: findObj && findObj.calendarId,
        };
        dispatch(rescheduleCall(props.appointmentId, requestBody));
      } else {
        let requestBody = {
          appointmentTypeID: findObj && findObj.appointmentId,
          calendarID: findObj && findObj.calendarId,
          datetime: selectedDateTime,
          firstName: JSON.parse(
            window.sessionStorage.getItem("studentBasicData")
          ).first_name,
          lastName: JSON.parse(
            window.sessionStorage.getItem("studentBasicData")
          ).last_name,
          email: JSON.parse(window.sessionStorage.getItem("studentBasicData"))
            .email_id,
          phone: JSON.parse(window.sessionStorage.getItem("studentBasicData"))
            .phone_number,
        };
        dispatch(confirmCallSchedule(productId, requestBody));
      }
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: getVariantSteps && getVariantSteps.message,
        snackVariant: "error",
      });
    }
  };

  const mentor = JSON.parse(
    window.sessionStorage.getItem("studentBasicData")
  ).first_name;
  const handleCallConfirmCancelClick = () => {
    setOpenCallConfirmPopup(false);
    setSelectedTimeSlot("");
  };

  /**this function is used to select the time slot(09.30 AM - 10 AM) from the call schedule popup */
  const timeSlotClick = (item, time) => {
    setSelectedTimeSlot(item);
    setSelectedDateTime(time.time);
    const data =
      new Date(time.time).getHours() <= 12
        ? pad(new Date(time.time).getHours()) +
        ":" +
        pad(new Date(time.time).getMinutes())
        : pad(new Date(time.time).getHours() % 12) +
        ":" +
        pad(new Date(time.time).getMinutes());

    const format = new Date(time.time).getHours() < 12 ? " AM" : " PM";
    const finalTime = data + format;
    setConfirmedTimeSlot(finalTime);
  };

  /**this function list available time slots on clicking particular date  */
  const handleDateClick = (date) => {
    /**using moment we can convert date to required format */
    let finalSelectedDate = moment(new Date(date)).format("dddd, MMM D");

    let onSelectedDate = moment(new Date()).format("dddd, MMM D");
    let currentScheduledDateForCalendar = moment(new Date()).format(
      "MMM D, YYYY"
    );
    let scheduledDateForCalendar = moment(new Date(date)).format("MMM D, YYYY");
    let selectedDate = moment(new Date(date)).format("YYYY-M-D");

    if (date) {
      setClickedDate(date);
      setSelectedDate(finalSelectedDate);
      setConfirmedDate(scheduledDateForCalendar);
    } else {
      setClickedDate(new Date());
      setSelectedDate(onSelectedDate);
      setConfirmedDate(currentScheduledDateForCalendar);
    }

    if (getVariantSteps && getVariantSteps.success) {
      const findObj =
        getVariantSteps &&
        getVariantSteps?.mentorCalendarList &&
        getVariantSteps?.mentorCalendarList?.find(
          (data) => data.name === props.callName
        );
      dispatch(
        getTimeSlots(
          productId,
          findObj && findObj.appointmentId,
          findObj && findObj.calendarId,
          date ? selectedDate : currentDate
        )
      );
    } else {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: getVariantSteps && getVariantSteps.message,
        snackVariant: "error",
      });
    }
  };
  /**this function is called while clicking add to google calendar button in (add to google calendar popup) */
  const handleAddToGoogleCalendarClick = () => {
    setGetStatus(true);
    dispatch(getCallDetails(productId, props.stepName));
    setClickedDate(new Date());
    setSelectedDate("");
    setConfirmedDate("");
    setOpenGoogleCalendarPopup(false);

    // dispatch(getStudentProductStages(productId));
    dispatch(getCallDetails(productId, props.stepName));
  };
  /**this function is called while clicking no Thanks button in (add to google calendar popup) */
  const handleNoThanksClick = () => {
    setGetStatus(true);
    dispatch(getCallDetails(productId, props.stepName));
    setClickedDate(new Date());
    setSelectedDate("");
    setConfirmedDate("");
    setOpenGoogleCalendarPopup(false);
    // dispatch(getStudentProductStages(productId));
    dispatch(getCallDetails(productId, props.stepName));
  };

  useEffect(() => {
    dispatch(getVariantStepsById(productId));
    if (props.isOpen) {
      handleDateClick();
    }
    // setClickedDate(new Date());
  }, [props.isOpen]);

  useEffect(() => {
    if (timeSlotsList) {
      if (timeSlotsList && timeSlotsList.success) {
        setTimeSlots(timeSlotsList && timeSlotsList.availableTime);
        setDuration(timeSlotsList && timeSlotsList.duration);
        setState({
          ...state,
          snackOpen: false,
        });
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: timeSlotsList && timeSlotsList.message,
          snackVariant: "error",
        });
      }
    }
  }, [timeSlotsList]);
  return (
    <div>
      <Popup
        open={props.isOpen}
        width={"md"}
        title={"Call Schedule"}
        rightButton={"Schedule"}
        leftButton={"Cancel"}
        popupTitle={"Call Schedule"}
        handleRightButtonClick={handleCallScheduleClick}
        handleLeftButtonClick={handleCancelClick}
        selectedTimeSlot={selectedTimeSlot}
      >
        <CallSchedule
          handleClick={handleDateClick}
          clickedDate={clickedDate}
          pad={pad}
          listedTimeSlots={timeSlots}
          duration={duration}
          handleTimeSlotClick={timeSlotClick}
          selectedTimeSlot={selectedTimeSlot}
        />
      </Popup>
      <Popup
        open={openCallConfirmPopup}
        width={"sm"}
        title={"Confirmation"}
        leftButton={"Cancel"}
        rightButton={"Confirm"}
        handleLeftButtonClick={handleCallConfirmCancelClick}
        handleRightButtonClick={handleCallConfirmClick}
        selectedTimeSlot={selectedTimeSlot}
      >
        <CallConfirmationPopup
          heading={
            "We have scheduled a call on the following date and time. Please confirm."
          }
          date={selectedDate}
          time={selectedTimeSlot}
        />
      </Popup>
      <Popup
        open={openGoogleCalendarPopup}
        width={"xs"}
        isGoogleCalendarActive={isAddToGoogleCalendarActive}
      >
        <AddToGoogleCalendarPopup
          confirmationText={
            `${mentor}` + ",We've have got you confirmed for your appointment."
          }
          scheduledDate={confirmedDate}
          scheduledTime={confirmedTimeSlot}
          addToGoogleCalendarButton={"Add to my Google Calendar"}
          noThanksButton={"No, Thanks"}
          handleClick={handleAddToGoogleCalendarClick}
          handleCancel={handleNoThanksClick}
        />
      </Popup>

      <BackdropLoader open={isLoading} isPopup={true} />
      <SnackBar
        open={state.snackOpen}
        severity={state.snackVariant}
        message={state.snackMsg}
        onClose={handleSnackClose}
      />
    </div>
  );
}
