import { Schedule } from "./ActionType";

const INITIAL_STATE = {
    timeSlotsList: null,
    getVariantSteps: null,
    isLoading: false,
    callDetails: null,
    confirmedCallStatus: null,
    rescheduledCall: null,
};

const callScheduleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Schedule.obLoader:
            return {
                ...state,
                isLoading: true,
            };
        case Schedule.getTimeSlots:
            return {
                ...state,
                timeSlotsList: action.payload,
                isLoading: action.loading,
            };
        case Schedule.getVariantStepsById:
            return {
                ...state,
                getVariantSteps: action.payload,
                isLoading: action.loading,
            };
        case Schedule.getCallDetails:
            return {
                ...state,
                callDetails: action.payload,
                isLoading: action.loading,
            };
        case Schedule.confirmCallSchedule:
            return {
                ...state,
                confirmedCallStatus: action.payload,
                isLoading: action.loading,
            };
        case Schedule.rescheduleCall:
            return {
                ...state,
                rescheduledCall: action.payload,
                isLoading: action.loading,
            };
        default:
            return state;
    }
};

export default callScheduleReducer;
