export const ProfileMentoring = {
  loader: "profileMentoringLoader",
  clearData: "profileMentoringClearData",
  getDocumentList: "getDocumentList",
  uploadDocument: "uploadDocument",
  downloadDocument: "downloadDocument",
  getLeftContainer: "getLeftContainer",
  completeStage: "completeStage",
  QPMC2ReportReducer: "QPMC2ReportReducer",
  workSheetSteps: "workSheetSteps",
  downloadDocumentModal: "downloadDocumentModal",
  downloadDocumentModals: "downloadDocumentModals",
  clearCustomData: "clearCustomData",
  clear: "clear",
};
