import axios from "axios";
import Cookies from "js-cookie";
import { GETTER } from "../utils/Constants";
import { BASE_URL, LOG_OUT_URL } from "./Url";

const Axios = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  let accessToken =
    // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDAwMDAwMjE0IiwiZXhwIjoxNjQwNzU2OTcwLCJpYXQiOjE2NDA3NDk3NzB9.SJVlHUr0Q3rO7p3yraskKu20zf_YcTBcfe4dwGonSA7rGyqs6HAL5COUEp8ajj_1mo3qmKKpJ9C1KMILzmx8iw";
    GETTER.sessionGetter("accessToken");
  request.headers.Authorization = `Bearer ${accessToken}`;
  // request.headers.admin = 'yes';
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (
    error?.response?.status === 401 ||
    error?.response?.status === 403 ||
    error?.response?.status === 500
  ) {
    setTimeout(() => {
      sessionStorage.clear();
      localStorage.clear();
      
      window.location.href = "/";
    }, 2000);
  }
  return Promise.reject(error);
};

Axios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

Axios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default Axios;
