import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useStyles } from "../pages/onBoarding/Styles";
import CustomButton from "./CustomButton";
import { isEmptyObject, isEmptyString } from "../../src/utils/Validation";
import { COLORS } from "../utils/Shared";

const Popup = ({
  open,
  title,
  children,
  leftButton,
  rightButton,
  handleClose,
  handleLeftButtonClick,
  handleRightButtonClick,
  width,
  isGoogleCalendarActive,
  selectedTimeSlot,
  headerColor,
  fullWidth,
  needDelete,
  handleDeleteClick,
  size,
  rightButtonColor,
  disabled,
  data
}) => {
  const classes = useStyles({ size });

  const renderHeader = () => {
    if (isGoogleCalendarActive) {
      return <div></div>;
    } else {
      return (
        <DialogTitle
          className={classes.popUpBanner}
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ color: headerColor && headerColor }}
        >
          {title}
        </DialogTitle>
      );
    }
  };

  const renderFooter = () => {
    if (isGoogleCalendarActive) {
      return <div></div>;
    } else {
      return (
        <DialogActions classes={{ root: classes.dialogActionContainer }}>
          {needDelete && !isEmptyString(data) && (
            <>
              <CustomButton
                onClick={handleDeleteClick}
                variant={"outlined"}
                color={"error"}
              >
                Delete Experience
              </CustomButton>
              <div className={classes.spacer}></div>
            </>
          )}
          {leftButton && (
            <CustomButton
              className={classes.button}
              variant={"inherit"}
              autoFocus
              onClick={handleLeftButtonClick}
            >
              {leftButton}
            </CustomButton>
          )}
          {rightButton && (
            <CustomButton
              variant={"contained"}
              color={rightButtonColor || "primary"}
              autoFocus
              onClick={() => handleRightButtonClick(selectedTimeSlot)}
              disabled={disabled}
            >
              {rightButton}
            </CustomButton>
          )}
        </DialogActions>
      );
    }
  };
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      maxWidth={width}
      fullWidth={fullWidth}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {renderHeader()}

      <DialogContent className={classes.DialogRoot} dividers>
        {children}
      </DialogContent>
      {renderFooter()}
    </Dialog>
  );
};

export default Popup;
