import { DEADLINE_TRACKER } from "./ActionType";

const initialState = {};

const DeadlineTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEADLINE_TRACKER.loader:
      return {
        ...state,
        isLoading: true,
      };
    case DEADLINE_TRACKER.getPaginateBSchool:
      return {
        ...state,
        bSchoolList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateCountry:
      return {
        ...state,
        countryList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateRegion:
      return {
        ...state,
        regionList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateSubregion:
      return {
        ...state,
        subRegionList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.postSearch:
      return {
        ...state,
        postSearchList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.allBSchool:
      return {
        ...state,
        allBSchoolList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.schoolLogoList:
      return {
        ...state,
        schoolLogoList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateIntakeTerm:
      return {
        ...state,
        intakeTermList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateIntakeYear:
      return {
        ...state,
        intakeYearList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.getPaginateRoundNo:
      return {
        ...state,
        roundNoList: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.clearData:
      return {};
    case DEADLINE_TRACKER.getDeadlineTrackerGuide:
      return {
        ...state,
        deadlineTrackerGuider: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.putUserGuide:
      return {
        ...state,
        userGuideStatus: action.payload,
        isLoading: false,
      };
    case DEADLINE_TRACKER.clearCustomData:
      return {
        ...state,
        [action.fieldName]: null,
      };
    default:
      return state;
  }
};

export default DeadlineTrackerReducer;
