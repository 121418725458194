import { Interview } from "./ActionType";

const INITIAL_STATE = {
    getSubStages: []
};

const InterviewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case Interview.getSubStages:
            return {
                ...state,
                getSubStages: action.payload,
                // isLoading: action.loading,
            };

        default:
            return state;
    }
};

export default InterviewReducer;
