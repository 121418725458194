import { makeStyles, withStyles } from "@mui/styles";
import { COLORS } from "../utils/Shared";
import styled from "styled-components";
import { Checkbox, Dialog, Slider, Typography } from "@mui/material";
import BannerImage from "../assets/images/DeadlineBanner.png";

const borderImage = (color) => {
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23${color}FF' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`;
};

export const useStyles = makeStyles((theme) => ({
  stepLabel: {
    color: `${COLORS.white} !important`,
  },
  admitCardModal: {
    border: "4px solid #81C14F",
    borderRadius: "8px",
    padding: "4px 4px",
  },
  pointerEvents: {
    pointerEvents: "none",
  },
  addSchoolButton: {
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "30px",
    paddingRight: "30px",
    whiteSpace: "nowrap",
  },
  listItemText: {
    fontFamily: "Poppins, sans-serif !important",
    fontSize: "13px !important",
    margin: "0px 15px 15px -15px",
    display: "flex",
    flex: "wrap",
  },
  listItemCard: {
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "18px !important",
    display: "flex",
    flex: "wrap",
  },
  addDiv: {
    width: "100%",
    height: "155px",
    backgroundImage: borderImage("343B89"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleClass: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    fontSize: "1.3rem",
    alignItems: "center",
    paddingRight: "60px",
    marginTop: "-5px",
  },
  dropzoneDiv: {
    height: "200px",
    // width: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "move",
    borderRadius: "10px",
    backgroundImage: borderImage("343B89"),
  },
  dropzoneInnerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "16%",
  },
  cvdropzoneDiv: {
    height: "170px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundImage: borderImage("343B89"),
  },
  cvdropzoneInnerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    cursor: "move",
  },
  dropzonePdfStyle: {
    height: "70px",
    width: "70px",
    cursor: "move",
  },
  cvDropZoneInnerText: {
    paddingRight: "5%",
    paddingLeft: "3%",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E7E7E7",
    borderRadius: "4px",
  },
  bottomPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "15px",
    gap: "15px",
    borderTop: "1px solid #E7E7E7",
  },
  label: {
    left: "-14px",
    "& + .MuiInput-root": {
      marginTop: "11px !important",
    },
  },
  helperText: {
    margin: "0px !important",
  },
  popperStyles: {
    height: "400px",
    width: "500px",
    backgroundColor: "white",
    border: "1px solid rgba(231, 231, 231, 1)",
    borderRadius: "10px",
    overflow: "scroll",
    padding: "10px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    top: "20px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::after": {
      display: "block",
      bottom: "99%",
      right: "16px",
      border: "solid transparent",
      content: `" "`,
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderColor: "rgba(136, 183, 213, 0)",
      borderWidth: "10px",
      marginLeft: "-30px",
      borderBottomColor: "white",
    },
    "&::before": {
      bottom: "100%",
      right: "16px",
      border: "1px solid transparent",
      content: `" "`,
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
      borderColor: "rgba(194, 225, 245, 0)",
      borderBottomColor: "rgba(231, 231, 231, 1)",
      borderWidth: "10px",
      marginLeft: "-39px",
    },
  },
  imageStyle: {
    height: "25px",
    width: "25px",
  },
  documentGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentDiv: {
    display: "flex",
    flexDirection: "row",
    padding: "1%",
  },
  cardBorder: {
    border: "1px solid #E7E7E7",
    position: "relative !important",
    height: "155px",
    "&:hover": {
      boxShadow: "1px 2px 5px 4px rgb(231, 231, 231)",
    },
  },
  semImageGridStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
  semImageStyle: {
    height: "60px",
    width: "60px",
  },
  formStyle: {
    paddingLeft: "20px",
  },
  semMainCard: {
    padding: "5px",
  },
  documentNameDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  semBackGround: {
    width: "60px",
    height: "60px",
    background: "rgba(245, 222, 65, 0.2)",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIconStyle: {
    height: "26px",
    cursor: "pointer",
  },
  fileNameCard: {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    border: "3px solid #81C14F",
    borderRadius: "10px",
    opacity: "1",
    "&:hover": {},
  },
  fileNameDeleteIcon: {
    position: "absolute",
    right: "2%",
    top: "10%",
  },
  fileNameDiv: {
    marginTop: "10px",
    marginRight: "10px",
  },
  cardGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileNamePDF: {
    height: "40px",
    width: "60px",
  },
  browse: {
    padding: "2.5px 21px !important",
  },
  timerIcon: {
    width: "32px",
    height: "32px",
    color: "#FD534F",
    opacity: "1",
  },
  remainingTimerIcon: {
    width: "28px",
    height: "28px",
    color: "#FD534F",
  },
  calendarIcon: {
    width: "25px",
    height: "25px",
  },
  rescheduleButton: {
    width: "130px",
    height: "33px",
  },
  callDetailsContainer: {
    padding: "1%",
  },
  leftSpacing: {
    paddingLeft: "5%",
  },

  photoFrameBackdrop: {
    position: "relative",
    display: "flex",
    width: "285px",
    height: "285px",
    borderRadius: "50%",
    background: ({ isImage, url }) =>
      isImage && url
        ? `url(${url}) center center / cover no-repeat transparent`
        : "transparent linear-gradient(138deg, #4199ec 0%, #5e6df8 100%) 0% 0% no-repeat padding-box",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "85px",
    letterSpacing: "1.7px",
    color: "#ffffff",
    fontWeight: 600,
    textTransform: "uppercase",
    fontFamily: "'Roboto', sans-serif",

    [theme.breakpoints.down("lg")]: {
      width: "175px",
      height: "175px",
      fontSize: "50px",
    },

    [theme.breakpoints.down("xl")]: {
      width: "240px",
      height: "240px",
      fontSize: "60px",
    },

    [theme.breakpoints.down("md")]: {
      width: "90px",
      height: "90px",
      fontSize: "20px",
    },

    "&:hover::before": {
      content: ({ hoverContent }) => `"${hoverContent}"`,
      textTransform: "none",
      display: "grid",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      fontFamily: "'Poppins', sans-serif",
      fontSize: "18px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
      fontWeight: 600,
      letterSpacing: "0.4px",
      background: "rgba(0, 0, 0, 0.73)",
      borderRadius: "50%",
      color: "#FFFFFF",
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  },
  accordionSummary: {
    color: "#333333 !important",
    fontSize: "12px !important",
    textTransform: "uppercase !important",
    letterSpacing: "0.84px !important",
    opacity: "1 !important",
  },
  accordionStyle: {
    "& .MuiAccordion-rounded": {
      borderRadius: "unset !important",
      boxShadow: "none !important",
    },
    "& .MuiAccordionSummary-content p": {
      fontSize: "14px !important",
      fontWeight: "500 !important",
      color: "#333333 !important",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "48px !important",
      margin: "0px 10px !important",
      padding: "0 !important",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      padding: "0px !important",
    },
    "& .MuiAccordionSummary-expandIcon": {
      transition: "none !important",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  popoverPaper: {
    width: "320px !important",
    height: "275px !important",
    padding: "10px !important",
    borderRadius: "15px !important",

    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialogBottomContainer: {
    padding: "14px !important",
    paddingRight: "20px !important",
    gap: "6px",
  },
  hideScroll: {
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  buttonMinWidth: {
    minWidth: "100px",
  },
  BannnerImageMainDiv: {
    display: "flex",
    width: "100%",
    height: "150px",
    backgroundImage: `url(${BannerImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  bannerSecondaryImage: {
    position: "absolute",
    right: "5%",
  },
  bannerTextStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "white",
    fontWeight: "600",
    fontSize: "24px",
  },
  courseCardStyle: {
    border: "1px solid lightgrey",
    width: "100%",
    overflowY: "scroll",
    padding: "2%",
  },
  progressText: {
    border: "1px solid #EFAB0C",
    borderRadius: "10px",
    padding: "1%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
  },
  completeText: {
    border: "1px solid green",
    borderRadius: "10px",
    padding: "1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  linearProgress: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#EFAB0C",
    },
  },
  completedLinearProgress: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
  courseCardMainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // Delete Icon Style
  deleteContainer: {
    "&:hover": {
      backgroundColor: `${COLORS.lightRed}`,
      borderRadius: "4px",
    },
  },
  paginationSelected: {
    justifyContent: "center",
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: ({ paginationButtonColor }) =>
        paginationButtonColor && `${paginationButtonColor} !important`,
    },
  },
  CardMainDiv: {
    paddingLeft: "20px",
    paddingBottom: "40px",
  },
  cardImageDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageStyle: {
    borderRadius: "20%",
  },
  CardMainDiv: {
    border: "1px solid lightgrey",
    width: "100%",
    height: "100%",
  },
  cardDetailsdiv: {
    padding: "10px",
  },
  imageDiv: {
    marginTop: "12%",
    marginLeft: "12%",
  },
  locationDiv: {
    display: "flex",
    flexDirection: "row",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  titleDiv: {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
  },
  nextIconDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  cursorStyle: {
    cursor: "pointer",
  },
  remainingTimeText: {
    color: "#FD534F",
  },
  pointerEvents: {
    pointerEvents: "none",
  },
  documentDropzoneLayout: {
    height: "577px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundImage: borderImage("343B89"),
    cursor: ({ disabled }) => (disabled ? "default" : "move"),
    opacity: ({ opacity }) => opacity,
  },
  documentDropzoneLayoutForPostAdmitService: {
    height: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundImage: borderImage("343B89"),
    cursor: ({ disabled }) => (disabled ? "default" : "move"),
    opacity: ({ opacity }) => opacity,
  },
  documentDropzoneLayoutForSupportPage: {
    boxSizing: "borderBox",
    position: "absolute",
    width: "646px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "#D3D3D35C",
    backgroundImage: borderImage("1093FF"),
    cursor: ({ disabled }) => (disabled ? "default" : "move"),
    opacity: ({ opacity }) => opacity,
  },
  rectangleButtonStyle: {
    minWidth: "125px",
    minHeight: "46px",
    borderRadius: "4px",
  },
  documentUploadIconStyle: {
    width: "90px",
    height: "70px",
  },
  listItemButton: {
    borderRadius: "7px",
    marginBottom: "12px",
  },
  listItem: {
    marginBottom: "-20px",
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: COLORS.white,
  },
  menuIconContainer: {
    marginRight: "11px",
  },
  stepperItem: {
    paddingBottom: "20px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "20px",

    "&:not(:first-child):after": {
      height: "100%",
      width: "4px",
      position: "absolute",
      left: "6px",
      content: `" "`,
      bottom: "60%",
      backgroundColor: "rgba(72, 141, 255, 0.2)",
      borderRadius: "30px",
      zIndex: 2,
    },
  },
  stepperMarker: {
    height: "16px",
    width: "16px",
    backgroundColor: "#488DFF",
    display: "inline-block",
    borderRadius: "50%",
    position: "relative",
    zIndex: 3,
  },
  stageCompleteButton: {
    "&:hover": {
      backgroundColor: COLORS.secondary,
      borderColor: COLORS.secondary,
      color: COLORS.white,
    },
  },
  afterStageCompleteButton: {
    color: COLORS.white,
    cursor: "not-allowed",
  },
  cardTagStyle: {
    backgroundColor: "#99999933",
    padding: "5px 16px",
    borderRadius: "4px",
  },
  documentCard: {
    background: COLORS.white.replace,
    border: `1px solid ${COLORS.lightGray}`,
    borderRadius: "10px",
    padding: "20px 20px 20px 28px",
    "&:hover": {
      border: `2px solid ${COLORS.primaryBlue}`,
      padding: "19px 19px 19px 27px",
    },
  },
  documentDropzoneContainer: {
    pointerEvents: ({ disabled }) => (disabled ? "none" : "unset"),
    width: "100%",
    height: "100%",
  },
  documentDropzoneContainerForSupportPage: {
    cursor: "pointer !important",
    pointerEvents: ({ disabled }) => (disabled ? "none" : "unset"),
    width: "100%",
    height: "100%",
  },
  downloadIconButton: {
    padding: 0,
    borderRadius: "4px",
    "&:hover": {
      background: "rgba(52, 59, 137, 0.1)",
    },
    "&:active": {
      background: "rgba(52, 59, 137, 0.3)",
    },
  },
  deleteIconButton: {
    padding: 0,
    borderRadius: "4px",
    "&:hover": {
      background: "rgba(253, 65, 60, 0.1)",
    },
    "&:active": {
      background: "rgba(253, 65, 60, 0.3)",
    },
  },
  commentIconButton: {
    padding: "1.5px",
    borderRadius: "4px",
    zIndex: 1,
    "&:hover": {
      background: "rgba(24, 170, 213, 0.1)",
    },
    "&:active": {
      background: "rgba(24, 170, 213, 0.3)",
    },
  },
}));
export const FlexView1 = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ gap }) => gap};
  padding-top: ${({ paddingTop }) => paddingTop};
`;

export const FlexView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => gap};
  padding-top: ${({ paddingTop }) => paddingTop};
`;

export const DropHelperText = styled.div`
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BannerBody = styled.div`
  width: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
`;

export const DialogHeaderImg = styled.img`
  height: 55px;
`;

export const DialogHeaderTitle = styled(Typography)`
  position: absolute;
  width: 100%;
  top: calc(50% - 12px);
  left: 36px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
`;

export const DialogHeaderCenteredTitle = styled(Typography)`
  position: absolute;
  width: 100%;
  top: calc(50% - 15px);
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
`;

export const DialogBox = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px !important;
    width: ${({ width }) => width && `${width} !important`};
  }
`;

export const MaxMinSlider = withStyles({
  root: {
    color: "#474E94",
    height: 6,
    padding: "15px 0 10px",
    margin: "0px 0px",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#474E94",
    "&:focus, &:hover, &$active": {
      boxShadow: "none",
    },
  },
  active: {},
  track: {
    height: 3,
  },
  pointerEvent: {
    pointerEvents: "none",
  },
  rail: {
    color: "#474E94",
    opacity: 0.2,
    height: 6,
    borderRadius: "7px",
    paddingLeft: "7px",
  },
  markLabel: {
    color: "#666666 !important",
    fontSize: "14px !important",
  },
  valueLabel: {
    left: "auto !important",
    top: -8,
    textAlign: "center",
    padding: 0,
    background: "none !important",
    "&:before": { display: "none" },
  },
})(Slider);

export const StyledCheckbox = styled(Checkbox)`
  width: 20px;
  height: 20px;
  padding: 0 !important;
  &:hover: {
    background-color: transparent;
  }
`;

export const CheckBoxIcon = styled.span`
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 1px solid #343b89;
  input:disabled ~ & {
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
`;

export const CheckBoxCheckedIcon = styled(CheckBoxIcon)`
  &::before {
    display: block;
    position: relative;
    content: "";
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
    left: calc(50% - 7px);
    background: #343b89;
    border-radius: 4px;
  }
  input:disabled ~ & {
    &::before {
      background: rgba(0, 0, 0, 0.26);
    }
  }
`;
