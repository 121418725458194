import { makeStyles } from "@mui/styles";
import Banner from "../../assets/images/PopupBanner.png";

export const useStyles = makeStyles((theme) => ({
  popUpBanner: {
    backgroundImage: `url(${Banner})`,
    backgroundRepeat: "no-repeat",
    textAlign: "center",
  },
  calendarStyle: {
    width: "167px",
    height: "160px",
  },
  buttonAndText: {
    fontSize: "20px",
    marginLeft: "25%",
    position: "relative",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  learningJourneyText: {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: "700",
    marginTop: "20%",
    marginLeft: "-25%",
  },
  paper: {
    borderRadius: "10px",
    width: ({ size }) => size,
  },
  DialogRoot: {
    borderTop: "none !important",
  },
  callSchedulePopupDivider: {
    marginRight: "25px",
  },

  timeSlotContainer: {
    marginTop: "5px",
    marginLeft: "28px",
    maxHeight: "24vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  selectedDateStyle: {
    paddingTop: "18px",
    marginLeft: "16px",
  },
  confirmationText: {
    padding: "20px",
  },
  scheduledDateTime: {
    marginTop: "-8px",
  },

  timeSlotActive: {
    backgroundColor: "#343b89",
    borderRadius: "6px",
    width: "145px",
    height: "40px",
    marginBottom: "8px",
    color: "#ffffff",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  timeSlotInActive: {
    backgroundColor: "#dae8fe",
    borderRadius: "6px",
    width: "145px",
    height: "40px",
    marginBottom: "8px",
    color: "#333333",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  timerIcon: {
    height: "38px",
    width: "38px",
    filter: "brightness(0%)",
  },
  calendarIcon: {
    height: "30px",
    width: "30px",
    color: "#000000",
    filter: "brightness(0%)",
  },
  dialogActionContainer: {
    gap: "3px",
    padding: "8px 8px 8px 25px",
  },
  spacer: {
    flexGrow: 1,
  },
}));
