import { Resources } from "./ActionType";

const INITIAL_STATE = {
    getDocuments: [],
    getDocumentById: [],
    downloadDocument: null,
    webinarList: ""
};

const ResourceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case Resources.getDocuments:
            return {
                ...state,
                getDocuments: action.payload,
                // isLoading: action.loading,
            };
        case Resources.getDocumentById:
            return {
                ...state,
                getDocumentById: action.payload,
                // isLoading: action.loading,
            };
        case Resources.downloadDocument:
            return {
                ...state,
                downloadDocument: action.payload,
                //   loading: action.loading,
            };
        case Resources.webinarList:
            return {
                ...state,
                webinarList: action.payload,
                // isLoading: action.loading,
            };
        default:
            return state;
    }
};

export default ResourceReducer;
