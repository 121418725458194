import { SUPPORT_PAGE } from "./ActionType";

const initialState = {
  loading: false,
  sendMails: null,
  sendFiles: null,
};

const SupportPage = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORT_PAGE.loading:
      return { ...state, loading: true };

    case SUPPORT_PAGE.clear: {
      return {};
    }
    case SUPPORT_PAGE.sendMails: {
      return {
        ...state,
        sendMails: action.payload,
        loading: action.loading,
      };
    }
    case SUPPORT_PAGE.sendFiles: {
      return {
        ...state,
        sendFiles: action.payload,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
};

export default SupportPage;
