import Grid from "@mui/material/Grid";
import React from "react";
import Calendar from "react-calendar";
import NextArrow from "../assets/images/NextArrow.png";
import PreviousArrow from "../assets/images/PreviousArrow.png";
import "../pages/onBoarding/popup/Calendar.css";

const CallScheduleCalendar = ({
  selectedDateClick,
  dateSelected,
  activeDateFunction,
  activeDate,
  date,
  drillUp,
}) => {
  return (
    <Grid container display="flex" direction="row">
      <Grid item md={12} sm={12} lg={12} xs={12}>
        <div>
          {/* calendar component for the call schedule popupO */}
          <Calendar
            view="date"
            activeStartDate={activeDate}
            defaultValue={new Date()}
            onActiveStartDateChange={activeDateFunction}
            onDrillUp={drillUp}
            showNeighboringMonth={true}
            showWeekNumbers={false}
            nextLabel={
              <img src={NextArrow} alt="RightArrow" className={"right_arrow"} />
            }
            prevLabel={
              <img
                src={PreviousArrow}
                alt="LeftArrow"
                className={"left_arrow"}
              />
            }
            next2Label={null}
            prev2Label={null}
            formatShortWeekday={(locale, date) =>
              ["SU", "MO", "TU", "WE", "TH", "FR", "SA"][date.getDay()]
            }
            onChange={selectedDateClick}
            value={dateSelected}
            minDate={new Date()}
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default CallScheduleCalendar;
