import { CvType } from "./ActionType";

const INITIAL_STATE = {
  getCVDetails: null,
  uploadCVDetails: null,
  deleteCVDetails: null,
  clearCVDetails: null,
};

const CVReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CvType.uploadCVDetails:
      return {
        ...state,
        uploadCVDetails: action.payload,
      };
    case CvType.getCVDetails:
      return {
        ...state,
        getCVDetails: action.payload,
      };
    case CvType.deleteCVDetails:
      return {
        ...state,
        deleteCVDetails: action.payload,
      };
    case CvType.clearCVDetails:
      return {
        ...state,
        uploadCVDetails: null,
        deleteCVDetails: null,
      };
    default:
      return state;
  }
};

export default CVReducer;
