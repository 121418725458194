import { ApplicationStage } from "./ActionType";

const INITIAL_STATE = {
  loading: false,
  getData: null,
  downloadFile: null,
  addSchoolResponse: null,
  savedFile: null,
  saveFieldValue: null,
  schoolList: null,
  getLeftContainer: null,
  completeStage: null,
  getSchoolList: null,
  tabSchoolValue: null,
  saveFileValue: null,
  deleteTabSchool: null,
};

const ApplicationStageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ApplicationStage.clearData:
      return {};

    case ApplicationStage.loader:
      return { ...state, loading: true };

    case ApplicationStage.getApplicationStageData:
      return {
        ...state,
        getData: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.downloadFile:
      return {
        ...state,
        downloadFile: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.addApplicationStageSchool:
      return {
        ...state,
        addSchoolResponse: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.saveFieldValue:
      return {
        ...state,
        savedFieldValue: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.getLeftContainer:
      return {
        ...state,
        getLeftContainer: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.saveApplicationStageFile:
      return {
        ...state,
        savedFile: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.schoolList:
      return {
        ...state,
        schoolList: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.completeStage:
      return {
        ...state,
        completeStage: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.getSchoolList:
      return {
        ...state,
        completeStage: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.tabSchoolValue:
      return {
        ...state,
        tabSchoolValue: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.saveFileValue:
      return {
        ...state,
        saveFileValue: action.payload,
        loading: action.loading,
      };

    case ApplicationStage.deleteTabSchool:
      return {
        ...state,
        deleteTabSchool: action.payload,
        loading: action.loading,
      };
    case ApplicationStage.clearCustomData:
      return {
        ...state,
        [action.fieldName]: null,
      };
    default:
      return state;
  }
};

export default ApplicationStageReducer;
