// import { CALL_SCHEDULE_URL } from "../../../axios/Url";
// // import Axios from "../../axios/Instance";
// import Axios from "../../../axios/Instance";

// // import { QUESTION_ANSWER_URL } from "../../axios/URL";
// // import { errorHandler } from "../../utills/HelperFunction";
// import { errorHandler } from "../../../utils/HelperFunction";
// import { Details } from "./ActionType";

// export const getScheduleDetails = (productId, stageName) => {
//     let studentId = window.sessionStorage.getItem("studentId");

//     return async (dispatch) => {
//         try {
//             await Axios.get(
//                 CALL_SCHEDULE_URL.getCardDetails(studentId, productId, stageName),
//                 {
//                     crossDomain: true,

//                 }
//             ).then((result) => {
//                 dispatch({
//                     type: Details.getCardDetails,
//                     payload: result.data,
//                     loading: false,
//                 });
//             });
//         } catch (error) {
//             dispatch(errorHandler(Details.getCardDetails, error, false));
//         }
//     };
// };
// export const getTimeDetails = (productId, appointmentTypeID, calendarID, date, type) => {
//     let studentId = window.sessionStorage.getItem("studentId");

//     return async (dispatch) => {
//         try {
//             await Axios.get(
//                 CALL_SCHEDULE_URL.getTimeSlots(studentId, productId, appointmentTypeID, calendarID, date, type),
//                 {
//                     crossDomain: true,

//                 }
//             ).then((result) => {
//                 dispatch({
//                     type: Details.getTimeSlots,
//                     payload: result.data,
//                     loading: false,
//                 });
//             });
//         } catch (error) {
//             dispatch(errorHandler(Details.getTimeSlots, error, false));
//         }
//     };
// };
import { Schedule } from "./ActionType";
// import Axios from "../../axios/Instance";
import Axios from "../../../axios/Instance";
// import { ONBOARDING_URL } from "../../axios/Url";
import { QPMC_URL } from "../../../axios/Url";
// import { errorHandler } from "../../utils/HelperFunction";
import { errorHandler } from "../../../utils/HelperFunction";

export const getTimeSlots = (productId, appointmentId, calendarId, date) => {
    let studentId = window.sessionStorage.getItem("studentId");

    return async (dispatch) => {
        try {
            dispatch({ type: Schedule.obLoader });
            await Axios.get(
                QPMC_URL.timeSlots(
                    studentId,
                    productId,
                    appointmentId,
                    calendarId,
                    date
                )
            ).then((result) => {
                dispatch({
                    type: Schedule.getTimeSlots,
                    payload: { ...result.data, success: true },
                    loading: false,
                });
            });
        } catch (error) {
            dispatch(errorHandler(Schedule.getTimeSlots, error, false));
        }
    };
};

export const getCallDetails = (productId, stageName) => {
    let studentId = window.sessionStorage.getItem("studentId");

    return async (dispatch) => {
        try {
            dispatch({ type: Schedule.obLoader });
            await Axios.get(QPMC_URL.callDetails(studentId, productId, stageName)).then(
                (result) => {
                    dispatch({
                        type: Schedule.getCallDetails,
                        payload: { ...result.data, success: true },
                        loading: false,
                    });
                }
            );
        } catch (error) {
            dispatch(errorHandler(Schedule.getCallDetails, error, false));
        }
    };
};

export const confirmCallSchedule = (productId, data) => {
    let studentId = window.sessionStorage.getItem("studentId");

    return async (dispatch) => {
        try {
            dispatch({ type: Schedule.obLoader });
            await Axios.post(
                QPMC_URL.callConfirm(studentId, productId),
                data
            ).then((result) => {
                dispatch({
                    type: Schedule.confirmCallSchedule,
                    payload: { ...result.data, success: true },
                    loading: false,
                });
            });
        } catch (error) {
            dispatch(errorHandler(Schedule.confirmCallSchedule, error, false));
        }
    };
};

export const rescheduleCall = (appointmentId, data) => {
    let studentId = window.sessionStorage.getItem("studentId");

    return async (dispatch) => {
        try {
            dispatch({ type: Schedule.obLoader });
            await Axios.post(
                QPMC_URL.rescheduleCall(appointmentId, studentId),
                data
            ).then((result) => {
                dispatch({
                    type: Schedule.rescheduleCall,
                    payload: { ...result.data, success: true },
                    loading: false,
                });
            });
        } catch (error) {
            dispatch(errorHandler(Schedule.rescheduleCall, error, false));
        }
    };
};

export const getVariantStepsById = (productId) => {
    let studentId = window.sessionStorage.getItem("studentId");

    return async (dispatch) => {
        try {
            dispatch({ type: Schedule.obLoader });
            await Axios.get(
                QPMC_URL.getVariantSteps(studentId, productId)
            ).then((result) => {
                dispatch({
                    type: Schedule.getVariantStepsById,
                    payload: { ...result.data, success: true },
                    loading: false,
                });
            });
        } catch (error) {
            dispatch(errorHandler(Schedule.getVariantStepsById, error, false));
        }
    };
};
