import { OnBoarding } from "./ActionType";

const INITIAL_STATE = {
  timeSlotsList: null,
  getVariantSteps: null,
  isLoading: false,
  callDetails: null,
  confirmedCallStatus: null,
  rescheduledCall: null,
  getUserGuide: null,
};

const OnBoardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OnBoarding.obLoader:
      return {
        ...state,
        isLoading: true,
      };
    case OnBoarding.getTimeSlots:
      return {
        ...state,
        timeSlotsList: action.payload,
        isLoading: action.loading,
      };
    case OnBoarding.getVariantStepsById:
      return {
        ...state,
        getVariantSteps: action.payload,
        isLoading: action.loading,
      };
    case OnBoarding.getCallDetails:
      return {
        ...state,
        callDetails: action.payload,
        isLoading: action.loading,
      };
    case OnBoarding.confirmCallSchedule:
      return {
        ...state,
        confirmedCallStatus: action.payload,
        isLoading: action.loading,
      };
    case OnBoarding.rescheduleCall:
      return {
        ...state,
        rescheduledCall: action.payload,
        isLoading: action.loading,
      };
    case OnBoarding.getUserGuide:
      return {
        ...state,
        getUserGuide: action.payload,
        isLoading: action.loading,
      };
    default:
      return state;
  }
};

export default OnBoardingReducer;
