import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import TextBox from "./TextBox";

/**
 * Return a format value
 * @param {String} value
 * @returns String
 */
const FORMAT_VALUE = (value) => ({
  date: value && moment(new Date(value)).format("YYYY-MM-DD"),
  month: value && moment(new Date(value)).format("YYYY-MM"),
  year: value && moment(new Date(value)).format("YYYY"),
});

/**
 * Date Picker
 * @param {String} label
 * @param {String} name
 * @param {String} type
 * @param {String} value
 * @param {Array} views
 * @param {Function} onChange
 * @returns Element
 */
function DatePicker({
  label,
  name,
  id,
  type,
  value,
  views,
  onChange,
  onClose,
  required,
  placeholder,
  className,
  helperText,
  error,
  maxDate,
  minDate,
  fullWidth,
  disableFuture,
  disablePast,
  disabled,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        label={label}
        value={error && !value ? "" : value ? new Date(value) : null}
        views={views}
        maxDate={maxDate ? new Date(maxDate) : maxDate}
        minDate={minDate ? new Date(minDate) : minDate}
        onChange={(val) =>
          onChange({
            target: {
              value: FORMAT_VALUE(val)[type],
              name: name,
            },
          })
        }
        renderInput={(params) => (
          <TextBox
            id={id}
            InputLabelProps={{ shrink: Boolean(value) }}
            helperText={helperText}
            placeholder={placeholder}
            error={error}
            required={required}
            fullWidth={fullWidth}
            {...params}
          />
        )}
        className={className}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        showToolbar={false}
        onClose={onClose}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
