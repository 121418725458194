export const ROOT_PATH = "";
export const ROOT_LOGIN_PATH = ROOT_PATH.concat("/login");

export const PAGE_NAME = {
  termsAndCon: "terms-and-con",
  obCall: "ob-call",
  onboarding: "onboarding",
  personalInfo: "personal-info",
  academicInfo: "academic",
  gat: "graduate-admission-test",
  prePgaCall: "pre-pga-call",
  pgaCall: "pga-call",
  pgaReport: "pga-report",
  planOfAction: "plan-of-action",
  workExperience: "work-experience",
  aspiration: "aspiration",
  cvReview: "cv-review",
  testAndSurvey: "test-and-survey",
  uploadcv: "upload-cv",
  callSchedule: "pre_strategy_call",
  uploadDocument: "pre_strategy",
  applicationStage: "upload-documents",
  profileMentoring: "qpmc1",
  qpmc2: "qpmc2",
  schoolResearch: "Worksheets",
  strategySessionCall: "strategy_session_call",
  strategyDocument: "strategy_session",
  upload: "upload",
  uploadSchool: "uploadSchool",
  documents: "documents",
  feedBackCall: "feedback_session",
  visaInterview: "visa_interview_call",
  visaInterview2: "interview_call",
  resources: "resources",
  documents: "documents",
  webinar: "webinar",
  learningJourney: "learning-journey",
};

export const NAVIGATE_TO = {
  productPath: (productId) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}`),
  learningJourneyPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.learningJourney}`
    ),
  reviewPath: (productId) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}/cvReview`),
  dashboardPath: () => ROOT_PATH.concat(`/dashboard`),
  academicPath: (productId, stageName, type) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/academic/${type}`
    ),
  stagePath: (productId, stageName) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}/stage/${stageName}`),
  mainContentPath: (productId, stageName, stepName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${stepName}`
    ),
  planOfActionAutoNavPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/plan-of-action/plan-of-action`
    ),

  pgaCallAutoNavPath: (productId, stageName) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}/stage/pga-call/pga-call`),

  qpmc1AutoNavPath: (productId) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}/stage/qpmc1/qpmc1`),
  prestrategycallAutoNav: (productId) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/pre_strategy_call/pre_strategy_call`
    ),

  termsAndConditionsPathForAutoNav: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${stageName}`
    ),
  pgaReportPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${stageName}`
    ),
  termsAndConPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.termsAndCon}`
    ),
  obCallPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.obCall}`
    ),
  personalInfoPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.personalInfo}`
    ),
  clickpersonalinfoPath: (productId) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/onboarding/personal-info`
    ),
  /**test and survey  */
  testAndSurveyPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.testAndSurvey}`
    ),
  /**pga client  */
  prePgaCallPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.prePgaCall}`
    ),
  pgaCallPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/pga-call/${PAGE_NAME.pgaCall}`
    ),
  pgaReportPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.pgaReport}`
    ),
  planOfActionPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.planOfAction}`
    ),

  cvReviewPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.cvReview}`
    ),
  profileMentoringPath: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.profileMentoring}`
    ),
  profileMentoringSectionPath: (productId, stageName, sectionId) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.profileMentoring}/${sectionId}`
    ),
  profileMentoringUploadDocumentSectionPath: (
    productId,
    stageName,
    sectionName,
    sectionId
  ) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.schoolResearch}/${sectionName}/${sectionId}`,
  qpmc2Path: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.qpmc2}`
    ),
  qpmc2SectionPath: (productId, stageName, sectionId) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.qpmc2}/${sectionId}`
    ),
  cvReviewListPath: (productId, stageName, cvName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.cvReview}/${cvName}`
    ),

  testAndSurveySectionPath: (productId, stageName, sectionId) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.testAndSurvey}/${sectionId}`
    ),

  deadlineTrackerPath: (productId) =>
    ROOT_PATH.concat(`/dashboard/product/${productId}/deadline-tracker`),
  gatLandingPath: (productId, stageName, gatType) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.gat}/${gatType}`,
  gatAddPath: (productId, stageName, gatType) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.gat}/${gatType}/add`,
  bShoolFilterPath: (productId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/b-school-filter`,
  bShoolFilterContentPath: (productId, listName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/b-school-filter/${listName}`,
  strategyUploadDocumentPath: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadDocument}`,
  strategyUploadDocumentSectionPath: (
    productId,
    stageName,
    sectionName,
    sectionId
  ) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadDocument}/${sectionName}/${sectionId}`,

  strategyUploadDocumentTestTranscriptsLandingPath: (
    productId,
    stageName,
    sectionName,
    sectionId,
    testType
  ) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadDocument}/${sectionName}/${sectionId}/${testType}`,
  strategyUploadDocumentTestTranscriptsAddPath: (
    productId,
    stageName,
    sectionName,
    sectionId,
    testType
  ) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadDocument}/${sectionName}/${sectionId}/${testType}/add`,
  strategySessionCallSchedule: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.callSchedule}`,
  strategySessionCallSchedulePath: (
    productId,
    stageName,
    sectionName,
    sectionId
  ) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.callSchedule}/${sectionName}/${sectionId}`,
  strategySessionCall: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.strategySessionCall}`,
  strategySessionCallPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.strategySessionCall}/${sectionName}/${sectionId}`,
  strategyDocument: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.strategyDocument}`,
  strategyDocumentPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.strategyDocument}/${sectionName}/${sectionId}`,
  applicationStagePath: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.applicationStage}`,
  applicationSectionPath: (productId, stageName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.applicationStage}/${sectionId}`,
  feedBackSessionCall: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.feedBackCall}`,
  feedBackSessionCallPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.feedBackCall}/${sectionName}/${sectionId}`,
  visaInterviewCall: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.visaInterview}`,
  visaInterviewCallPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.visaInterview}/${sectionName}/${sectionId}`,
  visaInterviewCall2: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.visaInterview2}`,
  visaInterviewCall2Path: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.visaInterview2}/${sectionName}/${sectionId}`,
  resource: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.resources}`,
  resourcePath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.resources}/${sectionName}/${sectionId}`,
  postAdmitSelectorUploadPath: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.upload}`,
  postAdmitSelectorUploadSectionPath: (productId, stageName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.upload}/${sectionId}`,

  uploadAdmitCard: (productId, stageName) =>
    ROOT_PATH.concat(
      `/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadSchool}`
    ),
  uploadAdmitCardPath: (productId, stageName, schoolId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.uploadSchool}/${schoolId}`,
  documents: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.documents}`,
  documentsPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.documents}/${sectionId}`,
  webinar: (productId, stageName) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.webinar}`,
  webinarPath: (productId, stageName, sectionName, sectionId) =>
    `${ROOT_PATH}/dashboard/product/${productId}/stage/${stageName}/${PAGE_NAME.webinar}/${sectionId}`,
};

export const PATH = {
  // LOGIN PATH
  rootLoginPath: ROOT_LOGIN_PATH,
  loginPagePath: ROOT_LOGIN_PATH.concat("/user"),
  forgotPassPath: ROOT_LOGIN_PATH.concat("/forgotPass"),
  troublePath: ROOT_LOGIN_PATH.concat("/trouble"),
  resetSuccessPath: ROOT_LOGIN_PATH.concat("/resetSuccess"),
  resetPassPath: ROOT_LOGIN_PATH.concat("/resetPass/:id"),
  mailSentPath: ROOT_LOGIN_PATH.concat("/mailSent"),

  dashboardPath: ROOT_PATH.concat("/dashboard"),
  productPath: ROOT_PATH.concat("/dashboard/product/:productId"),
  reviewPath: ROOT_PATH.concat("/dashboard/product/:productId/cvReview"),

  stagePath: ROOT_PATH.concat("/dashboard/product/:productId/stage/:stageName"),
  termsAndConPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.termsAndCon
  ),
  obCallPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.obCall
  ),
  personalInfoPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.personalInfo
  ),

  academicPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.academicInfo
  ),
  learningJourneyPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" +
      PAGE_NAME.learningJourney
  ),
  /* graduate admission default landing route path */
  gatPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.gat
  ),

  /* graduate admission landing route path */
  gatLandingPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.gat}/:gatType`,
  /* graduate admission add test route path */
  gatAddPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.gat}/:gatType/add`,

  testAndSurveyPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.testAndSurvey
  ),
  tenthPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/academic/tenth"
  ),
  twelfthPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/academic/twelfth"
  ),
  diplomaPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/academic/diploma"
  ),
  ugPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/academic/ug"
  ),
  pgPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/academic/pg"
  ),
  gatPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/graduate-admission-test"
  ),
  aspirationPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/aspiration"
  ),

  // Deadline Tracker Path
  deadlineTrackerPath: `${ROOT_PATH}/dashboard/product/:productId/deadline-tracker`,

  /**pga client */
  prePgaCallPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.prePgaCall
  ),
  pgaCallPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.pgaCall
  ),
  pgaReportPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.pgaReport
  ),
  planOfActionPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.planOfAction
  ),
  cvReviewPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.cvReview
  ),
  cvReviewListPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" +
      PAGE_NAME.cvReview +
      "/:cvName"
  ),
  testAndSurveySectionPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" +
      PAGE_NAME.testAndSurvey +
      "/:sectionId"
  ),

  // Upload CV
  uploadCVPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.uploadcv
  ),

  //Course Path
  coursePath: `${ROOT_PATH}/dashboard/course`,

  // Internship Path
  internshipPath: `${ROOT_PATH}/dashboard/internship`,

  // Work experience path
  workExperiencePath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/work-experience`,

  // BShool Filter Path
  bShoolFilterPath: `${ROOT_PATH}/dashboard/product/:productId/b-school-filter`,
  bShoolFilterContentPath: `${ROOT_PATH}/dashboard/product/:productId/b-school-filter/:listName`,

  //profile mentoring
  profileMentoringPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/profile-mentoring"
  ),
  qpmc2Path: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/qpmc2"
  ),
  schoolResearchPath: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/school-research"
  ),
  profileMentoringUploadDocumentSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.schoolResearch}/:sectionName/:sectionId`,

  // Strategy Session Path
  strategyUploadDocumentPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.uploadDocument}`,
  strategyUploadDocumentSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.uploadDocument}/:sectionName/:sectionId`,
  strategySessionCallSchedule: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.callSchedule}`,
  strategySessionCallSchedulePath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.callSchedule}/:sectionName/:sectionId`,
  strategySessionCall: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.strategySessionCall}`,
  strategySessionCallPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.strategySessionCall}/:sectionName/:sectionId`,
  strategyDocument: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.strategyDocument}`,
  strategyDocumentPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.strategyDocument}/:sectionName/:sectionId`,
  strategyUploadDocumentTestTranscriptsLandingPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.uploadDocument}/:sectionName/:sectionId/:testType`,
  strategyUploadDocumentTestTranscriptsAddPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.uploadDocument}/:sectionName/:sectionId/:testType/add`,

  // Application Stage Path
  applicationStagePath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.applicationStage}`,
  applicationSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.applicationStage}/:sectionId`,

  // Profile Mentoring Path
  profileMentoringPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.profileMentoring}`,
  profileMentoringSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.profileMentoring}/:sectionId`,
  qpmc2Path: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.qpmc2}`,
  qpmc2SectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.qpmc2}/:sectionId`,
  schoolResearchPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.schoolResearch}`,
  schoolResearchSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.schoolResearch}/:sectionId`,
  feedBackSessionCall: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.feedBackCall}`,
  feedBackSessionCallPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.feedBackCall}/:sectionName/:sectionId`,
  visaInterviewCall: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.visaInterview}`,
  visaInterviewCallPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.visaInterview}/:sectionName/:sectionId`,
  visaInterviewCall2: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.visaInterview2}`,
  visaInterviewCall2Path: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.visaInterview2}/:sectionName/:sectionId`,
  resource: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.resources}`,
  resourcePath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.resources}/:sectionName/:sectionId`,
  documents: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.documents}`,
  documentsPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.documents}/:sectionId`,
  webinar: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.webinar}`,
  webinarPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.webinar}/:sectionId`,

  // Post Admit Services
  postAdmitSelectorUploadPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.upload}`,
  postAdmitSelectorUploadSectionPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.upload}/:sectionId`,
  uploadAdmitCard: ROOT_PATH.concat(
    "/dashboard/product/:productId/stage/:stageName/" + PAGE_NAME.uploadSchool
  ),
  uploadAdmitCardPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.uploadSchool}/:schoolId`,
  documents: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.documents}`,
  documentsPath: `${ROOT_PATH}/dashboard/product/:productId/stage/:stageName/${PAGE_NAME.documents}/:sectionId`,
};
