import { B_SCHOOL_FILTER_TOOL } from "./ActionType";

const initialState = {
  bSchool: null,
  countries: null,
  compare: null,
  compareStatus: null,
  personalized: null,
  personalizedStatus: null,
  bSchoolCompare: null,
  bSchoolType: null,
  rank: null,
  rankType: null,
  compareBSchoolStatus: null,
  totalTuitionFees: null,
  headerText: null,
  personalizedDownloadResponse: null,
  deleteCompareStatus: null,
  deleteAllCompareStatus: null,
  deletePersonalizedStatus: null,
  deleteAllPersonalizedStatus: null,
  isLoading: false,
};

const BSchoolFilterToolReducer = (state = initialState, action) => {
  switch (action.type) {
    case B_SCHOOL_FILTER_TOOL.loader:
      return {
        ...state,
        isLoading: true,
      };
    case B_SCHOOL_FILTER_TOOL.searchBSchool:
      return {
        ...state,
        bSchool: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getCountries:
      return {
        ...state,
        countries: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getCompare:
      return {
        ...state,
        compare: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.createAndUpdateCompare:
      return {
        ...state,
        compareStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getPersonalized:
      return {
        ...state,
        personalized: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.createAndUpdatePersonalized:
      return {
        ...state,
        personalizedStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.searchBSchoolCompare:
      return {
        ...state,
        bSchoolCompare: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getType:
      return {
        ...state,
        bSchoolType: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getBSchoolByRank:
      return {
        ...state,
        rank: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getRankType:
      return {
        ...state,
        rankType: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.deleteAllStudentBSchoolCompare:
      return {
        ...state,
        deleteAllCompareStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.deleteStudentBSchoolCompare:
      return {
        ...state,
        deleteCompareStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.deleteAllStudentBSchoolPersonalized:
      return {
        ...state,
        deleteAllPersonalizedStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.deleteStudentBSchoolPersonalized:
      return {
        ...state,
        deletePersonalizedStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.compareBSchool:
      return {
        ...state,
        compareBSchoolStatus: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getTotalTuitionFees:
      return {
        ...state,
        totalTuitionFees: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.getHeaderText:
      return {
        ...state,
        headerText: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.downloadPersonalizedList:
      return {
        ...state,
        personalizedDownloadResponse: action.payload,
        isLoading: action.loading,
      };
    case B_SCHOOL_FILTER_TOOL.clearData:
      return {};
    case B_SCHOOL_FILTER_TOOL.clearCustomData:
      return {
        ...state,
        [action.fieldName]: null,
      };
    default:
      break;
  }
  return state;
};

export default BSchoolFilterToolReducer;
