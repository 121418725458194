import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  imageStyle: {
    height: "25px",
    width: "25px",
  },
  documentGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  documentDiv: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
  },
  cardBorder: {
    border: "1px solid #E7E7E7",
  },
  semImageGridStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
  semImageStyle: {
    height: "60px",
    width: "60px",
  },
  formStyle: {
    paddingLeft: "1.5%",
    paddingTop: "1%",
  },
  semMainCard: {
    padding: "5px",
  },
  documentNameDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  semBackGround: {
    width: "60px",
    height: "60px",
    background: "rgba(245, 222, 65, 0.2)",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIconStyle: {
    height: "26px",
  },
  formMaindiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
  },
  formDiv: {
    display: "flex",
    flexGrow: 1,
    overflowY: "scroll",
  },
  bottomDiv: {
    position: "absolute",
    bottom: "0%",
  },
  verificationStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "-10%",
  },
  tabMainDiv: {
    display: "flex",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexWrap: "nowrap",
  },
  outletDiv: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
  },
  dateStyle: {
    width: "100%",
  },
  pointerEvents: {
    pointerEvents: "none",
  },
}));
