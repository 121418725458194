import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import AcademicMarksCard from "../../components/AcademicMarksCard";
import BottomContainer from "../../components/BottomContainer";
import CustomAdd from "../../components/CustomAdd";
import SnackBar from "../../components/CustomizedSnackBar";
import DropDown from "../../components/DropDown";
import Popup from "../../components/Popup";
import TextBox from "../../components/TextBox";
import {
  getStudentSectionByStageName,
  getStudentProductStages,
} from "../../redux/student/Action";
import {
  clearAcademicDetails,
  deleteSemesterDetails,
  getAcademicDetails,
  getAcademicStatus,
  getcollegeDetails,
  getdegreeDetails,
  getdepartmentDetails,
  getuniversityDetails,
  updateAcademicDetails,
  updateSemesterDetails,
  uploadSemesterFile,
} from "../../redux/academic/Action";
import { Typo } from "../../styled/Index";
import { academicList, cgpaList } from "../../utils/Data";
import AcademicPopup from "./AcademicPopup";
import { createFilterOptions } from "@mui/material/Autocomplete";
import actions from "../../CleverTab/Events";
import { useStyles } from "./Styles";
import {
  FILE_FORMAT_ERROR,
  FILE_SIZE_ERROR,
  REQUIRED_ERROR,
} from "../../utils/Constants";
import CreatableDropdown from "../../components/CreatableDropdown";

import { isOnboardingCompleted } from "../../utils/StageStepStatus";
import moment from "moment";
import { isNumber } from "../../utils/HelperFunction";
import DatePicker from "../../components/DatePicker";

export function HigherStudiesForm() {
  const classes = useStyles();
  const props = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [hasFile, setHasFile] = useState(false);
  const [finalFile, setFinalFile] = useState(null);
  const [fileErr, setFileErr] = useState("");
  const [open, setOpen] = useState(false);
  const [semList, setSemList] = useState([]);
  const [getAcademic, setAcademic] = useState(null);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState(true);

  const [state, setState] = useState({
    id: null,
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    studentDocument: [],
    error: false,
    buttonDisabled: false,
  });
  const requiredSchema = (conditions, value) => {
    return value ? conditions.required(REQUIRED_ERROR) : value;
  };

  const validationSchema = yup.object({
    cgpaScale: requiredSchema(yup.object().nullable(), !flag),
    cgpa: requiredSchema(yup.string("Enter CGPA"), !flag),
    degree: requiredSchema(yup.object().nullable(), !flag),
    department: requiredSchema(yup.object().nullable(), !flag),
    university: requiredSchema(yup.object().nullable(), !flag),
    college: requiredSchema(yup.object().nullable(), !flag),
    semacademic: requiredSchema(yup.object().nullable(), flag),
    clearedBacklog: requiredSchema(yup.string(), flag),
    activeBacklog: requiredSchema(yup.string(), flag),
    semcgpaScale: requiredSchema(yup.object().nullable(), flag),
    semacademic: requiredSchema(yup.object().nullable(), flag),
    semcgpa: requiredSchema(yup.string("Enter CGPA"), flag),
    startDate: requiredSchema(yup.string(""), !flag),
    endDate: requiredSchema(yup.string(""), !flag),
  });

  const handleSave = () => {
    if (open) {
      if (finalFile === null) {
        setFileErr("Please Upload MarkSheet");
      } else {
        setFileErr("");
      }
      let reqBody = {
        semester: formik.values.semacademic.title,
        activeBacklogs: formik.values.activeBacklog,
        clearedBacklogs: formik.values.clearedBacklog,
        sgpa: parseInt(formik.values.semcgpa),
        scoreScale: formik.values.semcgpaScale.title,
      };
      if (finalFile) {
        if (values.semcgpa === ".") {
          setState({
            ...state,
            snackOpen: true,
            snackMsg: "Enter a valid CGPA value",
            snackVariant: "error",
          });
        } else {
          dispatch(updateSemesterDetails("ug", reqBody));

          actions.PushEvent("UP: User Saved UG Details", {
            "Date": new Date()
          });
          console.log(formik.values.college.name, formik.values.department.name)
          actions.UpdateProfile({
            "Site": {
              "College Name": formik.values.college.name,
              "Department": formik.values.department.name
            }
          });


          // dispatch(getAcademicDetails("ug"));
          setOpen(false);
        }
      }
    } else {
      let reqBody = {
        college: {
          name: formik.values.college.name,
        },
        university: {
          name: formik.values.university.name,
        },
        department: {
          name: formik.values.department.name,
        },
        degree: {
          name: formik.values.degree.name,
        },
        scoreScale: formik.values.cgpaScale.title,
        score: formik.values.cgpa,
        strStartDate: formik.values.startDate,
        strEndDate: formik.values.endDate,
      };
      if (formik.values.startDate === formik.values.endDate) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: "End Date should be greater than Start Date",
          snackVariant: "error",
        });
      } else if (values.cgpa === ".") {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: "Enter a valid CGPA value",
          snackVariant: "error",
        });
      } else {
        let obj =
          getAcademic && getAcademic.data.id
            ? { ...reqBody, id: getAcademic.data.id }
            : reqBody;
        dispatch(updateAcademicDetails("ug", obj));
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      activeBacklog: "",
      clearedBacklog: "",
      semcgpaScale: null,
      semcgpa: "",
      semacademic: null,
      startDate: "",
      endDate: "",
      cgpaScale: null,
      cgpa: "",
      department: null,
      university: null,
      college: null,
      degree: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const {
    getdegree,
    getdepartment,
    getcollege,
    getuniversity,
    getDetails,
    updateSemdetails,
    updateDetails,
    deleteSemDetails,
    uploadSemFile,
  } = useSelector((state) => state.AcademicReducer);
  useEffect(() => {
    actions.PushEvent("UP: User Opened UG Details Card", {
      "Date": new Date()
    });
  }, [])

  useEffect(() => {
    var data =
      getAcademic &&
      getAcademic.data &&
      getAcademic.data.semesterDetails.filter(
        (item) =>
          item.semester ===
          parseInt(formik.values.semacademic && formik.values.semacademic.title)
      );

    if (data && data.length > 0) {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Semester Already Exist",
        snackVariant: "error",
        error: true,
        buttonDisabled: true,
      });
    } else {
      setState({
        ...state,
        snackOpen: false,
        snackMsg: "",
        snackVariant: "",
        error: false,
        buttonDisabled: false,
      });
    }
  }, [formik.values.semacademic]);

  useEffect(() => {
    if (updateSemdetails) {
      if (updateSemdetails.success === false) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: updateSemdetails.message,
          snackVariant: "error",
        });
      } else {
        if (getAcademic && getAcademic.status === 200) {
          const file = new FormData();
          file.append("file", finalFile);

          dispatch(
            uploadSemesterFile(
              "ugDegree",
              getAcademic.data.id,
              updateSemdetails.data.data.semester,
              file
            )
          );
        }
      }
    }
    dispatch(getAcademicStatus());
    dispatch(getAcademicDetails("ug"));
  }, [updateSemdetails]);
  useEffect(() => {
    dispatch(getAcademicStatus());
    dispatch(getAcademicDetails("ug"));
  }, [uploadSemFile]);

  useEffect(() => {
    if (deleteSemDetails) {
      if (deleteSemDetails.status === 200) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: deleteSemDetails.data.message,
          snackVariant: "success",
        });
        setTimeout(() => {
          dispatch(getAcademicDetails("ug"));
        }, 3000);
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: deleteSemDetails.message,
          snackVariant: "error",
        });
      }
    }
    dispatch(getAcademicStatus());
    dispatch(getAcademicDetails("ug"));
  }, [deleteSemDetails]);

  useEffect(() => {
    if (
      state.studentDocument &&
      state.studentDocument.length > 0 &&
      state.studentDocument[0].type === "application/pdf"
    ) {
      if (state.studentDocument[0].size < 1000000) {
        setHasFile(true);
        setFileErr("");

        var stuBasicData = JSON.parse(
          window.sessionStorage.getItem("studentBasicData")
        );
        var name =
          stuBasicData.first_name + "_" + stuBasicData.last_name + "_" + "ug";
        var file = state.studentDocument[0];

        var indexOf = file.type.indexOf("/");
        var newFileType = file.type.substr(indexOf + 1);

        var blob = new Blob([file], { type: newFileType });

        var newFile = new File(
          [blob],
          name
            .concat(".", newFileType)
            .replace(
              "vnd.openxmlformats-officedocument.wordprocessingml.document",
              "docx"
            ),
          { type: newFileType }
        );
        setFinalFile(newFile);
      } else {
        setFileErr(FILE_SIZE_ERROR);
      }
    } else if (
      typeof state.studentDocument === "undefined" ||
      state.studentDocument.length === 0
    ) {
      setFileErr("");
    } else {
      setFileErr(FILE_FORMAT_ERROR);
    }
  }, [state.studentDocument]);

  useEffect(() => {
    if (updateDetails) {
      if (updateDetails.status === 200) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: "Updated SuccessFully",
          snackVariant: "success",
        });
        // dispatch(getAcademicDetails("ug"));
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: updateDetails.message,
          snackVariant: "error",
        });
      }
    }
    dispatch(getAcademicDetails("ug"));
    dispatch(getAcademicStatus());
    dispatch(clearAcademicDetails());
  }, [updateDetails]);
  const onDrop = (file) => {
    setState({ ...state, studentDocument: file });
  };

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    handleBlur,
  } = formik;

  useEffect(() => {
    setState({
      ...state,
      snackOpen: false,
      snackMsg: "",
      snackVariant: "",
    });
    dispatch(getdegreeDetails("ug"));
    dispatch(getdepartmentDetails());
    dispatch(getuniversityDetails());
    dispatch(getcollegeDetails());
    // dispatch(getAcademicDetails("ug"));
    dispatch(getStudentSectionByStageName(props.productId, props.stageName));
  }, []);
  useEffect(() => {
    setFlag(open);
    formik.setTouched({});
  }, [open]);
  useEffect(() => {
    if (getDetails) {
      if (getDetails.status === 200) {
        getDetails.data ? setData(true) : setData(false);
        setAcademic(getDetails);
        formik.setValues({
          ...formik.values,
          college: getDetails?.data.college,
          university: getDetails?.data.university,
          department: getDetails?.data.department,
          degree: getDetails?.data.degree,
          cgpaScale: getDetails?.data.scoreScale
            ? { title: getDetails?.data.scoreScale }
            : null,
          cgpa: getDetails?.data.score,
          endDate: getDetails?.data.endDate,
          startDate: getDetails?.data.startDate,
        });
        setSemList(getDetails?.data.semesterDetails);
      } else {
        setData(true);
        setState({
          ...state,
          snackOpen: true,
          snackMsg: "Network Failed",
          snackVariant: "error",
        });
      }
    } else {
      setData(true);
    }
  }, [getDetails]);
  const handleAddClick = () => {
    // if (
    //   formik.values.college === null ||
    //   formik.values.department === null ||
    //   formik.values.university === null ||
    //   formik.values.cgpa === "" ||
    //   formik.values.cgpaScale === null
    // ) {
    //   formik.setValues({
    //     activeBacklog: "",
    //     clearedBacklog: "",
    //     semcgpaScale: null,
    //     semcgpa: "",
    //     semacademic: null,
    //     startDate: "",
    //     endDate: "",
    //   });
    //   setOpen(false);
    //   setFinalFile(null);
    // } else {
    formik.setValues({
      ...formik.values,
      activeBacklog: "",
      clearedBacklog: "",
      semcgpaScale: null,
      semcgpa: "",
      semacademic: null,
      startDate: "",
      endDate: "",
    });
    setOpen(true);
    setFinalFile(null);
    // }
  };
  const handleDeleteClick = () => {
    setFinalFile(null);
  };

  const handleDeleteIconClick = (item) => {
    dispatch(
      deleteSemesterDetails(
        item.id,
        item.studentDocument && item.studentDocument.id
      )
    );
    dispatch(getAcademicDetails("ug"));
    dispatch(getAcademicStatus());
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackMsg: "", snackVariant: "" });
  };
  const handleCancel = () => {
    dispatch(getAcademicDetails("ug"));
    dispatch(getAcademicStatus());
  };
  const handlesgpaChange = (e) => {
    if (formik.values.semcgpaScale) {
      if (
        parseInt(formik.values.semcgpaScale.title) < parseInt(e.target.value) ||
        parseInt(e.target.value) < 0 ||
        parseFloat(parseInt(formik.values.semcgpaScale.title).toFixed(3)) <
        parseFloat(e.target.value).toFixed(3)
      ) {
        e.preventDefault();
      } else {
        formik.setValues({
          ...formik.values,
          semcgpa: e.target.value,
        });
      }
    }
  };
  const handleCgpaChange = (e) => {
    if (formik.values.cgpaScale) {
      if (
        parseInt(formik.values.cgpaScale.title) < parseInt(e.target.value) ||
        parseInt(e.target.value) < 0 ||
        parseFloat(parseInt(formik.values.cgpaScale.title).toFixed(3)) <
        parseFloat(e.target.value).toFixed(3)
      ) {
        e.preventDefault();
      } else {
        formik.setValues({
          ...formik.values,
          cgpa: e.target.value,
        });
      }
    }
  };
  useEffect(() => {
    if (
      parseInt(values.semcgpaScale?.title) < parseInt(values.semcgpa) ||
      parseFloat(parseInt(values.semcgpaScale?.title).toFixed(3)) <
      parseFloat(values.semcgpa).toFixed(3)
    ) {
      formik.setValues({
        ...formik.values,
        semcgpa: "",
      });
    } else if (
      values.semcgpaScale === null ||
      values.semcgpaScale === "" ||
      values.semcgpaScale?.title === ""
    ) {
      formik.setValues({
        ...formik.values,
        semcgpa: "",
      });
    }
  }, [formik.values.semcgpaScale]);
  useEffect(() => {
    if (
      parseInt(values.cgpaScale?.title) < parseInt(values.cgpa) ||
      parseFloat(parseInt(values.cgpaScale?.title).toFixed(3)) <
      parseFloat(values.cgpa).toFixed(3)
    ) {
      formik.setValues({
        ...formik.values,
        cgpa: "",
      });
    } else if (
      values.cgpa === null ||
      values.cgpa === "" ||
      values.cgpa?.title === ""
    ) {
      formik.setValues({
        ...formik.values,
        cgpa: "",
      });
    }
  }, [formik.values.cgpaScale]);

  const filter = createFilterOptions();

  const renderPointerEventsClass = () => {
    return isOnboardingCompleted() ? classes.pointerEvents : "";
  };

  useEffect(() => {
    if (
      moment(new Date(formik.values.startDate)).format("YYYY MM") >
      moment(new Date(formik.values.endDate)).format("YYYY MM")
    ) {
      formik.setValues({
        ...values,
        endDate: "",
      });
    }
  }, [formik.values.startDate]);

  return (
    <div className={classes.formMaindiv}>
      <div className={classes.formDiv}>
        <form onSubmit={handleSubmit} className={classes.formStyle}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <div className={renderPointerEventsClass()}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <CreatableDropdown
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      required
                      options={getcollege || []}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      value={values.college}
                      onBlur={handleBlur}
                      // onChange={(e, value) => setFieldValue("college", value)}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setFieldValue("college", { name: newValue });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setFieldValue("college", {
                            name: newValue.inputValue,
                          });
                        } else {
                          setFieldValue("college", newValue);
                        }
                      }}
                      label="College"
                      name="college"
                      variant="standard"
                      error={touched.college && errors.college}
                      helperText={touched.college && errors.college}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <CreatableDropdown
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      required
                      // options={getcollege || []}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setFieldValue("university", { name: newValue });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setFieldValue("university", {
                            name: newValue.inputValue,
                          });
                        } else {
                          setFieldValue("university", newValue);
                        }
                      }}
                      options={getuniversity || []}
                      value={values.university}
                      onBlur={handleBlur}
                      label="University"
                      name="university"
                      variant="standard"
                      error={touched.university && errors.university}
                      helperText={touched.university && errors.university}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <CreatableDropdown
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      // onChange={(e, value) => setFieldValue("college", value)}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setFieldValue("department", { name: newValue });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setFieldValue("department", {
                            name: newValue.inputValue,
                          });
                        } else {
                          setFieldValue("department", newValue);
                        }
                      }}
                      required
                      options={(getdepartment && getdepartment.data) || []}
                      // getOptionLabel={(option) => option.name}
                      value={values.department}
                      onBlur={handleBlur}
                      // onChange={(e, value) => setFieldValue("department", value)}
                      label="Department"
                      name="department"
                      variant="standard"
                      error={touched.department && errors.department}
                      helperText={touched.department && errors.department}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <CreatableDropdown
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      // onChange={(e, value) => setFieldValue("college", value)}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setFieldValue("degree", { name: newValue });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setFieldValue("degree", {
                            name: newValue.inputValue,
                          });
                        } else {
                          setFieldValue("degree", newValue);
                        }
                      }}
                      required
                      options={getdegree || []}
                      // getOptionLabel={(option) => option.name}
                      value={values.degree}
                      onBlur={handleBlur}
                      // onChange={(e, value) => setFieldValue("degree", value)}
                      label="Degree"
                      name="degree"
                      variant="standard"
                      error={touched.degree && errors.degree}
                      helperText={touched.degree && errors.degree}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DropDown
                      required
                      options={cgpaList}
                      getOptionLabel={(option) => option.title}
                      value={values.cgpaScale}
                      onBlur={handleBlur}
                      onChange={(e, value) => setFieldValue("cgpaScale", value)}
                      label="CGPA Scale"
                      name="cgpaScale"
                      variant="standard"
                      error={touched.cgpaScale && errors.cgpaScale}
                      helperText={touched.cgpaScale && errors.cgpaScale}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextBox
                      required
                      fullWidth
                      id="cgpa"
                      name="cgpa"
                      label="CGPA"
                      value={values.cgpa}
                      disabled={
                        values.cgpaScale === null || values.cgpaScale === ""
                      }
                      onChange={(e) => handleCgpaChange(e)}
                      onKeyPress={(evt) => {
                        if (isNumber(evt) || evt.target.value?.length >= 5)
                          evt.preventDefault();
                      }}
                      error={touched.cgpa && errors.cgpa}
                      helperText={touched.cgpa && errors.cgpa}
                      InputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DatePicker
                      label={"Start Date"}
                      name={"startDate"}
                      value={values.startDate}
                      type={"month"}
                      onChange={handleChange}
                      error={touched.startDate && Boolean(errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      views={["year", "month"]}
                      maxDate={new Date()}
                      disableFuture
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DatePicker
                      label={"End Date"}
                      name={"endDate"}
                      value={values.endDate}
                      type={"month"}
                      onChange={handleChange}
                      disabled={
                        values.startDate === null ||
                        values.startDate === "" ||
                        values.startDate === "Invalid date"
                      }
                      error={touched.endDate && Boolean(errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      views={["year", "month"]}
                      minDate={values.startDate}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={4}></Grid>
            <Grid item md={12}>
              <Typo variant={"body2"}>
                {/* Markscard ({semList ? semList.length : "0"}) */}
                Semester Wise Marksheet
              </Typo>
            </Grid>
            <Grid item md={12}>
              <div className={renderPointerEventsClass()}>
                <Grid container spacing={2}>
                  {semList &&
                    semList.map((item) => {
                      return (
                        <Grid item md={4}>
                          <AcademicMarksCard
                            activeBacklog={item.activeBacklogs}
                            clearedBacklog={item.clearedBacklogs}
                            sgpa={item.sgpa}
                            sgpaScale={item.scoreScale}
                            document={item.studentDocument}
                            semester={item.semester}
                            handleDeleteClick={() =>
                              handleDeleteIconClick(item)
                            }
                          />
                        </Grid>
                      );
                    })}
                  <Grid item md={4}>
                    {data ? (
                      <CustomAdd onClick={() => handleAddClick()}>
                        Add Semester Details
                      </CustomAdd>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Popup
            open={open}
            title={"Academic Information"}
            leftButton={"Cancel"}
            rightButton={"Save"}
            handleClose={() => setOpen(!open)}
            handleLeftButtonClick={() => setOpen(!open)}
            handleRightButtonClick={handleSubmit}
            headerColor={"white"}
            width={"md"}
            disabled={state.buttonDisabled}
          >
            <AcademicPopup
              academicList={academicList}
              onDrop={onDrop}
              fileType={".pdf"}
              finalFile={finalFile ? finalFile : null}
              fileErr={fileErr}
              handleDeleteClick={handleDeleteClick}
              formik={formik}
              handleCgpaChange={handlesgpaChange}
              contentForContent={"Undergraduate Marksheet: Choose or drag & drop it here. (PDF)"}
            />
          </Popup>
        </form>
      </div>
      <div className={renderPointerEventsClass()}>
        <BottomContainer
          className={classes.bottomDiv}
          primaryButtonText={"Save"}
          secondaryButtonText={"Cancel"}
          handlePrimaryButtonClick={handleSubmit}
          handleSecondaryButtonClick={handleCancel}
          loading={true}
        />
      </div>
      <SnackBar
        open={state.snackOpen}
        severity={state.snackVariant}
        message={state.snackMsg}
        onClose={handleSnackClose}
      />
    </div>
  );
}

export default HigherStudiesForm;
