import { useSelector } from "react-redux";

const StageStepStatus = (stageName, stepName, status) => {
  const { productStages } = useSelector((state) => state.StudentReducer);
  const isCompletedOnboarding = () => {
    let isReturn = false;
    let stageObj =
      productStages &&
      Array.isArray(productStages) &&
      productStages.find((el) => el.stepName === stageName);
    let stepObj = stageObj?.steps?.find((el) => el.stepName === stepName);
    isReturn = stepObj?.status === status;
    return Boolean(isReturn);
  };
  return isCompletedOnboarding();
};

const isOnboardingCompleted = () => {
  return StageStepStatus("Onboarding", "Onboarding Details", "COMPLETED");
};

const isStrategyUploadDocumentCompleted = () => {
  return StageStepStatus("Strategy Session", "Upload Document", "COMPLETED");
};

const isApplicationStageDocumentCompleted = () => {
  return StageStepStatus("Application Stage", "Upload Documents", "COMPLETED");
};

const isProfileMentoringDocumentCompleted = () => {
  return StageStepStatus("Profile Mentoring", "Completed Cv", "COMPLETED");
};

export {
  StageStepStatus,
  isOnboardingCompleted,
  isStrategyUploadDocumentCompleted,
  isApplicationStageDocumentCompleted,
  isProfileMentoringDocumentCompleted,
};
