export const B_SCHOOL_FILTER_TOOL = {
  loader: "bSchoolFilterLoader",
  searchBSchool: "searchBSchool",
  getCountries: "getCountries",
  deleteStudentBSchoolCompare: "deleteStudentBSchoolCompare",
  deleteAllStudentBSchoolCompare: "deleteAllStudentBSchoolCompare",
  createAndUpdateCompare: "createAndUpdateCompare",
  getCompare: "getCompare",
  deleteStudentBSchoolPersonalized: "deleteStudentBSchoolPersonalized",
  deleteAllStudentBSchoolPersonalized: "deleteAllStudentBSchoolPersonalized",
  createAndUpdatePersonalized: "createAndUpdatePersonalized",
  getPersonalized: "getPersonalized",
  searchBSchoolCompare: "searchBSchoolCompare",
  getType: "getType",
  getBSchoolByRank: "getBSchoolByRank",
  getRankType: "getRankType",
  compareBSchool: "compareBSchool",
  getTotalTuitionFees: "getTotalTuitionFees",
  getHeaderText: "getHeaderText",
  clearData: "bSchoolFilterClearData",
  downloadPersonalizedList: "downloadPersonalizedList",
  clearCustomData: "bSchoolFilterClearCustomData",
};
