import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import BottomContainer from "../../components/BottomContainer";
import CustomDropzone from "../../components/CustomDropzone";
import SnackBar from "../../components/CustomizedSnackBar";
import DropDown from "../../components/DropDown";
import TextBox from "../../components/TextBox";
import {
  clearAcademicDetails,
  deleteAcademicFiles,
  getAcademicDetails,
  getAcademicStatus,
  getExamBoardDetails,
  updateAcademicDetails,
  uploadStudentDocument,
} from "../../redux/academic/Action";
import { getStudentSectionByStageName } from "../../redux/student/Action";
import { cgpaList } from "../../utils/Data";
import { useStyles } from "./Styles";
import {
  END_DATE_MESSAGE,
  FILE_FORMAT_ERROR,
  FILE_SIZE_ERROR,
  INVALID_DATE_MESSAGE,
  REQUIRED_ERROR,
  REQUIRED_FILE_ERROR,
} from "../../utils/Constants";
import { createFilterOptions } from "@mui/material";
import CreatableDropdown from "../../components/CreatableDropdown";
import { isOnboardingCompleted } from "../../utils/StageStepStatus";
import { isNumber } from "../../utils/HelperFunction";
import { spaceRemoveRegex } from "../../utils/Validation";
import actions from "../../CleverTab/Events";
import DatePicker from "../../components/DatePicker";

function Form() {
  const props = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useLocation();
  const [finalFile, setFinalFile] = useState(null);
  const [formikValue, setFormikValue] = useState({});
  const [hasFile, setHasFile] = useState(false);
  const [fileErr, setFileErr] = useState("");
  const [error, setError] = useState(false);
  const [getAcademic, setAcademic] = useState(null);
  const [state, setState] = useState({
    snackMsg: "",
    snackOpen: false,
    snackVariant: "",
    studentDocument: null,
  });

  const {
    getDetails,
    getExamBoard,
    updateDetails,
    deleteFiles,
    documentUpload,
  } = useSelector((state) => {
    return state.AcademicReducer;
  });

  useEffect(() => {
    if (documentUpload) {
      if (documentUpload.status === 200) {
        dispatch(getAcademicDetails("ssc"));
        setState({
          ...state,
          // snackOpen: true,
          // snackMsg: documentUpload.data,
          // snackVariant: "success",
          studentDocument: null,
        });
      } else {
        dispatch(getAcademicDetails("ssc"));
        setState({
          ...state,
          snackOpen: true,
          snackMsg: documentUpload.message,
          snackVariant: "error",
        });
      }
      dispatch(getAcademicStatus());
    }
  }, [documentUpload]);
  useEffect(() => {
    if (deleteFiles) {
      if (deleteFiles.success) {
        dispatch(getAcademicDetails("ssc"));
        setState({
          ...state,
          snackOpen: true,
          snackMsg: deleteFiles.message,
          snackVariant: "success",
        });
      } else {
        dispatch(getAcademicDetails("ssc"));
        setState({
          ...state,
          snackOpen: true,
          snackMsg: deleteFiles.message,
          snackVariant: "error",
        });
      }
    }
  }, [deleteFiles]);
  useEffect(() => {
    setState({
      ...state,
      snackOpen: false,
      snackMsg: "",
      snackVariant: "",
    });
    dispatch(clearAcademicDetails());
    dispatch(getAcademicDetails("ssc"));
    dispatch(getExamBoardDetails());
    dispatch(getStudentSectionByStageName(props.productId, props.stageName));
    actions.PushEvent("UP: User Opened 10th Details Card", {
      "Date": new Date()
    });
  }, []);

  useEffect(() => {
    if (updateDetails) {
      if (updateDetails.status === 200) {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: updateDetails.data.message,
          snackVariant: "success",
        });
        const file = new FormData();
        file.append("file", finalFile);
        if (state.studentDocument && state.studentDocument.length > 0) {
          dispatch(
            uploadStudentDocument("ssc", updateDetails.data.data.id, file)
          );
        }
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: updateDetails && updateDetails.message,
          snackVariant: "error",
        });
      }
    }
    dispatch(getAcademicStatus());
    dispatch(clearAcademicDetails());
  }, [updateDetails]);

  const handleSave = () => {
    let reqBody = {
      schoolName: formik.values.schoolName,
      examBoard: {
        name: formik.values.examBoard.name,
      },
      strStartDate: formik.values.startDate,
      strEndDate: formik.values.endDate,
      scoreScale: formik.values.cgpaScale.value,
      score: formik.values.cgpa,
      scoreScale: formik.values.cgpaScale.title,
    };
    let obj =
      getAcademic && getAcademic.data.id
        ? { ...reqBody, id: getAcademic.data.id }
        : reqBody;
    if (finalFile === null) {
      setFileErr(REQUIRED_FILE_ERROR);
    } else if (formik.values.startDate === formik.values.endDate) {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "End Date should be greater than Start Date",
        snackVariant: "error",
      });
    } else if (values.cgpa === ".") {
      setState({
        ...state,
        snackOpen: true,
        snackMsg: "Enter a valid CGPA value",
        snackVariant: "error",
      });
    } else {
      setFileErr("");
      dispatch(updateAcademicDetails("ssc", obj));
      actions.PushEvent("UP: User Saved 10th Details", {
        "Date": new Date()
      });
    }
  };

  const handleSnackClose = () => {
    setState({ ...state, snackOpen: false, snackMsg: "", snackVariant: "" });
  };

  useEffect(() => {
    if (
      state.studentDocument &&
      state.studentDocument.length > 0 &&
      state.studentDocument[0].type === "application/pdf"
    ) {
      if (state.studentDocument[0].size < 1000000) {
        setHasFile(true);
        setFileErr("");

        var stuBasicData = JSON.parse(
          window.sessionStorage.getItem("studentBasicData")
        );
        var name =
          stuBasicData.first_name + "_" + stuBasicData.last_name + "_" + "SSC";
        var file = state.studentDocument[0];

        var indexOf = file.type.indexOf("/");
        var newFileType = file.type.substr(indexOf + 1);

        var blob = new Blob([file], { type: newFileType });

        var newFile = new File(
          [blob],
          name
            .concat(".", newFileType)
            .replace(
              "vnd.openxmlformats-officedocument.wordprocessingml.document",
              "docx"
            ),
          { type: newFileType }
        );
        setFinalFile(newFile);
      } else {
        setFileErr(FILE_SIZE_ERROR);
      }
    } else if (state.studentDocument) {
      setFileErr(FILE_FORMAT_ERROR);
    }
  }, [state.studentDocument]);

  useEffect(() => {
    if (getDetails) {
      if (getDetails.status === 200) {
        setAcademic(getDetails);
        formik.setValues({
          ...formik.values,
          schoolName:
            getDetails && getDetails.data && getDetails.data.schoolName,
          examBoard: getDetails && getDetails.data && getDetails.data.examBoard,
          startDate: getDetails && getDetails.data && getDetails.data.startDate,
          endDate: getDetails && getDetails.data && getDetails.data.endDate,
          cgpa: getDetails && getDetails.data && getDetails.data.score,
          cgpaScale:
            getDetails && getDetails.data && getDetails.data.scoreScale
              ? {
                  title:
                    getDetails && getDetails.data && getDetails.data.scoreScale,
                }
              : null,
        });
        setHasFile(
          getDetails &&
            getDetails.data &&
            getDetails.data.studentDocument.length !== 0
            ? true
            : false
        );
        setFinalFile(
          getDetails &&
            getDetails.data.studentDocument &&
            getDetails.data.studentDocument.length > 0
            ? {
                ...getDetails.data.studentDocument,
                name: getDetails.data.studentDocument[0].path,
                size: getDetails.data.studentDocument[0].fileSizeInBytes,
              }
            : null
        );
      } else {
        setState({
          ...state,
          snackOpen: true,
          snackMsg: "Network Failed",
          snackVariant: "error",
        });
      }
      // dispatch(clearAcademicDetails());
    }
  }, [getDetails]);
  const onDrop = (files) => {
    setState({ ...state, studentDocument: files });
  };

  const validationSchema = yup.object({
    schoolName: yup.string().required(REQUIRED_ERROR),
    examBoard: yup.object().nullable().required(REQUIRED_ERROR),
    startDate: yup
      // .date()
      .string()
      .required(REQUIRED_ERROR),
    // .nullable(REQUIRED_ERROR),
    // .max(moment(new Date()).format("yyyy-MM"), INVALID_DATE_MESSAGE),
    endDate: yup.string().required(REQUIRED_ERROR),
    // .min(yup.ref("startDate"), END_DATE_MESSAGE),
    cgpaScale: yup.object().nullable().required(REQUIRED_ERROR),
    cgpa: yup.string().required(REQUIRED_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      schoolName: "",
      examBoard: null,
      startDate: "",
      endDate: "",
      cgpaScale: null,
      cgpa: " ",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const handleDeleteClick = () => {
    let docId =
      getAcademic &&
      getAcademic.data &&
      getAcademic.data.studentDocument &&
      getAcademic.data.studentDocument.length > 0
        ? getAcademic?.data?.studentDocument[0].id
        : {};

    if (
      getAcademic &&
      getAcademic.data &&
      getAcademic.data.studentDocument.length === 0
    ) {
      setFinalFile(null);
    } else if (getAcademic?.data === "") {
      setFinalFile(null);
    } else {
      dispatch(deleteAcademicFiles("ssc", docId));
      // dispatch(getAcademicDetails("ssc"));
    }
  };
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    handleSubmit,
    handleBlur,
    touched,
  } = formik;

  const handleCancel = () => {
    dispatch(getAcademicDetails("ssc"));
  };

  const handleCgpaChange = (e) => {
    if (formik.values.cgpaScale) {
      if (
        parseInt(formik.values.cgpaScale.title) < parseInt(e.target.value) ||
        parseInt(e.target.value) < 0 ||
        parseFloat(parseInt(formik.values.cgpaScale.title).toFixed(3)) <
          parseFloat(e.target.value).toFixed(3)
      ) {
        e.preventDefault();
      } else {
        formik.setValues({
          ...formik.values,
          cgpa: e.target.value,
        });
      }
    }
  };
  useEffect(() => {
    if (
      values.cgpaScale === null ||
      values.cgpaScale === "" ||
      values.cgpaScale.title === ""
    ) {
      formik.setValues({
        ...formik.values,
        cgpa: "",
      });
    } else if (
      parseInt(values.cgpaScale.title) < parseInt(values.cgpa) ||
      parseFloat(parseInt(values.cgpaScale.title).toFixed(3)) <
        parseFloat(values.cgpa).toFixed(3)
    ) {
      formik.setValues({
        ...formik.values,
        cgpa: "",
      });
    }
  }, [values.cgpaScale]);
  useEffect(() => {
    if (
      moment(new Date(values.startDate)).format("YYYY MM") >
      moment(new Date(values.endDate)).format("YYYY MM")
    ) {
      formik.setValues({
        ...values,
        endDate: "",
      });
    }
  }, [values.startDate]);
  const filter = createFilterOptions();

  const renderPointerEventsClass = () => {
    return isOnboardingCompleted() ? classes.pointerEvents : "";
  };
  return (
    <div className={classes.formMaindiv}>
      <div className={classes.formDiv}>
        <form className={classes.formStyle}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className={renderPointerEventsClass()}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextBox
                      fullWidth
                      required
                      name="schoolName"
                      label="School Name"
                      value={values.schoolName}
                      onChange={(e) => {
                        if (
                          spaceRemoveRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          handleChange(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      error={touched.schoolName && errors.schoolName}
                      helperText={touched.schoolName && errors.schoolName}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <CreatableDropdown
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      // onChange={(e, value) => setFieldValue("college", value)}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setFieldValue("examBoard", { name: newValue });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setFieldValue("examBoard", {
                            name: newValue.inputValue,
                          });
                        } else {
                          setFieldValue("examBoard", newValue);
                        }
                      }}
                      required
                      options={getExamBoard || []}
                      // getOptionLabel={(option) => option.name}
                      value={values.examBoard || null}
                      onBlur={handleBlur}
                      // onChange={(e, value) => setFieldValue("examBoard", value)}
                      label="Examination Board"
                      name="examBoard"
                      variant="standard"
                      error={touched.examBoard && errors.examBoard}
                      helperText={touched.examBoard && errors.examBoard}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DatePicker
                      label={"Start Date"}
                      name={"startDate"}
                      value={values.startDate}
                      type={"month"}
                      onChange={handleChange}
                      error={touched.startDate && Boolean(errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      views={["year", "month"]}
                      maxDate={new Date()}
                      disableFuture
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DatePicker
                      label={"End Date"}
                      name={"endDate"}
                      value={values.endDate}
                      type={"month"}
                      onChange={handleChange}
                      disabled={
                        values.startDate === null ||
                        values.startDate === "" ||
                        values.startDate === "Invalid date"
                      }
                      error={touched.endDate && Boolean(errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      views={["year", "month"]}
                      minDate={values.startDate}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DropDown
                      required
                      options={cgpaList}
                      getOptionLabel={(option) => option.title}
                      value={values.cgpaScale}
                      onBlur={handleBlur}
                      onChange={(e, value) => setFieldValue("cgpaScale", value)}
                      label="Grading Scale"
                      name="cgpaScale"
                      variant="standard"
                      error={touched.cgpaScale && errors.cgpaScale}
                      helperText={touched.cgpaScale && errors.cgpaScale}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextBox
                      required
                      id="cgpa"
                      name="cgpa"
                      label="Percentage/CGPA"
                      disabled={
                        values.cgpaScale === null ||
                        values.cgpaScale === "" ||
                        (values.cgpaScale && values.cgpaScale.title === "")
                      }
                      fullWidth
                      value={values.cgpa}
                      onChange={(e) => handleCgpaChange(e)}
                      onKeyPress={(evt) => {
                        if (isNumber(evt) || evt.target.value?.length >= 5)
                          evt.preventDefault();
                      }}
                      error={touched.cgpa && errors.cgpa}
                      helperText={touched.cgpa && errors.cgpa}
                      InputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={renderPointerEventsClass()}>
                <CustomDropzone
                  fileErr={fileErr}
                  finalFile={finalFile ? finalFile : null}
                  onDrop={onDrop}
                  fileType={".pdf"}
                  content={"10th Marksheet: Choose or drag & drop it here. (PDF)"}
                  handleDeleteClick={handleDeleteClick}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={renderPointerEventsClass()}>
        <BottomContainer
          className={classes.bottomDiv}
          primaryButtonText={"Save"}
          secondaryButtonText={"Cancel"}
          handlePrimaryButtonClick={handleSubmit}
          handleSecondaryButtonClick={handleCancel}
        />
      </div>
      <SnackBar
        open={state.snackOpen}
        severity={state.snackVariant}
        message={state.snackMsg}
        onClose={handleSnackClose}
      />
    </div>
  );
}

export default Form;
