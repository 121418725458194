import Cookies from "js-cookie";

export const SETTER = {
  sessionSetter: (name, value) => sessionStorage.setItem(name, value),
  cookieSetter: (name, value, options) => Cookies.set(name, value, options),
};

export const GETTER = {
  sessionGetter: (name) => sessionStorage.getItem(name),
  cookieGetter: (name) => Cookies.get(name),
};

export const TIMER = {
  halfSecond: 500,
  oneSecond: 1000,
};
export const REQUIRED_ERROR = "Please fill the required field";

export const EMAIL_ERROR = "Enter a valid email";

export const PHONE_NUMBER_ERROR = "Enter a valid phone number";

export const PHONE_NUMBER_LENGTH_ERROR =
  "Phone number must be at least 10 characters";

export const UPDATED_SUCCESS = "Updated Successfully";

export const CREATED_SUCCESS = "Created Successfully";

export const UPLOADED_SUCCESS = "Uploaded Successfully";

export const FILE_UPLOADED_SUCCESS = "File Uploaded Successfully";

export const FILE_DELETE_SUCCESS = "File Deleted Successfully";

export const DELETE_SUCCESS = "Deleted Successfully";

export const NO_DATA_FOUND = "No Data Found";

export const NO_RESULT_FOUND = "No Result Found";

export const ATTEMPT_SELECT_ERROR = "Please select the attempt first";

export const FILE_FORMAT_ERROR = "Invalid file format";

export const FILE_SIZE_ERROR = "Please check the file size";

export const UPLOAD_CERTIFICATE_MESSAGE = "Please upload the certificate";

export const PIN_CODE_ERROR = "Enter a valid pin code";

export const INVALID_DATE_MESSAGE = "Enter a valid date";

export const END_DATE_MESSAGE = "End date should be greater than start date";

export const START_DATE_MESSAGE = "Start date should be less than current date";

export const REQUIRED_FILE_ERROR = "Please upload marksheet";

export const URL_ERROR = "Enter a valid URL";

export const supportEmail = "support@thecareerlabs.com";
