class Helper {
  actionType = {
    test: "",
  };

  constructor(){
      this.actionType.test = "test";
  }

  testAction = () => {
    return dispatch => {
      dispatch({ type: this.actionType.test });
    };
  };
}

export default new Helper();
