import React, { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BackDrop from "../components/Backdrop";
import Index from "../pages/academic/Index";
import { NAVIGATE_TO, PATH, ROOT_PATH } from "./RoutePaths";
import LoginChildren from "../pages/Authentication/LoginRoute";
import TenthForm from "../pages/academic/TenthForm";
import TwelthForm from "../pages/academic/TwelthForm";
import PGForm from "../pages/academic/PgForm";
import UGForm from "../pages/academic/UgForm";
import DegreeForm from "../pages/academic/DegreeForm";
import StartegySessionIndex from "../pages/strategy-session/upload-document/Index";
// import QpmcCall from "../pages/strategy-session/callSchedule/Index";
// import UploadCVIndex from "../pages/uploadCv/Index";
const Login = React.lazy(() => import("../pages/Authentication/RootLogin"));
const Dashboard = React.lazy(() => import("../pages/dashboard/Index"));
const ProductStage = React.lazy(() =>
  import("../pages/dashboard/product-stage/Index")
);
const ReviewCard = React.lazy(() => import("../pages/Review/cvReview"));
const LeftActionContainer = React.lazy(() =>
  import("../pages/dashboard/left-action-container/Index")
);
const LearningJourney = React.lazy(() =>
  import("../pages/onBoarding/learningjourney")
);
const PersonalInformation = React.lazy(() =>
  import("../pages/personal-info/Index")
);
const AcademicInformation = React.lazy(() => import("../pages/academic/Index"));

const TermsAndCon = React.lazy(() => import("../pages/terms-and-con/Index"));

const ObCall = React.lazy(() => import("../pages/onBoarding/Index"));

const TestSurvey = React.lazy(() => import("../pages/test-survey/Index"));

const GraduateAdmissionTest = React.lazy(() =>
  import("../pages/graduate-admission-test/Index")
);

const GraduateAdmissionTestAdd = React.lazy(() =>
  import("../pages/graduate-admission-test/RootOutlet")
);

const GraduateAdmissionTestLanding = React.lazy(() =>
  import("../pages/graduate-admission-test/Landing")
);

const DeadLineTracker = lazy(() => import("../pages/deadline-tracker/Index"));

// Work exp Component

const IELTSAdd = React.lazy(() =>
  import("../pages/graduate-admission-test/IeltsForm")
);

const Course = lazy(() => import("../pages/course/Index"));
const Internship = lazy(() => import("../pages/internship/Index"));

const WorkExperience = lazy(() => import("../pages/work-experience/Index"));

const PrePgaCall = React.lazy(() =>
  import("../pages/profile-gap-analysis/pre-pga-call/RightContainer")
);

const PgaCall = React.lazy(() =>
  import("../pages/profile-gap-analysis/pga-call/RightContainer")
);

const PgaReport = React.lazy(() =>
  import("../pages/profile-gap-analysis/report/Index")
);

const PlanOfAction = React.lazy(() =>
  import("../pages/profile-gap-analysis/plan-of-action/PlanOfAction")
);

const CvReview = React.lazy(() =>
  import("../pages/profile-gap-analysis/cv-review/ViewCV")
);

const DisplayCV = React.lazy(() =>
  import("../pages/profile-gap-analysis/cv-review/CvViewer")
);

const BShoolFilter = lazy(() => import("../pages/bschool-filter/Index"));
const BShoolFilterMainContent = lazy(() =>
  import("../pages/bschool-filter/MainContent")
);
const AspirationIndex = React.lazy(() => import("../pages/aspiration/Index"));

const ProfileMentoring = lazy(() => import("../pages/profile-mentoring/Index"));
const Qpmc2 = lazy(() => import("../pages/profile-mentoring/qpmc2Index"));
const SchoolResearch = lazy(() =>
  import("../pages/profile-mentoring/SchoolResearchIndex")
);
const UploadCV = lazy(() => import("../pages/uploadCv/Index"));
const StrategyUploadDocument = lazy(() =>
  import("../pages/strategy-session/upload-document/Index")
);
const StrategyUploadDocumentTestTranscriptsLanding = lazy(() =>
  import("../pages/strategy-session/upload-document/test-transcripts/Landing")
);
const StrategyUploadDocumentTestTranscriptsAdd = lazy(() =>
  import(
    "../pages/strategy-session/upload-document/test-transcripts/RootOutlet"
  )
);
const StrategyCallSchedule = lazy(() =>
  import("../pages/strategy-session/callSchedule/Index")
);
const FeedBackCallSchedule = lazy(() =>
  import("../pages/pas/feedBackCall/Index")
);
const StrategyDocument = lazy(() =>
  import("../pages/strategy-session/upload-document/UploadedDocument")
);
const ReScheduleIndex = lazy(() =>
  import("../pages/strategy-session/callSchedule/ReScheduleIndex")
);
const InterViewCallSchedule = lazy(() =>
  import("../pages/pas/visaInterview/Index")
);
const InterViewCall2Schedule = lazy(() =>
  import("../pages/pas/visaInterview/InterviewCall")
);
const ResourcesIndex = lazy(() => import("../pages/pas/Resources/Index"));
const DocumentsIndex = lazy(() =>
  import("../pages/pas/Resources/ViewDocument")
);
const WebinarIndex = lazy(() => import("../pages/pas/Resources/Webinar"));
const ApplicationStage = lazy(() => import("../pages/applicationStage/Index"));
const AdmitCard = lazy(() =>
  import("../pages/postAdmitService/admitCard/Index")
);
const UploadAdmitCard = lazy(() =>
  import("../pages/postAdmitService/admitCard/uploadAdmitCard/Index")
);
const UploadAdmitCardLeft = lazy(() =>
  import("../pages/postAdmitService/admitCard/uploadAdmitCard/LeftContainer")
);
function MainRoute() {
  return (
    <BrowserRouter>
      <Suspense fallback={<BackDrop open={true} />}>
        <Routes>
          <Route path={PATH.rootLoginPath} element={<Login />}>
            {LoginChildren()}
          </Route>
          <Route path={"/"} element={<Navigate to={PATH.loginPagePath} />} />
          <Route path={PATH.dashboardPath} element={<Dashboard />}>
            {/* Dashboard Content Will Come Here */}
            <Route path={PATH.productPath} element={<ProductStage />}>
              {/* Action Container Will Come Here */}
              <Route path={PATH.stagePath} element={<LeftActionContainer />}>
                {/* Main Content Routes Will Come Here */}
                <Route path={PATH.obCallPath} element={<ObCall />} />

                <Route path={PATH.prePgaCallPath} element={<PrePgaCall />} />
                <Route path={PATH.pgaCallPath} element={<PgaCall />} />
                <Route path={PATH.pgaReportPath} element={<PgaReport />} />
                <Route
                  path={PATH.planOfActionPath}
                  element={<PlanOfAction />}
                />
                <Route path={PATH.cvReviewPath} element={<CvReview />}>
                  <Route path={PATH.cvReviewListPath} element={<DisplayCV />} />
                </Route>

                {/* test and survey */}
                <Route path={PATH.testAndSurveyPath} element={<TestSurvey />} />

                {/* Profile Mentoring */}

                <Route
                  path={PATH.profileMentoringPath}
                  element={<ProfileMentoring />}
                />
                <Route
                  path={PATH.profileMentoringSectionPath}
                  element={<ProfileMentoring />}
                />
                <Route
                  path={PATH.profileMentoringUploadDocumentSectionPath}
                  element={<ProfileMentoring />}
                />
                <Route
                  path={PATH.learningJourneyPath}
                  element={<LearningJourney />}
                />
                <Route path={PATH.qpmc2Path} element={<Qpmc2 />} />
                <Route path={PATH.qpmc2SectionPath} element={<Qpmc2 />} />
                <Route
                  path={PATH.schoolResearchPath}
                  element={<SchoolResearch />}
                />
                <Route
                  path={PATH.schoolResearchSectionPath}
                  element={<SchoolResearch />}
                />
                {/* <Route path={PATH.testSurveyPath} element={<TestSurvey />} /> */}
                {/* GAT*/}

                <Route path={PATH.gatPath} element={<GraduateAdmissionTest />}>
                  {/* Test Landing */}
                  <Route
                    path={PATH.gatPath}
                    element={<GraduateAdmissionTestLanding />}
                  />
                  <Route
                    path={PATH.gatLandingPath}
                    element={<GraduateAdmissionTestLanding />}
                  />
                  {/* Test Add Root */}
                  <Route
                    path={PATH.gatAddPath}
                    element={<GraduateAdmissionTestAdd />}
                  />
                </Route>

                <Route
                  path={PATH.personalInfoPath}
                  element={<PersonalInformation />}
                />
                <Route
                  path={PATH.workExperiencePath}
                  element={<WorkExperience />}
                />
                <Route path={PATH.academicPath} element={<Index />} />

                <Route
                  path={PATH.academicPath}
                  element={<AcademicInformation />}
                >
                  <Route path={PATH.academicPath} element={<TenthForm />} />
                  <Route path={PATH.tenthPath} element={<TenthForm />} />
                  <Route path={PATH.twelfthPath} element={<TwelthForm />} />
                  <Route path={PATH.diplomaPath} element={<DegreeForm />} />
                  <Route path={PATH.ugPath} element={<UGForm />} />
                  <Route path={PATH.pgPath} element={<PGForm />} />
                </Route>
                <Route
                  path={PATH.gatPath}
                  element={<GraduateAdmissionTest />}
                />
                {/* Aspiration Path */}
                <Route
                  path={PATH.aspirationPath}
                  element={<AspirationIndex />}
                />

                {/* Upload CV */}
                <Route path={PATH.uploadCVPath} element={<UploadCV />} />

                <Route path={PATH.termsAndConPath} element={<TermsAndCon />} />

                {/* Application Stage */}
                <Route
                  path={PATH.applicationStagePath}
                  element={<ApplicationStage />}
                />

                <Route
                  path={PATH.applicationSectionPath}
                  element={<ApplicationStage />}
                />

                {/* Post Admit Service */}
                <Route
                  path={PATH.postAdmitSelectorUploadPath}
                  element={<AdmitCard />}
                />

                <Route
                  path={PATH.postAdmitSelectorUploadSectionPath}
                  element={<AdmitCard />}
                />
                <Route
                  path={PATH.uploadAdmitCardPath}
                  element={<UploadAdmitCard />}
                />
                <Route
                  path={PATH.uploadAdmitCard}
                  element={<UploadAdmitCard />}
                />
                {/* <Route path={PATH.documents} element={<UploadAdmitCard />} />
                <Route
                  path={PATH.documentsPath}
                  element={<UploadAdmitCard />}
                /> */}
                {/* Strategy Session */}
                <Route
                  path={PATH.strategyUploadDocumentPath}
                  element={<StrategyUploadDocument />}
                />
                <Route
                  path={PATH.strategySessionCallSchedule}
                  element={<StrategyCallSchedule />}
                />
                <Route
                  path={PATH.strategySessionCallSchedulePath}
                  element={<StrategyCallSchedule />}
                />
                <Route
                  path={PATH.strategyDocument}
                  element={<StrategyDocument />}
                />
                <Route
                  path={PATH.strategyDocumentPath}
                  element={<StrategyDocument />}
                />
                <Route
                  path={PATH.strategySessionCall}
                  element={<ReScheduleIndex />}
                />
                <Route
                  path={PATH.strategySessionCallPath}
                  element={<ReScheduleIndex />}
                />
                <Route
                  path={PATH.strategyUploadDocumentSectionPath}
                  element={<StrategyUploadDocument />}
                >
                  {/* Test Landing */}
                  <Route
                    path={PATH.strategyUploadDocumentTestTranscriptsLandingPath}
                    element={<StrategyUploadDocumentTestTranscriptsLanding />}
                  />
                  {/* Test Add Root */}
                  <Route
                    path={PATH.strategyUploadDocumentTestTranscriptsAddPath}
                    element={<StrategyUploadDocumentTestTranscriptsAdd />}
                  />
                </Route>
                <Route path={PATH.resource} element={<ResourcesIndex />} />
                <Route path={PATH.resourcePath} element={<ResourcesIndex />} />
                <Route path={PATH.documents} element={<DocumentsIndex />} />
                <Route path={PATH.documentsPath} element={<DocumentsIndex />} />
                <Route
                  path={PATH.feedBackSessionCall}
                  element={<FeedBackCallSchedule />}
                />
                <Route
                  path={PATH.feedBackSessionCallPath}
                  element={<FeedBackCallSchedule />}
                />
                <Route
                  path={PATH.visaInterviewCall}
                  element={<InterViewCallSchedule />}
                />
                <Route
                  path={PATH.visaInterviewCallPath}
                  element={<InterViewCallSchedule />}
                />
                <Route
                  path={PATH.visaInterviewCall2}
                  element={<InterViewCall2Schedule />}
                />
                <Route
                  path={PATH.visaInterviewCall2Path}
                  element={<InterViewCall2Schedule />}
                />
                <Route path={PATH.webinar} element={<WebinarIndex />} />
                <Route path={PATH.webinarPath} element={<WebinarIndex />} />
              </Route>
            </Route>
            {/* DeadLine Tracker */}
            <Route
              path={PATH.deadlineTrackerPath}
              element={<DeadLineTracker />}
            />
            {/* Course Path */}
            <Route path={PATH.coursePath} element={<Course />} />
            <Route path={PATH.reviewPath} element={<ReviewCard />} />

            {/* Internship Path */}
            <Route path={PATH.internshipPath} element={<Internship />} />
            {/* BShool Filter */}
            <Route path={PATH.bShoolFilterPath} element={<BShoolFilter />}>
              <Route
                path={PATH.bShoolFilterPath}
                element={<BShoolFilterMainContent />}
              />
              <Route
                path={PATH.bShoolFilterContentPath}
                element={<BShoolFilterMainContent />}
              />
            </Route>
          </Route>
          {/* profile gap analysis path */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default MainRoute;
