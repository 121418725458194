import React from "react";
import { Route } from "react-router-dom";
import { PATH } from "../../routes/RoutePaths";

const Login = React.lazy(() => import("./Login"));
const ForgetPass = React.lazy(() => import("./ForgetPass"));
const MailSent = React.lazy(() => import("./MailSent"));
const ResetPass = React.lazy(() => import("./ResetPass"));
const ResetSuccess = React.lazy(() => import("./ResetSuccess"));
const Trouble = React.lazy(() => import("./Trouble"));

const LOGIN_ROUTE_CONTENT = [
  {
    path: PATH.rootLoginPath,
    element: <Login />,
  },
  {
    path: PATH.loginPagePath,
    element: <Login />,
  },
  {
    path: PATH.forgotPassPath,
    element: <ForgetPass />,
  },
  {
    path: PATH.troublePath,
    element: <Trouble />,
  },
  {
    path: PATH.resetPassPath,
    element: <ResetPass />,
  },
  {
    path: PATH.resetSuccessPath,
    element: <ResetSuccess />,
  },
  {
    path: PATH.mailSentPath,
    element: <MailSent />,
  },
];

export default function LoginChildren() {
  return LOGIN_ROUTE_CONTENT.map(({ path, element }) => (
    <Route path={path} element={element} />
  ));
}
