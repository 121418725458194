import React, { useState } from "react";
import { HeaderDiv, Typo } from "../styled/Index";
import { ICONS } from "../utils/Shared";
import { useStyles } from "./Styles";
import GatTaken from '../assets/images/Test.svg'
import { Popper } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
function HeadingContainer({ title, data, details, arrow, onClick }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseOver = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <HeaderDiv>
        <Typo variant={"h5"} fontWeight={600} onClick={onClick}>
          {title} {arrow}{details}
        </Typo>

        <div
          className={classes.infoContainer}
          onMouseOver={(e) => handleMouseOver(e)}
        >
          {ICONS["info"]}
        </div>
      </HeaderDiv>
      <ClickAwayListener onClickAway={() => handleMouseLeave()}>
        <Popper
          // open={anchorEl}
          anchorEl={anchorEl}
          placement="bottom-end"
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={classes.popperStyles}
            dangerouslySetInnerHTML={{ __html: data }}
          ></div>
        </Popper>
      </ClickAwayListener>
    </>
  );
}

export default HeadingContainer;
