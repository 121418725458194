import axios from "../../axios/Instance";
import { ACADEMIC_URL } from "../../axios/Url";
import { errorHandler } from "../../utils/HelperFunction";
import { Academic } from "./ActionType";

export const getAcademicStatus = () => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getStatus(studentId), {
          params: {
            section: "AcademicDetails",
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.getStatus,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getStatus, error, false));
    }
  };
};
export const updateAcademicDetails = (type, body) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .put(ACADEMIC_URL.updateDetails(studentId), body, {
          crossDomain: true,

          params: {
            type: type,
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.updateDetails,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.updateDetails, error, false));
    }
  };
};
export const deleteSemesterDetails = (semesterDetailsId, studentDocumentId) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .delete(ACADEMIC_URL.deleteSemDetails(studentId), {
          crossDomain: true,
          params: {
            semesterDetailsId: semesterDetailsId,
            studentDocumentId: studentDocumentId,
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.deleteSemDetails,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.deleteSemDetails, error, false));
    }
  };
};
export const deleteAcademicFiles = (type, documentId) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .delete(ACADEMIC_URL.deleteAcademicFiles(studentId), {
          crossDomain: true,
          params: {
            type: type,
            certificateId: documentId,
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.deleteFiles,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.deleteFiles, error, false));
    }
  };
};
export const getExamBoardDetails = () => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getExamBoard(studentId), {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.getExamBoard,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getExamBoard, error, false));
    }
  };
};
export const getcollegeDetails = () => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getcollege(studentId), {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.getcollege,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getcollege, error, false));
    }
  };
};
export const getuniversityDetails = () => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getuniversity(studentId), {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.getuniversity,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getuniversity, error, false));
    }
  };
};
export const getdegreeDetails = (type) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getdegree(studentId, type), {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.getdegree,
            payload: result.data,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getdegree, error, false));
    }
  };
};
export const getdepartmentDetails = () => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getdepartment(studentId), {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.getdepartment,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getdepartment, error, false));
    }
  };
};
export const getAcademicDetails = (type) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .get(ACADEMIC_URL.getacademicdetails(studentId), {
          crossDomain: true,

          params: {
            type: type,
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.getAcademic,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.getAcademic, error, false));
    }
  };
};
export const uploadStudentDocument = (type, id, data) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .post(ACADEMIC_URL.uploadDocument(studentId, id, type), data, {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.documentUpload,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.documentUpload, error, false));
    }
  };
};
export const updateSemesterDetails = (type, data) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .put(ACADEMIC_URL.updatesemDetails(studentId), data, {
          crossDomain: true,

          params: {
            type: type,
          },
        })
        .then((result) => {
          dispatch({
            type: Academic.updateSemdetails,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.updateSemdetails, error, false));
    }
  };
};
export const uploadSemesterFile = (type, id, semNo, data) => {
  let studentId = window.sessionStorage.getItem("studentId");

  return async (dispatch) => {
    try {
      await axios
        .post(ACADEMIC_URL.uploadSemFile(studentId, type, id, semNo), data, {
          crossDomain: true,
        })
        .then((result) => {
          dispatch({
            type: Academic.uploadSemFile,
            payload: result,
            loading: false,
          });
        });
    } catch (error) {
      dispatch(errorHandler(Academic.uploadSemFile, error, false));
    }
  };
};
export const clearAcademicDetails = () => {
  return (dispatch) => {
    dispatch({ type: Academic.clearAcademic });
  };
};
