import { Course } from "./ActionType";

const INITIAL_STATE = {
  getCourse: null,
  postCourse: [],
  postCourseId: []
};

const CourseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Course.getCourse:
      return {
        ...state,
        getCourse: action.payload,
      };
    case Course.postCourse:
      return {
        ...state,
        postCourse: action.payload,
      };
    case Course.postCourseId:
      return {
        ...state,
        postCourseId: action.payload,
      };
    default:
      return state;
  }
};

export default CourseReducer;
