export const GAT = {
  gatLoader: "gatLoader",

  /**GRE */
  getGreData: "getGreData",
  getUpcomingGreData: "getUpcomingGreData",
  saveGreData: "saveGreData",
  updateGreData: "updateGreData",
  getGreInfo: "getGreInfo",

  /**GMAT */
  gmatLoader: "gmatLoader",
  getGmatData: "getGmatData",
  getUpcomingGmatData: "getUpcomingGmatData",
  saveGmatData: "saveGmatData",
  updateGmatData: "updateGmatData",
  getGmatInfo: "getGmatInfo",

  /**TOEFL */
  toeflLoader: "toeflLoader",
  getToeflData: "getToeflData",
  getUpcomingToeflData: "getUpcomingToeflData",
  saveToeflData: "saveToeflData",
  updateToeflData: "updateToeflData",
  getToeflInfo: "getToeflInfo",

  /**IELTS */
  ieltsLoader: "ieltsLoader",
  getIeltsData: "getIeltsData",
  getUpcomingIeltsData: "getUpcomingIeltsData",
  saveIeltsData: "saveIeltsData",
  updateIeltsData: "updateIeltsData",
  getIeltsInfo: "getIeltsInfo",

  deleteFile: "gatDeleteFile",
  uploadFile: "gatUploadFile",
  deleteGatInfo: "deleteGatInfo",
  gatClearData: "gatClearData",
};
