export const ApplicationStage = {
  loader: "applicationStageLoader",
  clearData: "applicationStageClearData",
  getApplicationStageData: "getApplicationStageData",
  downloadFile: "downloadFile",
  addApplicationStageSchool: "addApplicationStageSchool",
  saveApplicationStageFile: "saveApplicationStageFile",
  saveFieldValue: "saveFieldValue",
  schoolList: "schoolList",
  getLeftContainer: "getLeftContainer",
  completeStage: "completeStage",
  getSchoolList: "getSchoolList",
  tabSchoolValue: "tabSchoolValue",
  saveFileValue: "saveFileValue",
  deleteTabSchool: "deleteTabSchool",
  clearCustomData: "clearApplicationStageCustomData",
};
