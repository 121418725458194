import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import CalendarIcon from "../../../assets/images/CalendarIcon.png";
import TimerIcon from "../../../assets/images/Timer.png";
import { Typo } from "../../../styled/Index";
import { useStyles } from "../Styles";
import { DAYS, MONTHS } from "../Variables";
import * as moment from "moment";

const CallConfirmationPopup = ({ heading, date, time }) => {
  /**using moment we can convert date to required format */
  let onSelectedDate = moment(new Date()).format("dddd, MMM D");

  const classes = useStyles();
  return (
    <Box pt={2} pb={2}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <Typo fontWeight={"600"} variant={"subtitle1"}>
            {heading}
          </Typo>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          lg={12}
          xs={12}
          display="flex"
          columnSpacing={2}
        >
          {/* it displays date(Tuesday, Oct 17) and time(10:00 AM - 10:45 AM)  */}
          <Box display="flex" alignItems="center">
            <img
              src={CalendarIcon}
              alt=""
              color="#000000"
              className={classes.calendarIcon}
            />
            <Box pl={1}>
              <Typo variant={"inherit"}>{date ? date : onSelectedDate}</Typo>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pl={4}>
            <img src={TimerIcon} alt="" className={classes.timerIcon} />

            <Box pl={1}>
              <Typo variant={"inherit"}>{time}</Typo>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallConfirmationPopup;
