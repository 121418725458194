import { PERSONAL_INFORMATION } from "./ActionType";

const initialState = {};

const PersonalInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSONAL_INFORMATION.personalInfoLoader:
      return { ...state, isLoading: true };
    case PERSONAL_INFORMATION.personalInfoClearData:
      return {};
    case PERSONAL_INFORMATION.getPersonalDetails:
      return {
        ...state,
        personalDetails: action.payload,
        isLoading: action.loading,
      };
    case PERSONAL_INFORMATION.getPersonalImage:
      return {
        ...state,
        personalImage: action.payload,
        isLoading: action.loading,
      };
    case PERSONAL_INFORMATION.updatePersonalDetails:
      return {
        ...state,
        updatePersonalInfo: action.payload,
        isLoading: action.loading,
      };
    case PERSONAL_INFORMATION.uploadProfileImage:
      return {
        ...state,
        uploadProfileImageResponse: action.payload,
        isLoading: action.loading,
      };
    case PERSONAL_INFORMATION.clearPersonalDetails:
      return {
        ...state,
        personalDetails: null,
      };
    case PERSONAL_INFORMATION.clearCustomData:
      return {
        ...state,
        [action.fieldName]: null,
      };
    default:
      return state;
  }
};

export default PersonalInformationReducer;
