import { Internship } from "./ActionType";

const INITIAL_STATE = {
  getAllLocation: null,
  getAllSkills: null,
  getAllIntenship: null,
  postInternship: null,
  searchIntern: null,
};

const InternshipReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Internship.getAllIntenship:
      return {
        ...state,
        getAllIntenship: action.payload,
      };
    case Internship.getAllLocation:
      return {
        ...state,
        getAllLocation: action.payload,
      };
    case Internship.getAllSkills:
      return {
        ...state,
        getAllSkills: action.payload,
      };
    case Internship.searchIntern:
      return {
        ...state,
        searchIntern: action.payload,
      };
    case Internship.postInternship:
      return {
        ...state,
        postInternship: action.payload,
      };
    case Internship.clearInternship:
      return {
        ...state,
        postInternship: null,
        searchIntern: null,
        getAllIntenship: null,
      };

    default:
      return state;
  }
};

export default InternshipReducer;
