export const Aspiration = {
  getAspiration: "getAspiration",
  getDegree: "getDegree",
  getIntake: "getIntake",
  getFieldofStudy: "getFieldofStudy",
  getPreferredPackage: "getPreferredPackage",
  getPreferredLocation: "getPreferredLocation",
  getWork: "getWork",
  getRegion: "getRegion",
  getSchool: "getSchool",
  getAreaofSpecialsation: "getAreaofSpecialsation",
  postAspiration: "postAspiration",
  clearAspiration: "clearAspiration",
  postFieldOfStudy: "postFieldOfStudy"
};
