import { Academic } from "./ActionType";

const INITIAL_STATE = {
  getStatus: null,
  deleteSemDetails: null,
  updateDetails: null,
  deleteFiles: null,
  getdegree: null,
  getdepartment: null,
  getuniversity: null,
  getcollege: null,
  getExamBoard: null,
  getDetails: null,
  documentUpload: null,
  updateSemdetails: null,
  uploadSemFile: null,
};

const AcademicReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Academic.getStatus:
      return {
        ...state,
        getStatus: action.payload,
      };
    case Academic.updateDetails:
      return {
        ...state,
        updateDetails: action.payload,
      };
    case Academic.deleteSemDetails:
      return {
        ...state,
        deleteSemDetails: action.payload,
      };
    case Academic.deleteFiles:
      return {
        ...state,
        deleteFiles: action.payload,
      };
    case Academic.getdegree:
      return {
        ...state,
        getdegree: action.payload,
      };
    case Academic.getdepartment:
      return {
        ...state,
        getdepartment: action.payload,
      };
    case Academic.getExamBoard:
      return {
        ...state,
        getExamBoard: action.payload,
      };
    case Academic.getuniversity:
      return {
        ...state,
        getuniversity: action.payload,
      };
    case Academic.getcollege:
      return {
        ...state,
        getcollege: action.payload,
      };
    case Academic.getAcademic:
      return {
        ...state,
        getDetails: action.payload,
      };
    case Academic.documentUpload:
      return {
        ...state,
        documentUpload: action.payload,
      };
    case Academic.updateSemdetails:
      return {
        ...state,
        updateSemdetails: action.payload,
      };
    case Academic.uploadSemFile:
      return {
        ...state,
        uploadSemFile: action.payload,
      };
    case Academic.clearAcademic:
      return {
        ...state,
        updateDetails: null,
      };
    default:
      return state;
  }
};

export default AcademicReducer;
