import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import DeleteIcon from "../assets/icons/DeleteIcon.svg";
import PDFImage from "../assets/images/Pdf.svg";
import UploadDocumentImage from "../assets/icons/uploadIcon.svg";
import { Typo } from "../styled/Index";
import CustomButton from "./CustomButton";
import { useStyles } from "./Styles";

export function CustomDropzone({
  fileErr,
  finalFile,
  onDrop,
  handleDeleteClick,
  isCvReview,
  fileType,
  content
}) {
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section className="container">
          <div className={classes.dropzoneDiv}>
            <div
              style={{
                pointerEvents: finalFile ? "none" : "unset",

                width: isCvReview ? "100%" : "98%",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} accept={fileType}
               />
              <div className={classes.dropzoneInnerDiv}>
                {isCvReview ? (
                  <img
                    src={UploadDocumentImage}
                    alt={"UploadDocument_Image"}
                    className={classes.dropzonePdfStyle}
                  />
                ) : (
                  <img
                    src={PDFImage}
                    alt={"PDF_Image"}
                    className={classes.dropzonePdfStyle}
                  />
                )}
                <Typo style={{ paddingRight: "5%", paddingLeft: "1%" }}>
                  {content}
                </Typo>
                <CustomButton variant={"outlined"}>Browse</CustomButton>
              </div>
            </div>
          </div>

          <Typo color={"error"} fontSize={"body2"}>
            {fileErr}
          </Typo>
          <aside className={classes.fileNameDiv}>
            {finalFile && (
              <div
                className={classes.fileNameCard}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Grid container>
                  <Grid item md={3} className={classes.cardGrid}>
                    <img
                      src={PDFImage}
                      alt={"PDF_Image"}
                      className={classes.fileNamePDF}
                    />
                  </Grid>
                  <Grid item md={9} className={classes.cardGrid}>
                    <Grid container>
                      <Grid item md={7}>
                        {finalFile && (
                          <Typo>
                            {typeof finalFile === "object"
                              ? finalFile.name
                              : finalFile}
                          </Typo>
                        )}
                      </Grid>
                      <Grid item md={5}>
                        {finalFile && (
                          <Typo>
                            {typeof finalFile === "object"
                              ? `${
                                  finalFile.size
                                    ? `${finalFile.size} bytes`
                                    : ""
                                }`
                              : finalFile}
                          </Typo>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {hover && (
                  <div
                    className={classes.fileNameDeleteIcon}
                    onClick={() => handleDeleteClick()}
                  >
                    <img
                      src={DeleteIcon}
                      className={classes.deleteIconStyle}
                      alt={"Delete_Icon"}
                    />
                  </div>
                )}
              </div>
            )}
          </aside>
        </section>
      )}
    </Dropzone>
  );
}

export default CustomDropzone;
