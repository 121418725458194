import Backdrop from "@mui/material/Backdrop";
import React from "react";
import Loader from "./Loader";

function BackDrop({ open, isPopup }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: isPopup ? 2500 : (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Loader />
    </Backdrop>
  );
}

export default BackDrop;
