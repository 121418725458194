import React from "react";
import CustomButton from "./CustomButton";
import { useStyles } from "./Styles";

function BottomContainer({
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  disabled,
  opacity,
}) {
  const classes = useStyles();
  return (
    <div className={classes.bottomPanel}>
      <CustomButton variant={"cancel"} onClick={handleSecondaryButtonClick}>
        {secondaryButtonText}
      </CustomButton>
      <CustomButton
        variant={"contained"}
        color={"primary"}
        disabled={disabled}
        opacity={opacity}
        onClick={handlePrimaryButtonClick}
      >
        {primaryButtonText}
      </CustomButton>
    </div>
  );
}

export default BottomContainer;
