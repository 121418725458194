export const DEADLINE_TRACKER = {
  loader: "deadLineTrackerLoader",
  getPaginateRegion: "getPaginateRegion",
  getPaginateSubregion: "getPaginateSubregion",
  getPaginateCountry: "getPaginateCountry",
  getPaginateBSchool: "getPaginateBSchool",
  postSearch: "postSearch",
  allBSchool: "allBSchool",
  schoolLogoList: "schoolLogoList",
  getPaginateIntakeYear: "getPaginateIntakeYear",
  getPaginateIntakeTerm: "getPaginateIntakeTerm",
  getPaginateRoundNo: "getPaginateRoundNo",
  clearData: "deadLineTrackerClearData",
  clearCustomData: "deadLineTrackerClearCustomData",
  putUserGuide: "putUserGuide",
  getDeadlineTrackerGuide: "getDeadlineTrackerGuide",
};
