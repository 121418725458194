import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import CustomButton from "../../components/CustomButton";
import { Typo } from "../../styled/Index";
import { useStyles } from "./Styles";

const Popup = ({
  open,
  close,
  title,
  children,
  leftButton,
  rightButton,
  handleClose,
  handleRightButtonClick,
  width,
  isGoogleCalendarActive,
  selectedTimeSlot,
  handleLeftButtonClick,
}) => {
  const classes = useStyles();

  /**it renders header for the popup */
  const renderHeader = () => {
    if (!isGoogleCalendarActive) {
      return (
        <DialogTitle
          className={classes.popUpBanner}
          id="customized-dialog-title"
        >
          <Typo
            textAlign={"center"}
            color={"#fff"}
            fontWeight={"500"}
            fontSize={"21px"}
          >
            {title}
          </Typo>
        </DialogTitle>
      );
    }
  };

  /**it renders popup for the footer */
  const renderFooter = () => {
    if (!isGoogleCalendarActive) {
      return (
        <DialogActions>
          <CustomButton
            variant={"inherit"}
            color={"primary"}
            autoFocus
            onClick={() => handleLeftButtonClick()}
          >
            {leftButton}
          </CustomButton>
          <CustomButton
            disabled={selectedTimeSlot ? false : true}
            opacity={selectedTimeSlot ? 1 : 0.5}
            variant={"contained"}
            // color={selectedTimeSlot ? "primary" : "#999DC4"}
            autoFocus
            onClick={() => handleRightButtonClick(selectedTimeSlot)}
          >
            {rightButton}
          </CustomButton>
        </DialogActions>
      );
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      maxWidth={width}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {/* displays header for the popup */}
      {renderHeader()}

      {/* it renders the content(children) for the popup */}
      <DialogContent className={classes.DialogRoot} dividers>
        {children}
      </DialogContent>

      {/* displays footer for the popup */}
      {renderFooter()}
    </Dialog>
  );
};

export default Popup;
